import React from 'react';
import styled, { keyframes } from 'styled-components';

interface SatelliteLoadingProps {
    customMessage?: string;
}

const SatelliteLoading = (props: SatelliteLoadingProps) => {
    return (
        <SatelliteLoadingContainer>
            <LoadingText>{props.customMessage ? props.customMessage : 'Waiting for Satellite Images ...'}</LoadingText>
            <LoadingSatelliteIcon src="/assets/floating-drawer-icons/icon-UI-satellite-white.png" />
            <LoadingWave src="/assets/floating-drawer-loading-icons/loading-wave.svg" />
            <LoadingAntennaIcon src="/assets/floating-drawer-loading-icons/loading-antenna-icon.svg" />
        </SatelliteLoadingContainer>
    );
};

export default SatelliteLoading;

const SatelliteLoadingContainer = styled.div`
    position: relative;
    min-height: 260px;
    height: 260px;
`;

const LoadingText = styled.p`
    margin-top: 25px;
    color: white;
    padding: 0;
    text-align: center;
    z-index: 1;
`;

const LoadingSatelliteIcon = styled.img`
    position: absolute;
    bottom: 65%;
    right: 10%;
    max-width: 80px;
    z-index: 1;
`;

const LoadingAntennaIcon = styled.img`
    position: absolute;
    bottom: 10%;
    left: 21%;
    width: 44px;
    height: 44px;
    opacity: 0.8;
    z-index: 1;
`;

const WaveAnimation = keyframes`
    0% {
        bottom: 50%;
        right: 17%;
        transform: rotate(135deg) scale(0.3);
    } 
    95% {
        bottom: 14%;
        right: 55%;
        transform: rotate(135deg) scale(0.7);
    } 
    100% {
        bottom: 12%;
        right: 55%;
        transform: rotate(135deg) scale(0.2);
    }
`;

const LoadingWave = styled.img`
    position: absolute;
    bottom: 30%;
    right: 20%;
    transform: rotate(135deg) scale(0.7);
    opacity: 0.6;
    animation: ${WaveAnimation} 1.25s infinite linear;
    z-index: 0;
`;
