import { EvalScript } from '../../../../api/api-cf-sentinel';

export const trueColor: EvalScript = {
    name: 'True Color',
    sentinelSatelliteType: 'sentinel-2-l1c',
    processing: undefined,
    script: `
//VERSION=3

function setup() {
  return {
    input: ["B04", "B03", "B02", "dataMask"],
    output: { bands: 4 }
  };
}

// Contrast enhance / highlight compress
const maxR = 3.0; // max reflectance

const midR = 0.13;
const sat = 1.3;
const gamma = 2.3;

// remove the minimum Rayleigh scattering (check the Himalayas)
const ray = { r: 0.013, g: 0.024, b: 0.041 };

function evaluatePixel(smp) {
  const rgbLin = satEnh(sAdj(smp.B04 - ray.r), sAdj(smp.B03 - ray.g), sAdj(smp.B02 - ray.b));
  return [sRGB(rgbLin[0]), sRGB(rgbLin[1]), sRGB(rgbLin[2]), smp.dataMask];
}

const sAdj = (a) => adjGamma(adj(a, midR, 1, maxR));

const gOff = 0.01;
const gOffPow = Math.pow(gOff, gamma);
const gOffRange = Math.pow(1 + gOff, gamma) - gOffPow;

const adjGamma = (b) => (Math.pow((b + gOff), gamma) - gOffPow) / gOffRange;

// Saturation enhancement
function satEnh(r, g, b) {
  const avgS = (r + g + b) / 3.0 * (1 - sat);
  return [clip(avgS + r * sat), clip(avgS + g * sat), clip(avgS + b * sat)];
}

const clip = (s) => s < 0 ? 0 : s > 1 ? 1 : s;

//contrast enhancement with highlight compression
function adj(a, tx, ty, maxC) {
  var ar = clip(a / maxC, 0, 1);
  return ar * (ar * (tx / maxC + ty - 1) - ty) / (ar * (2 * tx / maxC - 1) - tx / maxC);
}

const sRGB = (c) => c <= 0.0031308 ? (12.92 * c) : (1.055 * Math.pow(c, 0.41666666666) - 0.055);
`,
    description:
        'True color composite uses visible light bands "red, green and blue" in the corresponding RGB color channels, which is the closest to natural human view.',
};

const falseColor: EvalScript = {
    name: 'False Color',
    sentinelSatelliteType: 'sentinel-2-l1c',
    processing: undefined,
    script: `
//VERSION=3

let minVal = -0.02; // original=0.0
let maxVal =  0.39; // original=0.4

let viz = new HighlightCompressVisualizer(minVal, maxVal);

function setup() {
  return {
    input: ["B03", "B04", "B08","dataMask"],
    output: { bands: 4 }
  };
}

function evaluatePixel(samples) {
  let val = [samples.B08, samples.B04, samples.B03,samples.dataMask];
  return viz.processList(val);
}
`,
    description:
        'False color composite highlights vegetation as reddish patterns: the more bright red, the more dense and healthy is the vegetation. This composite uses the Near Infrared band, which is very sensitive to vegetation, in the place of red channel of RGB.',
};

const enhancedNaturalColor: EvalScript = {
    name: 'Enhanced Natural Color',
    sentinelSatelliteType: 'sentinel-2-l1c',
    processing: undefined,
    script: `
//VERSION=3

let minVal = 0.00;
let maxVal = 0.90;

let viz = new HighlightCompressVisualizer(minVal, maxVal);

function setup() {
  return {
    input: ["B04", "B03", "B02", "B08", "B12", "dataMask"],
    output: { bands: 4 }
  };
}

function evaluatePixel(samples) {
  var B04=samples.B04;
  var B03=samples.B03;
  var B02=samples.B02;
  var B08=samples.B08;
  var B12=samples.B12;
  let val = [B04*1+B12*1.5,B03*1.5+B08*1,B02*2.5,samples.dataMask];
  return viz.processList(val);
}
`,
    description: 'This composite uses Infrared bands to highlight urban areas, soil and vegetation.',
};

const enhancedVegetationHealth: EvalScript = {
    name: 'Enhanced Vegetation Health',
    sentinelSatelliteType: 'sentinel-2-l1c',
    processing: undefined,
    script: `
//VERSION=3

function setup() {
  return {
    input: ["B03","B04", "B08", "dataMask"],
    output: [
        { id: "default", bands: 4 },
    { id: "index", bands: 1, sampleType: "FLOAT32" }, 
        { id: "eobrowserStats", bands: 2, sampleType: 'FLOAT32' },
        { id: "dataMask", bands: 1 }
    ]
  };
}

function evaluatePixel(samples) {
    let val = index(samples.B08, samples.B04);
    let imgVals = null;
  
    // The library for tiffs works well only if there is only one channel returned.
    // So we encode the "no data" as NaN here and ignore NaNs on frontend.
    const indexVal = samples.dataMask === 1 ? val : NaN;
  
    if (val<-0.5) imgVals = [0.05,0.05,0.05,samples.dataMask];
    else if (val<-0.2) imgVals = [0.75,0.75,0.75,samples.dataMask];
    else if (val<-0.1) imgVals = [0.86,0.86,0.86,samples.dataMask];
    else if (val<0) imgVals = [0.92,0.92,0.92,samples.dataMask];
    else if (val<0.025) imgVals = [1,0.98,0.8,samples.dataMask];
    else if (val<0.05) imgVals = [0.93,0.91,0.71,samples.dataMask];
    else if (val<0.075) imgVals = [0.87,0.85,0.61,samples.dataMask];
    else if (val<0.1) imgVals = [0.8,0.78,0.51,samples.dataMask];
    else if (val<0.125) imgVals = [0.74,0.72,0.42,samples.dataMask];
    else if (val<0.15) imgVals = [0.69,0.76,0.38,samples.dataMask];
    else if (val<0.175) imgVals = [0.64,0.8,0.35,samples.dataMask];
    else if (val<0.2) imgVals = [0.57,0.75,0.32,samples.dataMask];
    else if (val<0.25) imgVals = [0.5,0.7,0.28,samples.dataMask];
    else if (val<0.3) imgVals = [0.44,0.64,0.25,samples.dataMask];
    else if (val<0.35) imgVals = [0.38,0.59,0.21,samples.dataMask];
    else if (val<0.4) imgVals = [0.31,0.54,0.18,samples.dataMask];
    else if (val<0.45) imgVals = [0.25,0.49,0.14,samples.dataMask];
    else if (val<0.5) imgVals = [0.19,0.43,0.11,samples.dataMask];
    else if (val<0.55) imgVals = [0.13,0.38,0.07,samples.dataMask];
    else if (val<0.6) imgVals = [0.06,0.33,0.04,samples.dataMask];
    else imgVals = [0,0.27,0,samples.dataMask];    
 	
  	return {
      default: imgVals,
      index: [indexVal],
      eobrowserStats:[val,isCloud(samples)?1:0],
      dataMask: [samples.dataMask]
    };
}

function isCloud(samples){ // FOR CLOUD DETECTION 
    const NGDR = index(samples.B03, samples.B04);
    const bRatio = (samples.B03 - 0.175) / (0.39 - 0.175);
    return bRatio > 1 || (bRatio > 0 && NGDR > 0);
}  
`,
    description: 'This is the most used index to determine density and health of vegetation',
};

const waterDetection: EvalScript = {
    name: 'Water Detection',
    sentinelSatelliteType: 'sentinel-2-l2a',
    processing: undefined,
    script: `
//VERSION=3

//ndwi
var colorRamp1 = [
  	[0, 0xFFFFFF],
  	[1, 0x008000]
  ];

var colorRamp2 = [
  	[0, 0xFFFFFF],
  	[1, 0x0000CC]
  ];

let viz1 = new ColorRampVisualizer(colorRamp1);
let viz2 = new ColorRampVisualizer(colorRamp2);

function evaluatePixel(samples) {
  var val = index(samples.B03, samples.B08);

  if (val < -0) {
    return viz1.process(-val);
  } else {
    return viz2.process(Math.sqrt(Math.sqrt(val)));
  }
}

function setup() {
  return {
    input: [{
      bands: [
        "B03",
        "B08"
      ]
    }],
    output: {
      bands: 3
    }
  }
}
`,
    description: 'This index is used to map water bodies (be aware that shadows on mountains appear like water).',
};

const radarColored: EvalScript = {
    name: 'Radar (Colored)',
    sentinelSatelliteType: 'sentinel-1-grd',
    processing: {
        orthorectify: 'true',
        backCoeff: 'GAMMA0_TERRAIN',
        demInstance: 'COPERNICUS_30',
    },
    script: `
//VERSION=3

function setup() {
  return {
    input: ["VV", "VH", "dataMask"],
        output: { bands: 4 }
  };
}

// VV is more sensitive generally
// VH is more sensitive to vegetation
function evaluatePixel(sample) {
  var VV=sample.VV;var VH=sample.VH
var SQVV = (Math.sqrt(VV+0.002));
var SQVH = (Math.sqrt(VH+0.002));
var RGBsq  =[
  1.2 * SQVV ,
  2.5 * SQVH ,
  0.8 * (SQVH / SQVV) ,
  sample.dataMask ];
return RGBsq ;
}
`,
    description:
        'This composite uses radar bands, which can detect reflected signals of features even through clouds. Reddish patterns indicate general sensitivity (VV), while greenish patterns are more related to vegetation (VH).',
};

const geology: EvalScript = {
    name: 'Geology',
    sentinelSatelliteType: 'sentinel-2-l1c',
    processing: undefined,
    script: `
//VERSION=3

// Simplest Enhancement Functions
function a(a, b) { return a + b; }

function stretch(val, Mi, Mx) {
  return (val - Mi) / (Mx - Mi);
}

function satEnh(rgbArr) {
  var avg = rgbArr.reduce((a, b) => a + b, 0) / rgbArr.length;
  return rgbArr.map(a => avg * (1 - Saturation) + a * Saturation);
}

function applyEnh(bArr) {
  return satEnh([stretch(bArr[0], Min, Max), stretch(bArr[1], Min, Max), stretch(bArr[2], Min, Max)]);
}

// RGB BAND COMPOSITION for Enhanced Geology
var RGB_Composite = [
  B04 * 1 + B12 * 2, // Red Channel (Combination of Red and SWIR)
  B03 * 2 + B08 * 1, // Green Channel (Green and NIR)
  B02 * 4 // Blue Channel (Blue band)
];

// ENHANCEMENT PARAMETERS (Can be adjusted)
var Min = -0.1; // Luminosity for lower values
var Max = 1.6; // Luminosity for higher values
var Saturation = 1.2; // Saturation enhancement factor

// Apply enhancements to RGB composite
var Enhancement = applyEnh(RGB_Composite); 

// Return the enhanced result
return Enhancement;
  `,
    description:
        'This composite uses Infrared bands to highlight geology and vegetation. The red channel is a mix of Red and SWIR bands, the green channel uses Green and NIR bands, and the blue channel uses the Blue band for enhanced geological and vegetation contrast.',
};

const enhancedWaterColor: EvalScript = {
    name: 'Enhanced Water Color',
    sentinelSatelliteType: 'sentinel-2-l2a',
    processing: undefined,
    script: `
//VERSION=3

// Compute NDWI for water bodies (Water > 0.0)
var NDWI = (B03 - B08) / (B03 + B08);

// Land composition (red channel to highlight land)
var Land = [B04 * 1.5, B04 * 1.5, B04 * 1.5];

// Bathymetry calculation
var IG = (B02 / B03); // Blue/Green Ratio
var IR = (B02 / B04); // Blue/Red Ratio
var BT = 1 / (IG * IG); // Bathymetric Transformation (Bathymetry index)

// Bathymetry Enhancement: Red = shallow, Green = medium, Blue = deeper
var BathyEnh = [
  B04 * 2 + BT * 0.15,  // Red channel (Enhanced Red)
  B03 * 4 + BT * 0.25,  // Green channel (Enhanced Green)
  B02 * 5 + BT * 0.20   // Blue channel (Enhanced Blue)
];

// Return Bathymetry Enhancement for water areas and Land composition for non-water
return (NDWI > -0.00) ? BathyEnh : Land;
  `,
    description:
        'This script is meant for simple enhancement of shallow bathymetry as blue shades. It works best for less turbid waters (as turbidity appears as yellowish hues). The enhancement uses the Blue/Green ratio for bathymetry calculation, with blue shades indicating shallow waters and green for deeper waters.',
};

const enhancedFireDetection: EvalScript = {
    name: 'Enhanced Fire Detection',
    sentinelSatelliteType: 'sentinel-2-l1c',
    processing: undefined,
    script: `
//VERSION=3

function setup() {
return {
  input: ["B01","B02","B03","B04","B08","B8A","B11","B12","CLP", "dataMask"],
  output: { bands: 4 }
};
}

function stretch(val, min, max) { return (val - min) / (max - min); }

function satEnh(arr, s) {
 var avg = arr.reduce((a, b) => a + b, 0) / arr.length;
 return arr.map(a => avg * (1 - s) + a * s);
}

function layerBlend(lay1, lay2, lay3, op1, op2, op3) {
  return lay1.map(function(num, index) {
   return (num / 100 * op1 + (lay2[index] / 100 * op2) + (lay3[index] / 100 * op3));
  });
}

function evaluatePixel(sample) {
const hsThreshold = [2.0, 1.5, 1.25, 1.0];
const hotspot = 1;
const style = 1;
const hsSensitivity = 1.0;
const boost = 1;

const cloudAvoidance = 1;
const cloudAvoidanceThreshold = 245;
const avoidanceHelper = 0.8;

const offset = -0.000;
const saturation = 1.10;
const brightness = 1.00;
const sMin = 0.01;
const sMax = 0.99;

const showBurnscars = 0;
const burnscarThreshold = -0.25;
const burnscarStrength = 0.3;

const NDWI = (sample.B03 - sample.B08) / (sample.B03 + sample.B08);
const NDVI = (sample.B08 - sample.B04) / (sample.B08 + sample.B04);
const waterHighlight = 0;
const waterBoost = 2.0;
const NDVI_threshold = -0.15;
const NDWI_threshold = 0.15;
const waterHelper = 0.2;

const Black = [0, 0, 0];
const NBRindex = (sample.B08 - sample.B12) / (sample.B08 + sample.B12); 
const naturalColorsCC = [Math.sqrt(brightness * sample.B04 + offset), Math.sqrt(brightness * sample.B03 + offset), Math.sqrt(brightness * sample.B02 + offset)];
const naturalColors = [(2.5 * brightness * sample.B04 + offset), (2.5 * brightness * sample.B03 + offset), (2.5 * brightness * sample.B02 + offset)];
const URBAN = [Math.sqrt(brightness * sample.B12 * 1.2 + offset), Math.sqrt(brightness * sample.B11 * 1.4 + offset), Math.sqrt(brightness * sample.B04 + offset)];
const SWIR = [Math.sqrt(brightness * sample.B12 + offset), Math.sqrt(brightness * sample.B8A + offset), Math.sqrt(brightness * sample.B04 + offset)];
const NIRblue = colorBlend(sample.B08, [0, 0.25, 1], [[0/255, 0/255, 0/255],[0/255, 100/255, 175/255],[150/255, 230/255, 255/255]]);
const classicFalse = [sample.B08 * brightness, sample.B04 * brightness, sample.B03 * brightness];
const NIR = [sample.B08 * brightness, sample.B08 * brightness, sample.B08 * brightness];
const atmoPen = [sample.B12 * brightness, sample.B11 * brightness, sample.B08 * brightness];
var enhNaturalColors = [0, 0, 0];
for (let i = 0; i < 3; i += 1) { enhNaturalColors[i] = (brightness * ((naturalColors[i] + naturalColorsCC[i]) / 2) + (URBAN[i] / 10)); }

const manualCorrection = [0.00, 0.00, 0.00];

var Viz = layerBlend(URBAN, naturalColors, naturalColorsCC, 10, 40, 50); // Choose visualization(s) and opacity here

if (waterHighlight) {
  if ((NDVI < NDVI_threshold) && (NDWI > NDWI_threshold) && (sample.B04 < waterHelper)) {
   Viz[1] = Viz[1] * 1.2 * waterBoost + 0.1;
   Viz[2] = Viz[2] * 1.5 * waterBoost + 0.2;
  }
} 

Viz = satEnh(Viz, saturation);
for (let i = 0; i < 3; i += 1) {
  Viz[i] = stretch(Viz[i], sMin, sMax); 
  Viz[i] += manualCorrection[i];  
}

if (hotspot) {  
  if ((!cloudAvoidance) || ((sample.CLP < cloudAvoidanceThreshold) && (sample.B02 < avoidanceHelper))) {
   switch (style) {
     case 1:
      if ((sample.B12 + sample.B11) > (hsThreshold[0] / hsSensitivity)) return [((boost * 0.50 * sample.B12) + Viz[0]), ((boost * 0.50 * sample.B11) + Viz[1]), Viz[2], sample.dataMask]; 
      if ((sample.B12 + sample.B11) > (hsThreshold[1] / hsSensitivity)) return [((boost * 0.50 * sample.B12) + Viz[0]), ((boost * 0.20 * sample.B11) + Viz[1]), Viz[2], sample.dataMask]; 
      if ((sample.B12 + sample.B11) > (hsThreshold[2] / hsSensitivity)) return [((boost * 0.50 * sample.B12) + Viz[0]), ((boost * 0.10 * sample.B11) + Viz[1]), Viz[2], sample.dataMask];  
      if ((sample.B12 + sample.B11) > (hsThreshold[3] / hsSensitivity)) return [((boost * 0.50 * sample.B12) + Viz[0]), ((boost * 0.00 * sample.B11) + Viz[1]), Viz[2], sample.dataMask]; 
     break;
     case 2:
      if ((sample.B12 + sample.B11) > (hsThreshold[3] / hsSensitivity)) return [1, 0, 0, sample.dataMask]; 
     break;
     case 3:
      if ((sample.B12 + sample.B11) > (hsThreshold[3] / hsSensitivity)) return [1, 1, 0, sample.dataMask]; 
     break;
     case 4:  
      if ((sample.B12 + sample.B11) > (hsThreshold[3] / hsSensitivity)) return [Viz[0] + 0.2, Viz[1] - 0.2, Viz[2] - 0.2, sample.dataMask];
     break;
     default:
    }
  }
}

if (showBurnscars) {
 if (NBRindex < burnscarThreshold) {
   Viz[0] = Viz[0] + burnscarStrength;
   Viz[1] = Viz[1] + burnscarStrength;
 }
}

return [Viz[0], Viz[1], Viz[2], sample.dataMask];
}
  `,
    description:
        'This script combines a natural color background with NIR/SWIR bands to highlight wildfire hotspots. It utilizes the B11 and B12 bands to show more detail on active fires. The result is a visualization where fires appear as bright red areas.',
    author: 'Author: Pierre Markuse (https://twitter.com/Pierre_Markuse)',
};

const tonemappedNaturalColor: EvalScript = {
    name: 'Tonemapped Natural Color',
    sentinelSatelliteType: 'sentinel-2-l1c',
    processing: undefined,
    script: `
//VERSION=3

var tonemapMethod = 4;
// 0 - Simple Reinhard

// 1 - Luma based Reinhard

// 2 - Uncharted 2 filmic curve

// 3 - ACES Reinhard

// 4 - ACES Uncharted

var debug = false;

var adjForSunColor = true;

var atmosphere = 0.025;

var gain = 1.5;

var saturation = 2.0;

var White = 2.5;

// atmospheric adjustment

//https://custom-scripts.sentinel-hub.com/sentinel-2/poor_mans_atcor/#

var c0r = atmosphere;

var atmRatios = [1, 2, 3.25]; // Rayleigh-derived consts for automated atmosphere offsets

var atm2p = (a, c0, c1) => (a - c0) / c1;

var atm1p = (a, c0) => atm2p(a, c0, (1 - c0) ** 2);

var atm = (a, ii) => (typeof cManual !== 'undefined')
? (cManual[ii] instanceof Array)
  ? atm2p(a, cManual[ii][0], cManual[ii][1])
  : atm1p(a, cManual[ii])
: atm1p(a, c0r * atmRatios[ii]);

function matMul(vec, mat) {
return [vec[0] * mat[0][0]
  + vec[1] * mat[0][1]
  + vec[2] * mat[0][2],
vec[0] * mat[1][0]
+ vec[1] * mat[1][1]
+ vec[2] * mat[1][2],
vec[0] * mat[2][0]
+ vec[1] * mat[2][1]
+ vec[2] * mat[2][2]
];
}

const RGBLin_2_AP0 = [
[0.4397010, 0.3829780, 0.1773350],
[0.0897923, 0.8134230, 0.0967616],
[0.0175440, 0.1115440, 0.8707040]];

const AP0_2_RGBLin = [
[2.52169, -1.13413, -0.38756],
[-0.27648, 1.37272, -0.09624],
[-0.01538, -0.15298, 1.16835]];

/////////////////////////////////////////

///    The interesting part:

/////////////////////////////////////////

function GetLuma(rgb) {
// https://en.wikipedia.org/wiki/Relative_luminance

return rgb[0] * 0.2126 + rgb[1] * 0.7152 + rgb[2] * 0.0722
}


function Saturate(rgb) {
var L = GetLuma(rgb);
rgb = rgb.map(a => Math.max(L + (a - L) * saturation, 0.));
return rgb;
}

function Uncharted2FilmicCurve(x) {
// Unchared2 tone mapping (See https://filmicgames.com)

const A = 0.15;
const B = 0.50;
const C = 0.10;
const D = 0.20;
const E = 0.02;
const F = 0.30;
const W = White;
var c = ((x * (A * x + C * B) + D * E) / (x * (A * x + B) + D * F)) - E / F;
var w = ((W * (A * W + C * B) + D * E) / (W * (A * W + B) + D * F)) - E / F;
return c / w;
}

function ReinhardCurve(C) {
return C / (1 + C) * (1 + 1 / White)
}

function LumaBasedReinhardTonemap(col) {
const W = White;
var L = GetLuma(col);
return col.map(a => a * (ReinhardCurve(L) / L));
}

function tonemap(col) {
switch (tonemapMethod) {
  case 0:
    return col.map(ReinhardCurve);
  case 1:
    return LumaBasedReinhardTonemap(col);
  case 2:
    return col.map(Uncharted2FilmicCurve);
  case 3:
    col = matMul(col, RGBLin_2_AP0);
    col = col.map(ReinhardCurve);
    col = matMul(col, AP0_2_RGBLin);
    return col;
  case 4:
    col = matMul(col, RGBLin_2_AP0);
    col = col.map(Uncharted2FilmicCurve);
    col = matMul(col, AP0_2_RGBLin);
    return col;
}
}

function sRGBCurve(C) {
return C < 0.0031308 ? (12.92 * C) : (1.055 * Math.pow(C, 0.41666) - 0.055);
}

var col = [B04, B03, B02];
col = col.map(atm);
if (adjForSunColor)
col = [col[0], 0.939 * col[1], 0.779 * col[2]]

col = col.map(a => a * gain);

if (debug) {
var minC = Math.min.apply(null, col);
if (minC < 0.) col = [0., 0., 1.,];
}

col = Saturate(col);
col = tonemap(col);

if (debug) {
var maxC = Math.max.apply(null, col);
if (maxC > 1.) col = [1., 0., 0.,];
}
col = col.map(sRGBCurve);

return col;
  `,
    description:
        'This script is designed to produce natural color images using tonemapping techniques. The tonemapping is applied globally to preserve details in bright areas of the image.',
    author: 'Author: Gregory Ivanov',
};

const algaeDetection: EvalScript = {
    name: 'Algae Detection',
    sentinelSatelliteType: 'sentinel-2-l2a',
    processing: undefined,
    script: `
// Water body detection - credit Mohor Gartner
var MNDWI_threshold=0.42; //testing shows recommended 0.42 for Sentinel-2 and Landsat 8. For the scene in article [1] it was 0.8.
var NDWI_threshold=0.4; //testing shows recommended 0.4 for Sentinel-2 and Landsat 8. For the scene in article [1] it was 0.5.
var filter_UABS=true;
var filter_SSI=false;

function wbi(r,g,b,nir,swir1,swir2) {
  //water surface
  let ws=0;
  //try as it might fail for some pixel
  try {
      //calc indices
      //[4][5][1][8][2][3]
      var ndvi=(nir-r)/(nir+r),mndwi=(g-swir1)/(g+swir1),ndwi=(g-nir)/(g+nir),ndwi_leaves=(nir-swir1)/(nir+swir1),aweish=b+2.5*g-1.5*(nir+swir1)-0.25*swir2,aweinsh=4*(g-swir1)-(0.25*nir+2.75*swir1);
      //[10][11][12]
      var dbsi=((swir1-g)/(swir1+g))-ndvi,wii=Math.pow(nir,2)/r,wri=(g+r)/(nir+swir1),puwi=5.83*g-6.57*r-30.32*nir+2.25,uwi=(g-1.1*r-5.2*nir+0.4)/Math.abs(g-1.1*r-5.2*nir),usi=0.25*(g/r)-0.57*(nir/g)-0.83*(b/g)+1;
      //DEFINE WB
      if (mndwi>MNDWI_threshold||ndwi>NDWI_threshold||aweinsh>0.1879||aweish>0.1112||ndvi<-0.2||ndwi_leaves>1) {ws=1;}
      //filter urban areas [3] and bare soil [10]
      if (filter_UABS && ws==1) {
          if ((aweinsh<=-0.03)||(dbsi>0)) {ws=0;}
      }
  }catch(err){ws=0;}
  return ws;
}
let water = wbi(B04,B03,B02,B08,B11,B12);

// Floating vegetation
function FAI (a,b,c) {return (b-a-(c-a)*(783-665)/(865-665))};
let FAIv = FAI(B04,B07,B8A);

// Chlorophyll-a
function NDCI (a,b) {return (b-a)/(b+a)};
let NDCIv = NDCI(B04,B05);
let chl = 826.57 * NDCIv**3 - 176.43 * NDCIv**2 + 19 * NDCIv + 4.071; // From simulated data

// Ture colour
let trueColor = [3*B04,3*B03,3*B02];

// Render colour map
if (water==0) {
  return trueColor;
} else if (FAIv>0.08){
  return [233/255,72/255,21/255];
} else if (chl<0.5){
  return [0,0,1.0];
} else if (chl<1){
  return [0,0,1.0];
} else if (chl<2.5){
  return [0,59/255,1];
} else if (chl<3.5){
  return [0,98/255,1];
} else if (chl<5){
  return [15/255,113/255,141/255];
} else if (chl<7){
  return [14/255,141/255,120/255];
} else if (chl<8){
  return [13/255,141/255,103/255];
} else if (chl<10){
  return [30/255,226/255,28/255];
} else if (chl<14){
  return [42/255,226/255,28/255];
} else if (chl<18){
  return [68/255,226/255,28/255];
} else if (chl<20){
  return [68/255,226/255,28/255];
} else if (chl<24){
  return [134/255,247/255,0];
} else if (chl<28){
  return [140/255,247/255,0];
} else if (chl<30){
  return [205/255,237/255,0];
} else if (chl<38){
  return [208/255,240/255,0];
} else if (chl<45){
  return [208/255,240/255,0];
} else if (chl<50){
  return [251/255,210/255,3/255];
} else if (chl<75){
  return [248/255,207/255,2/255];
} else if (chl<90){
  return [134/255,247/255,0];
} else if (chl<100){
  return [245/255,164/255,9/255];
} else if (chl<150){
  return [240/255,159/255,8/255];
} else if (chl<250){
  return [237/255,157/255,7/255];
} else if (chl<300){
  return [239/255,118/255,15/255];
} else if (chl<350){
  return [239/255,101/255,15/255];
} else if (chl<450){
  return [239/255,100/255,14/255];
} else if (chl<500){
  return [233/255,72/255,21/255];
} else return [233/255,72/255,21/255];
  `,
    description:
        'This script estimates chlorophyll-a for detecting cyanobacteria blooms in surface water bodies, using Sentinel-2 satellite data.',
    author: 'Author: Kravitz, J & Matthews M., 2020. Chlorophyll-a for cyanobacteria blooms from Sentinel-2. CyanoLakes.',
};

const enhancedVegetationDensity: EvalScript = {
    name: 'Enhanced Vegetation Density',
    sentinelSatelliteType: 'sentinel-2-l1c',
    processing: undefined,
    script: `
// Description: This script classifies each pixel into the following categories: 
// High Vegetation (Dark Green), Low Vegetation (Medium Green), Grassland (Bright Green), 
// Bare land (Brown), Water (Blue), Unknown (Black).

// Set to false to avoid detecting water bodies
detect_water = true;

// Colors
let water = [51/255, 128/255, 204/255]; // Blue #3380cc
let hi_forest = [0, 104/255, 55/255]; // Strong green #006837
let low_forest = [62/255, 165/255, 64/255]; // Medium green #3ea540
let grass_land = [186/255, 240/255, 150/255]; // Light green #baf096
let bare_land = [173/255, 136/255, 85/255]; // Brown #ad8855
let other = [0, 0, 0]; // Black #000000

// COEFICIENTS: These coefficients are orientative
// and some tweak may be needed depending on the
// location and case of study

// NDWI water limit
let ndwi_hi = 0.2;

// Bare soil index (BI), soil limit
let bi_hi = 2;

// NDVI high and low limits
let ndvi_lo = 0.20; // 0.20 for L1C (suggested value)
// 0.25 for L2A (suggested value)
let ndvi_hi = 0.40; // 0.40 for L1C (suggested value)
// 0.45 for L2A (suggested value)

// Shadow index (SI) high and low limits
let si_lo = 0.90; // 0.90 for L1C (suggested value) 
// 0.92 for L2A (suggested value)
let si_hi = 0.93; // 0.93 for L1C (suggested value) 
// 0.95 for L2A (suggested value)

if (detect_water) {
  ndwi = (B03 - B08) / (B03 + B08);
  if (ndwi > ndwi_hi)
    return water;
}

let ndvi = (B08 - B04) / (B08 + B04);
let bi_1 = (B08 + B03 + B04) / (B08 + B03 - B04);
let si = Math.pow((1 - B03) * (1 - B04), 1/2);

if (ndvi > ndvi_hi && bi_1 < bi_hi && si > si_hi)
return hi_forest;
else if (ndvi_hi > ndvi > ndvi_lo && bi_1 < bi_hi && si_hi > si > si_lo)
return low_forest;
else if (ndvi > ndvi_lo)
return grass_land;
else if (ndvi < ndvi_lo && bi_1 > bi_hi && si_lo > si)
return bare_land;
else
return other;
  `,
    description:
        'This script classifies pixels into various land types based on vegetation, water, and soil characteristics. It uses NDVI, NDWI, and other indices to classify areas into categories like high forest, low forest, grassland, and bare land.',
    author: 'Author: Antonio Carlon Paredes',
};

const enhancedSurfaceIndices: EvalScript = {
    name: 'Enhanced Surface Indices',
    sentinelSatelliteType: 'sentinel-2-l1c',
    processing: undefined,
    script: `
function a(a, b) {return a + b;}
// FUNCTION FOR CONTRAST:

function stretch(val, min, max) {return (val - min) / (max - min);} 
// FUNCTION FOR SATURATION (for Verse and Inverse, separately):

function satEnh_V(rgbArr) {
  var avg = rgbArr.reduce((a, b) => a + b, 0) / rgbArr.length;
  return rgbArr.map(a => avg * (1 - SATU_V) + a * SATU_V); }	
function satEnh_I(rgbArr) {
  var avg = rgbArr.reduce((a, b) => a + b, 0) / rgbArr.length;
  return rgbArr.map(a => avg * (1 - SATU_I) + a * SATU_I); } 
// FUNCTION CONTRAST + SATURATION (for Verse and Inverse, separately):

function applyEnh_V(bArr) {
return satEnh_V([stretch(bArr[0], SminV, SmaxV), stretch(bArr[1], SminV, SmaxV), stretch(bArr[2], SminV, SmaxV)]); }
function applyEnh_I(bArr) {
return satEnh_I([stretch(bArr[0], SminI, SmaxI), stretch(bArr[1], SminI, SmaxI), stretch(bArr[2], SminI, SmaxI)]); }
//==============================================================================

// *SETTINGS BEGIN HERE*: Choose adjustments to these parameters (for Verse and Inverse selections, separately):

// STRETCH CONTRAST (min/max: Shadow/Light; default=0.00/1.00; blackout-mask = 1,1): // TIP: avoid too different contrast for masks

var SminV = 0.05 ; // Shadows: Darken : >0 ; Lighten <0

var SmaxV = 0.95 ; // Lights:  Darken : >1 ; Lighten <1

var SminI = 0.05 ; // Shadows: Darken : >0 ; Lighten <0

var SmaxI = 0.95 ; // Lights:  Darken : >1 ; Lighten <1

// SATURATION:

var SATU_V = 1.10 ; // standard=1.00; monochromatic=0; 2x=2.00

var SATU_I = 1.10 ; // standard=1.00; monochromatic=0; 2x=2.00

//------------------------------------------------------------------------------

// INDEX: Choose "only one" as SELECTION MASK (activate it removing first "//"; default=NDWI2):

//var NDSI = (B03-B11)/(B03+B11);  // Discriminates WATER&SNOW x NON-WATER: standard SNOW ~ > 0.42

//var NDVI = (B08-B04)/(B08+B04);  // Discriminates VEGETATION x NON-VEG: ~ Water<0 Soil,Sand,Snow,Clouds=-.1,+.1 LowVeg=.2,.4 DenseVeg>.4

var NDWI2 = (B03-B08)/(B03+B08); // Discriminates WATER x NON-WATER: standard WATER limit ~ > 0.3

//------------------------------------------------------------------------------

// BAND COMPOSITIONS: [RED, GREEN, BLUE]; (more customised compostions can be added to list below) // Enhancement description:

// (activate compositions removing first "//").

// A) Proposed for VERSE SELECTION (Water/NDWI, Vegetation/NDVI, Snow/NDSI):

var NATURAL_REDGE = [(B04*4.0), (B03*2.8+B06*1.5), (B02*3.5)]; // Near Natural; turbidity and algae RedEdge

//var FALSECOLOR_NIR = [(B08*2.3), (B03*1.0+B05*2.0), (B02*2.7)]; // Bluish water NIR; algae RedEdge

// B) Proposed for INVERSE SELECTION (LAND):

var NATURAL_NIR = [(B04*3.0+B05*1.0), (B03*3.0+B08*1.0), (B02*3.5)] ; // Near Natural; Vegetation NIR 

//var NATURAL_SWIR = [(B04*2.6+B12*0.8), (B03*3.0+B08*0.5), (B02*3.0)] ; // Bare soil SWIR; Vegetation NIR

//var GEOLOGY_SWIR = [(B12*2.2), (B04*1.4+B08*1.0), (B02*2.5)] ; // Geology SWIR; Vegetation NIR

//------------------------------------------------------------------------------

// SET BAND COMPOSTIONS (for each mask; may use same COMPO for both, or switch each other, according to necessity): 

var MaskVERSE   = NATURAL_REDGE ; // Copy composition here

var MaskINVERSE = NATURAL_NIR ;  // Copy composition here

//------------------------------------------------------------------------------

// APPLY ENHANCEMENTS: These two lines recall to FUNCTION CONTRAST + SATURATION;

var EnhVERSE   = applyEnh_V(MaskVERSE)  ; 

var EnhINVERSE = applyEnh_I(MaskINVERSE); 

//------------------------------------------------------------------------------

// RETURNs on screen selected ENHANCEMENT, according to Indices above, and limits below (choose "only one" line to remove "//"; default NDWI2):

//return ( NDSI > 0.42 ) ? EnhVERSE : EnhINVERSE ; // For SNOWY areas only

//return ( NDVI > 0.4 ) ? EnhVERSE : EnhINVERSE ; // For VEGETATION areas only

return ( NDWI2 > 0.1 ) ? EnhVERSE : EnhINVERSE ; // LAND x WATER: limit lowered to take clouds w/ water
  `,
    description:
        'Interactive enhancement dual mask to alternate selective treatment of features, like Land x Water or Snow or Vegetation.',
    author: 'Author: Sérgio A. J. Volkmer (https://twitter.com/sergioajv1)',
};

const synthwaveNeonforUrbanAreas: EvalScript = {
    name: 'Synthwave Neon for Urban Areas',
    sentinelSatelliteType: 'sentinel-2-l1c',
    processing: undefined,
    script: `
//VERSION=3

function setup() {
return {
  input: ["B04", "B03", "B02", "B12", "dataMask"],
  output: { bands: 4 }
};
}

function evaluatePixel(sample) {

let val = sample.B12 - sample.B04
let gain = 2.3
let gamma = - 0.95 // recommended gamma: -0.55 to -0.95
return [(gain * 3 * val) + gamma,
        (gain * 2.5 * sample.B03) + gamma,
        (gain * 4.2 * sample.B02) + gamma,
        sample.dataMask];
}
  `,
    description:
        'Aesthetic script designed to highlight urban and dry areas using a neon effect for enhanced visualization.',
    author: 'Author: Monja Šebela',
};

const enhancedUrbanNaturalColor: EvalScript = {
    name: 'Enhanced Urban Natural Color ',
    sentinelSatelliteType: 'sentinel-2-l1c',
    script: `
return [B08*0.3 + B04*2.5 + (B04*1.0+B12*0.3),
        B08*0.3 + B03*2.5 + (B03*1.0+B12*0.3),
        B08*0.3 + B02*2.5 + (B02*1.0+B12*0.3)];
  `,
    description:
        'This script enhances urban area visualizations by applying specific weightings to the Sentinel-2 bands, allowing for clearer distinction of features such as vegetation and built-up areas in urban environments.',
    author: 'Author: Leo Tolari',
};

const artisticNDVIColorScript: EvalScript = {
    name: 'Artistic NDVI Color Script',
    sentinelSatelliteType: 'sentinel-2-l1c',
    processing: undefined,
    script: `
// Index limits for different colors, especially white and black, can be adjusted for prettier artistic effect.

var NDVI = index(B08, B04); // calculate the index 

if (NDVI < 0.1) { 
  return [1, 1, 1]; // white
} 
if (NDVI < 0.2) { 
return [0.8, 0.2, 0.]; // nice red
} 
if (NDVI < 0.4) {
return [0.2, 0.2, 1]; // nice blue
}
if (NDVI < 0.6) {
return [1., 0.7, 0.]; // nice yellow
} 
else { 
return [0, 0, 0]; // black
}
  `,
    description:
        'This script applies an artistic effect to vegetation by using NDVI values. Based on NDVI thresholds, different colors are applied: white for barren land, red for low vegetation, blue for medium vegetation, yellow for healthy vegetation, and black for dense vegetation or other features.',
    author: 'Author: Matevz Pintar',
};

const leafAreaIndex: EvalScript = {
    name: 'Leaf Area Index',
    sentinelSatelliteType: 'sentinel-2-l1c',
    processing: undefined,
    script: `
var MixLAI = ((B08/(B04+B11))+(B08/(B04+B12)))/2.0 // calculate the index

if (MixLAI<0.0) return [0,0,0];
else if (MixLAI<0.25) return [0.95,0.95,0.8];
else if (MixLAI<0.5) return [0.93,0.91,0.71];
else if (MixLAI<0.75) return [0.87,0.85,0.61];
else if (MixLAI<1.0) return [0.57,0.75,0.32];
else if (MixLAI<1.5) return [0.44,0.64,0.25];
else if (MixLAI<2.0) return [0.31,0.54,0.18];
else if (MixLAI<2.5) return [0.19,0.43,0.11];
else if (MixLAI<3.0) return [0.57,0.75,0.32];
else if (MixLAI<3.5) return [0.44,0.64,0.25];
else if (MixLAI<4.0) return [0.31,0.54,0.18];
else if (MixLAI<4.5) return [0.19,0.43,0.11];
else if (MixLAI<5.0) return [0.06,0.33,0.04];
else return [0,0.15,0];
  `,
    description:
        'Leaf area index is one of the most important parameters in determining plant yields, the script calculates the Leaf Area Index (LAI) using Sentinel-2 bands B08, B04, B11, and B12. The resulting index is then categorized into different ranges to visualize various levels of leaf area density, with a color scale to represent each level.',
    author: 'Author: Dr. Hamid Rahimi',
};

const panchromaticsentineltwo: EvalScript = {
    name: 'Panchromatic Sentinel-2',
    sentinelSatelliteType: 'sentinel-2-l1c',
    processing: undefined,
    script: `
//VERSION=3

function setup() {
  return {
      input: ["B01", "B02", "B03", "B08", "dataMask"],
      output: { bands: 4 }
  };
}

const gain = 2.5;

function evaluatePixel(sample) {
  // Calculate the mean of the bands
  let pan = (sample.B01 + sample.B02 + sample.B03 + sample.B08) / 4;

  return [gain * pan, gain * pan, gain * pan, sample.dataMask];
}
  `,
    description:
        'This script generates a simple panchromatic visualization of Sentinel-2 data by calculating the mean of the Red, Green, Blue, and Near Infrared bands. The result is visualized in a grayscale format with a gain applied to enhance the contrast.',
    author: 'Author: András Zlinszky',
};

export const sentinelDefaultEvalScripts: EvalScript[] = [
    trueColor,
    enhancedNaturalColor,
    falseColor,
    geology,
    enhancedVegetationHealth,
    enhancedVegetationDensity,
    leafAreaIndex,
    waterDetection,
    enhancedWaterColor,
    algaeDetection,
    enhancedFireDetection,
    enhancedUrbanNaturalColor,
    panchromaticsentineltwo,
    enhancedSurfaceIndices,
    synthwaveNeonforUrbanAreas,
    artisticNDVIColorScript,
    tonemappedNaturalColor,
];

// Legacy default sentinel eval scripts pre 2025
export const legacyDefaultTrueColorEvalScript = trueColor;
export const legacyDefaultFalseColorEvalScript = falseColor;
export const legacyDefaultGeologyEvalScript = geology;
export const legacyDefaultRadarEvalScript = radarColored;
export const legacyDefaultNDVIEvalScript = enhancedVegetationHealth;

export const sentinelDefaultSideDrawerEvalScripts: EvalScript[] = [
    legacyDefaultTrueColorEvalScript,
    legacyDefaultFalseColorEvalScript,
    legacyDefaultGeologyEvalScript,
    legacyDefaultNDVIEvalScript,
    // Adding but is not part of the advanced dropdown scripts, works fine in the sidedrawer but has different date
    // availability to other scripts (not sure why bands possibly..)
    //legacyDefaultRadarEvalScript,
];
