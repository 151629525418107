import styled from 'styled-components';
import { PulseLoader } from '../../../Shared/pulse-loader';

interface SideDrawerLoaderProps {
    className?: string;
}

const SideDrawerLoader = ({ className }: SideDrawerLoaderProps) => {
    return (
        <LoaderContainer data-testid="side-drawer-loader" className={className}>
            <PulseLoader />
        </LoaderContainer>
    );
};

export default SideDrawerLoader;

const LoaderContainer = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    width: 320px;
    height: 50px;
    z-index: 99999;

    &.list {
        top: 85px;
        backdrop-filter: blur(3px);
    }
`;
