import styled, { keyframes } from 'styled-components';

interface MapSearchDrawListingCountProps {
    listingCount?: number | undefined;
    onClickFilter: () => void;
    filter: string | undefined;
    filterOpen: boolean;
}

const MapSearchDrawListingCount = (props: MapSearchDrawListingCountProps) => {
    if (props.listingCount === undefined) {
        return <SkeletonLoading />;
    }

    let listingCountText;
    if (props.listingCount) {
        if (props.filter) {
            listingCountText = `${props.listingCount.toLocaleString()} ${props.filter} ${
                props.listingCount === 1 ? 'map' : 'maps'
            } in this area`;
        } else {
            listingCountText = `${props.listingCount.toLocaleString()} ${
                props.listingCount === 1 ? 'map' : 'maps'
            } in this area`;
        }
    }

    return (
        <MapSearchDrawListingCountContainer isFilterOpen={props.filterOpen}>
            <MapCount>{listingCountText}</MapCount>
            <FilterIcon src="/assets/side-drawer/filter.svg" onClick={() => props.onClickFilter()} />
        </MapSearchDrawListingCountContainer>
    );
};

export default MapSearchDrawListingCount;

const MapSearchDrawListingCountContainer = styled.div<{ isFilterOpen: boolean }>`
    background: rgba(255, 255, 255, 0.05);
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-bottom-left-radius: ${(props) => (props.isFilterOpen ? '0' : '6px')};
    border-bottom-right-radius: ${(props) => (props.isFilterOpen ? '0' : '6px')};
    transition: opacity 0.3s ease, transform 0.3s ease;
    padding: 5px;
    width: calc(100% - 5px);
`;

const shimmer = keyframes`
  0% {
    background-position: -400px 0;
  }
  100% {
    background-position: 400px 0;
  }
`;

const SkeletonLoading = styled.div`
    background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0.05) 25%,
        rgba(255, 255, 255, 0.1) 50%,
        rgba(255, 255, 255, 0.05) 75%
    );
    background-size: 800px 100%;
    animation: ${shimmer} 1.5s infinite linear;
    border-radius: 6px;
    padding: 5px;
    margin-right: 5px;
    width: auto;
    height: 34px;
`;

const MapCount = styled.span`
    font-size: 16px;
    color: white;
    margin-right: 5px;
    text-align: center;
    width: calc(100% - 27px);
    display: inline-block;
`;

const FilterIcon = styled.img`
    height: 20px;
    width: 20px;
    margin-top: -2px;
    cursor: pointer;
`;
