import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { selectSentinelSelectedFeatureOpacity } from '../../../../store/Map/Sentinel/selectors';
import { actionSentinelSelectFeatureOpacity } from '../../../../store/Map/Sentinel/actions';

interface SentinelOpacitySliderProps {
    padding?: string;
}
const SentinelOpacitySlider = ({ padding }: SentinelOpacitySliderProps) => {
    const sentinelOpacity = useSelector(selectSentinelSelectedFeatureOpacity);

    const dispatch = useDispatch();
    const changeOpacity = (opacity: number) => dispatch(actionSentinelSelectFeatureOpacity(opacity));

    const opacity = sentinelOpacity ?? 1.0;

    return (
        <SliderContainer padding={padding}>
            <SliderText>Transparency:</SliderText>
            <SliderInput
                type="number"
                value={(opacity * 100).toFixed()}
                step={1}
                min={0}
                max={100}
                onChange={(e) => {
                    const opacity = parseInt(e.target.value) / 100;
                    changeOpacity(opacity);
                }}
            />
            <PercentageSign>%</PercentageSign>

            <SliderInputContainer>
                <Slider
                    percentageFilled={opacity * 100}
                    type="range"
                    defaultValue={100}
                    value={opacity * 100}
                    step={0.01}
                    onClick={(e) => e.stopPropagation()}
                    onChange={(e) => {
                        const opacity = parseInt(e.target.value) / 100;
                        changeOpacity(opacity);
                    }}
                />
            </SliderInputContainer>
        </SliderContainer>
    );
};

export default SentinelOpacitySlider;

const SliderContainer = styled.div<{ padding?: string }>`
    width: 100%;
    padding: ${(props) => props.padding || '0px'};
    display: flex;
`;

const SliderText = styled.p`
    vertical-align: middle;
    color: white;
    font-size: 0.9rem;
    margin: 1px 0px 0px 0px;
    padding: 0;
`;

const SliderInput = styled.input`
    background: none;
    border: none;
    color: rgba(255, 255, 255, 0.5);
    font-size: 0.9rem;
    width: 32px;
    text-align: right;
    margin-right: 1px;
    padding: 0px 1px;
    appearance: none;
    -moz-appearance: textfield;

    &:focus {
        color: white;
        outline: none;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
`;

const PercentageSign = styled.span`
    display: inline;
    margin-right: 5px;
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    vertical-align: middle;
    margin-top: -1px;
`;

const SliderInputContainer = styled.div`
    width: 100%;
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Slider = styled.input<any>`
    width: 100%;
    background: linear-gradient(
        to left,
        rgba(255, 255, 255, 0.3) ${(props) => 100 - props.value}%,
        #eed926 ${(props) => 100 - props.value}%
    );
    height: 2px;
    box-shadow: none !important;
    border: none !important;
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    border-radius: 6px;
    vertical-align: middle;
    margin-top: -5px;

    &::-moz-range-thumb {
        appearance: none;
        -webkit-appearance: none;
        background: #eed926;
        height: 12px;
        width: 12px;
        border-radius: 50%;
        cursor: pointer;
    }

    &::-webkit-slider-thumb {
        appearance: none;
        -webkit-appearance: none;
        background: #eed926;
        height: 12px;
        width: 12px;
        border-radius: 50%;
        cursor: pointer;
    }

    &:hover::-moz-range-thumb {
        appearance: none;
        -webkit-appearance: none;
        background: #eed926;
        border-radius: 50%;
        cursor: pointer;
    }

    &:hover::-webkit-slider-thumb {
        appearance: none;
        -webkit-appearance: none;
        background: #eed926;
        border-radius: 50%;
        cursor: pointer;
    }

    &:focus {
        background: linear-gradient(
            to left,
            rgba(255, 255, 255, 0.3) ${(props) => 100 - props.value}%,
            #eed926 ${(props) => 100 - props.value}%
        );
    }
`;
