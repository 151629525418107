import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import ImagePreviewFailed, { PreviewSize } from '../../../Shared/image-preview-failed';

interface SentinelDrawerThumbnailProps {
    src: string | undefined;
    alt: string;
}

const FORCED_LOADING_TIMEOUT = 5000; // 5 seconds

const SentinelDrawerThumbnail = ({ src, alt }: SentinelDrawerThumbnailProps) => {
    const [hasError, setHasError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [imageSrc, setImageSrc] = useState<string | undefined>(src);

    useEffect(() => {
        if (src) {
            setImageSrc(src);
            setIsLoading(false);
            setHasError(false);
        }
    }, [src]);

    const handleLoad = () => {
        setIsLoading(false);
        setHasError(false);
    };

    const handleError = () => {
        setIsLoading(false);
        setHasError(true);
    };

    // If sentinel takes too long with a response (internet issues or other)
    // we cancel the loading and let the ImagePreviewFailed display
    useEffect(() => {
        if (isLoading) {
            const timeout = setTimeout(() => {
                if (isLoading) {
                    setIsLoading(false);
                    setHasError(true);
                }
            }, FORCED_LOADING_TIMEOUT);

            return () => clearTimeout(timeout);
        }
        return;
    }, [isLoading]);

    return (
        <ImageWithFallbackContainer>
            {isLoading && !hasError && <SkeletonLoading />}
            {!isLoading && (hasError || !imageSrc) ? (
                <ImagePreviewFailed previewSize={PreviewSize.SMALL} />
            ) : (
                <StyledImage
                    src={imageSrc}
                    alt={alt}
                    onLoad={handleLoad}
                    onError={handleError}
                    loading="lazy"
                    isHidden={isLoading}
                />
            )}
        </ImageWithFallbackContainer>
    );
};

export default SentinelDrawerThumbnail;

const ImageWithFallbackContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    overflow: hidden;
`;

const shimmer = keyframes`
    0% {
        background-position: -68px 0;
    }
    100% {
        background-position: 68px 0;
    }
`;

const SkeletonLoading = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0.05) 25%,
        rgba(255, 255, 255, 0.1) 50%,
        rgba(255, 255, 255, 0.05) 75%
    );
    background-size: 136px 100%;
    animation: ${shimmer} 1.5s infinite linear;
`;

const StyledImage = styled.img<{ isHidden: boolean }>`
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: ${({ isHidden }) => (isHidden ? 'none' : 'block')};
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
`;
