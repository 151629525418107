import styled from 'styled-components';
import SentinelAdvancedEditor from './sentinel-advanced-editor';
import { EvalScript } from '../../../../../api/api-cf-sentinel';

interface SentinelAdvancedControlSettingsProps {
    setShowAdvancedFeatures: () => void;
    selectedEvalScript: EvalScript;
    onSelectedEvalScript: (evalScript: EvalScript) => void;
    isLoadingPreview?: boolean;
}

const SentinelAdvancedControlSettings = ({
    setShowAdvancedFeatures,
    selectedEvalScript,
    onSelectedEvalScript,
    isLoadingPreview,
}: SentinelAdvancedControlSettingsProps) => {
    return (
        <AdvancedControlSettingsContainer>
            <AdvancedControlHeader>
                <AdvancedControlIcon src="/assets/side-drawer/advanced-sentinel-wand-yellow.png" />
                <AdvancedControlTitle>Advanced Sentinel Satellite Image Processing</AdvancedControlTitle>
            </AdvancedControlHeader>
            <SentinelAdvancedEditor
                setShowAdvancedFeatures={setShowAdvancedFeatures}
                evalScript={selectedEvalScript}
                onSelectedEvalScript={onSelectedEvalScript}
                isLoadingPreview={isLoadingPreview}
            />
        </AdvancedControlSettingsContainer>
    );
};

export default SentinelAdvancedControlSettings;

const AdvancedControlSettingsContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 10px;
    width: 400px;
`;

const AdvancedControlHeader = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;

const AdvancedControlIcon = styled.img`
    width: 65px;
    height: 65px;
`;

const AdvancedControlTitle = styled.h2`
    color: white;
    text-align: left;
    font-size: 18px;
    font-weight: 800;
    margin-top: 10px;
    width: 270px;
`;
