import { EvalScript } from '../../../../api/api-cf-sentinel';

// https://custom-scripts.sentinel-hub.com/custom-scripts/landsat/
// https://custom-scripts.sentinel-hub.com/custom-scripts/hls/
export const landsatTrueColor: EvalScript = {
    name: 'Landsat True Color',
    sentinelSatelliteType: 'landsat-ot-l1',
    script: `//VERSION=3  
    function setup() {
    return {
        input: [{
        bands: [ "B02", "B03", "B04", "B08", "dataMask" ]
        }],
        output: { bands: 4 }  }
    }

    let minVal = -0.02;
    let maxVal = 0.38;

    let viz = new DefaultVisualizer(minVal, maxVal);

    function evaluatePixel(samples) {
        let sudoPanW = (samples.B04 + samples.B03 + samples.B02 * 0.4) / 2.4;
        let ratioW = samples.B08 / sudoPanW;
        let val = [samples.B04 * ratioW, samples.B03 * ratioW, samples.B02 * ratioW, samples.dataMask];
        return viz.processList(val);
    }
    `,
    description:
        'True color composite uses visible light bands "red, green and blue" in the corresponding RGB color channels, which is the closest to natural human view.',
};

const landsatFalseColor: EvalScript = {
    name: 'Landsat False Color',
    sentinelSatelliteType: 'landsat-ot-l1',
    script: `//VERSION=3 
        let minVal = -0.02; // original=0.0
        let maxVal =  0.39; // original=0.4

        let viz = new DefaultVisualizer(minVal, maxVal);

        function setup() {
        return {
            input: [{
            bands: ["B03","B04","B05","dataMask"]
            }],
            output: { bands: 4 }  }
        }

        function evaluatePixel(samples) {
            let val = [samples.B05, samples.B04, samples.B03, samples.dataMask];
            return viz.processList(val);
        }
    `,
    description:
        'False color composite highlights vegetation as reddish patterns: the more bright red, the more dense and healthy is the vegetation. This composite uses the Near Infrared band, which is very sensitive to vegetation, in the place of red channel of RGB.',
};

const landsatGeology: EvalScript = {
    name: 'Landsat Geology',
    sentinelSatelliteType: 'landsat-ot-l1',
    script: `//VERSION=3
    let minVal = 0.0;
    let maxVal = 0.6;
    let viz = new HighlightCompressVisualizer(minVal, maxVal);

    function setup() {
    return {
        input: ["B07", "B05", "B04", "dataMask"],
        output: { bands: 4 }
    };
    }
    function evaluatePixel(samples) {
        let val = [samples.B07, samples.B05, samples.B04, samples.dataMask];
        return viz.processList(val);
    }
    `,
    description: 'This composite uses Infrared bands to highlight geology and vegetation.',
};

const landsatFireDetection: EvalScript = {
    name: 'Landsat Fire Detection',
    sentinelSatelliteType: 'landsat-ot-l1',
    script: `//VERSION=3
    let minVal = 0.00;
    let maxVal = 0.99;

    let viz = new HighlightCompressVisualizer(minVal, maxVal);

    function setup() {
    return {
        input: ["B04", "B03", "B02", "B05", "B06", "B07", "dataMask"],
        output: { bands: 4 }
    };
    }

    function evaluatePixel(samples) {
        var B04=samples.B04;
        var B03=samples.B03;
        var B02=samples.B02;
        var B05=samples.B05;
        var B06=samples.B06;
        var B07=samples.B07;
        let val = [B04*1.3+B07*1.3,B03*1.2+B06*0.7+B05*0.4,B02*2.5,samples.dataMask];
        return viz.processList(val);
    }
    `,
    description: 'This composite uses Infrared bands to highlight geology and vegetation.',
};

export const landsatDefaultEvalScripts: EvalScript[] = [
    landsatTrueColor,
    landsatFalseColor,
    landsatGeology,
    landsatFireDetection,
];

export const legacyDefaultLandsatTrueColorEvalScript = landsatTrueColor;
