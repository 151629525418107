import ApiCfSentinel from '../../../../../api/api-cf-sentinel';
import { SentinelFeature } from '../../../../../store/Map/Sentinel/model';

const updateSearchParams = (params: Record<string, string | null>) => {
    const url = new URL(window.location.href);
    Object.entries(params).forEach(([key, value]) => {
        if (value) {
            url.searchParams.set(key, value);
        } else {
            url.searchParams.delete(key);
        }
    });
    window.history.replaceState({}, '', url.toString());
};

const handleUpdateShortUrl = async (feature: SentinelFeature) => {
    if (!feature?.evalScript) return;

    try {
        const response = await ApiCfSentinel.postSentinelShortUrl(feature);
        if (typeof response === 'string') {
            updateSearchParams({ shareId: response });
        } else {
            console.error('Error updating short URL:', response.error);
        }
    } catch (error) {
        console.error('Failed to update short URL:', error);
    }
};

export const updateShortUrl = (selectedFeature?: SentinelFeature) => {
    if (!selectedFeature) {
        updateSearchParams({ shareId: null });
    } else {
        requestAnimationFrame(() => handleUpdateShortUrl(selectedFeature));
    }
};
