const moment = require('moment');

export const last30Days: DateRange = {
    startDate: moment(new Date()).subtract(1, 'months').toDate(),
    endDate: new Date(),
    displayText: 'Last 30 days',
};

export interface DateRange {
    startDate: Date;
    endDate: Date;
    displayText: string;
}

export const getDateOptions = (): DateRange[] => {
    const currentDate = moment(new Date());
    const previousMonth = currentDate.clone().subtract(1, 'months');
    const beginning = moment(new Date()).year(2016).month('January').date(1);
    const date = beginning;
    const dates: DateRange[] = [];

    while (date.isBefore(previousMonth)) {
        const endDate = date.clone();
        const startDate = date.clone().subtract(1, 'months');
        dates.push({
            startDate: startDate.toDate(),
            endDate: endDate.toDate(),
            displayText: startDate.format('MMMM YYYY'),
        });
        date.add(1, 'months');
    }

    const endOfPreviousMonth = currentDate.clone().subtract(1, 'months').endOf('month');
    const startOfPreviousMonth = currentDate.clone().subtract(1, 'months').startOf('month');
    dates.push({
        startDate: startOfPreviousMonth.toDate(),
        endDate: endOfPreviousMonth.toDate(),
        displayText: startOfPreviousMonth.format('MMMM YYYY'),
    });

    return [last30Days].concat(dates.reverse());
};

export const getDateRangeByDate = (date: string): DateRange | undefined => {
    const dateRanges = getDateOptions();
    const parsedDate = moment(date, 'YYYY-MM-DD');
    if (!parsedDate.isValid()) {
        return undefined;
    }
    const displayText = parsedDate.format('MMMM YYYY');

    return dateRanges.find((range) => {
        return range.displayText === displayText;
    });
};
