import { LatLng, LatLngBounds, LatLngBoundsExpression } from 'leaflet';
import React, { useEffect, useState } from 'react';
import { ImageOverlay, Pane, Polygon } from 'react-leaflet';
import { useDispatch, useSelector } from 'react-redux';
import GeoUtil from '../../../lib/geo-util';
import SatelliteUtil from '../../../lib/satellite-util';
import {
    actionSentinelFetchFeaturesError,
    actionSentinelSelectFeature,
    actionSentinelSelectFeatureLoading,
} from '../../../store/Map/Sentinel/actions';
import {
    selectSentinelError,
    selectSentinelIsBoxSelectActive,
    selectSentinelSelectedFeatureOpacity,
    selectSentinelSelectedAOI,
    selectSentinelSelectedFeature,
} from '../../../store/Map/Sentinel/selectors';
import { selectSideDrawerMode } from '../../../store/SideDrawer/selectors';
import ImageOverlayEvented from '../image-overload-evented';
import { isMobileVersion } from '../../../lib/soar-helper';
import ApiCfSentinel from '../../../api/api-cf-sentinel';

interface SentinelFeaturesProps {
    isViewStoryMap?: boolean;
}

const SENTINEL_PANE = 'sentinelPane';

const SentinelFeatures = ({ isViewStoryMap }: SentinelFeaturesProps) => {
    const sentinelSelectedAOI = useSelector(selectSentinelSelectedAOI);
    const sentinelSelectedFeature = useSelector(selectSentinelSelectedFeature);
    const sentinelOverlayOpacity = useSelector(selectSentinelSelectedFeatureOpacity);
    const sideDrawerMode = useSelector(selectSideDrawerMode);
    const isBoxSelectActive = useSelector(selectSentinelIsBoxSelectActive);
    const sentinelError = useSelector(selectSentinelError);

    const [highResLoaded, setHighResLoaded] = useState(false);
    const [apiImageKey, setApiImageKey] = useState('');

    const dispatch = useDispatch();

    const squarePolygonForBounds = (bounds: LatLngBounds): LatLngBounds => {
        const ns = bounds.getNorth() - bounds.getSouth();
        const ew = bounds.getEast() - bounds.getWest();

        if (Math.abs(ns) > Math.abs(ew)) {
            const north = bounds.getSouth() + ns / 2 + ns / 4;
            const south = bounds.getSouth() + ns / 2 - ns / 4;
            const east = bounds.getEast();
            const west = bounds.getWest();
            return new LatLngBounds(new LatLng(south, west), new LatLng(north, east));
        } else {
            const north = bounds.getNorth();
            const south = bounds.getSouth();
            const east = bounds.getWest() + ew / 2 + ew / 4;
            const west = bounds.getWest() + ew / 2 - ew / 4;
            return new LatLngBounds(new LatLng(south, west), new LatLng(north, east));
        }
    };

    const handleOverlayErrorMessage = (errMessage: string, err: string) => {
        if (errMessage && sentinelSelectedFeature && highResLoaded) {
            dispatch(actionSentinelFetchFeaturesError(errMessage));
            SatelliteUtil.sendSentinelSlackMessage(
                `ERROR : ${sideDrawerMode} - FAILED TO LOAD IMAGE`,
                `TYPE: ${err}`,
                `BBOX: ${sentinelSelectedFeature.bbox}, EVAL: ${sentinelSelectedFeature?.evalScript?.name}`
            );
        }
    };

    useEffect(() => {
        async function fetchSentinelImage() {
            if (sentinelSelectedFeature && !sentinelSelectedFeature?.highResolutionPreviewUrl) {
                setHighResLoaded(false);
                if (!isMobileVersion) {
                    dispatch(actionSentinelSelectFeatureLoading(true));
                }
                dispatch(
                    actionSentinelSelectFeature({ ...sentinelSelectedFeature, highResolutionPreviewUrl: undefined })
                );

                if (!sentinelSelectedFeature.previewUrl.length) {
                    const previewUrl = await ApiCfSentinel.updateSentinelPreviewImage(
                        sentinelSelectedFeature,
                        sentinelSelectedFeature?.evalScript
                    );
                    const updatedFeature = { ...sentinelSelectedFeature, previewUrl };
                    dispatch(actionSentinelSelectFeature(updatedFeature));
                }

                try {
                    const selectedQuality = SatelliteUtil.getSelectedQuality(
                        sentinelSelectedFeature,
                        sentinelSelectedFeature.resolution
                    );

                    const { imageUrl, error } = await ApiCfSentinel.updateSentinelImage(
                        sentinelSelectedFeature,
                        sentinelSelectedFeature.evalScript,
                        selectedQuality?.widthPixels ?? 0,
                        selectedQuality?.heightPixels ?? 0,
                        undefined,
                        undefined,
                        'image/png',
                        undefined,
                        (key) => setApiImageKey(key)
                    );

                    if (error) {
                        return;
                    }

                    const updatedFeature = { ...sentinelSelectedFeature, highResolutionPreviewUrl: imageUrl };
                    dispatch(actionSentinelSelectFeature(updatedFeature));
                } catch (error) {
                    console.error('Failed to fetch Sentinel image:', error);
                } finally {
                    setHighResLoaded(true);
                    dispatch(actionSentinelSelectFeatureLoading(false));
                }
            }
        }
        setHighResLoaded(true);
        dispatch(actionSentinelSelectFeatureLoading(false));
        fetchSentinelImage();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        sentinelSelectedFeature?.evalScript?.script,
        sentinelSelectedFeature?.previewUrl,
        sentinelSelectedFeature?.date,
    ]);

    useEffect(() => {
        // if sentinelFeature has been removed while fetching image, cancel the request
        if (!sentinelSelectedFeature && apiImageKey) {
            ApiCfSentinel.cancelToken(apiImageKey);
            setApiImageKey('');
        }
    }, [sentinelSelectedFeature, apiImageKey]);

    return (
        <Pane name={SENTINEL_PANE}>
            {sentinelSelectedAOI && !sentinelSelectedFeature && !isBoxSelectActive && !isMobileVersion ? (
                <React.Fragment>
                    <Polygon
                        positions={GeoUtil.polygonForBounds(sentinelSelectedAOI)}
                        weight={1}
                        interactive={false}
                        fillColor="transparent"
                        key="overlayFilledPolygon"
                    />
                </React.Fragment>
            ) : null}
            {sentinelSelectedAOI && sentinelSelectedFeature ? (
                <React.Fragment>
                    {/* Polygon once a feature has been selected */}

                    {!highResLoaded && (
                        <ImageOverlay
                            url={
                                sentinelError
                                    ? '/assets/image-unavailable/preview_fallback.svg'
                                    : sentinelSelectedFeature.previewUrl
                            }
                            key={sentinelSelectedFeature.previewUrl}
                            zIndex={1}
                            interactive={false}
                            bounds={sentinelSelectedFeature.bbox as LatLngBoundsExpression}
                            opacity={sentinelOverlayOpacity ?? 1.0}
                        />
                    )}

                    {!sentinelError && sentinelSelectedFeature?.highResolutionPreviewUrl ? (
                        <React.Fragment>
                            {!isViewStoryMap ? (
                                <Polygon
                                    positions={GeoUtil.polygonForBounds(sentinelSelectedAOI)}
                                    fillColor="transparent"
                                    interactive={false}
                                    weight={1}
                                />
                            ) : null}

                            <ImageOverlayEvented
                                onStart={() => {
                                    setHighResLoaded(false);
                                }}
                                onLoad={() => {
                                    setHighResLoaded(true);
                                }}
                                onError={(err: Error) => {
                                    setHighResLoaded(true);
                                    handleOverlayErrorMessage(
                                        "Sorry, this service is temporarily unavailable. We're working to get it up and running as soon as possible. Please check in again later.",
                                        err.message
                                    );
                                }}
                                key={sentinelSelectedFeature.highResolutionPreviewUrl}
                                url={sentinelSelectedFeature?.highResolutionPreviewUrl}
                                zIndex={2}
                                bounds={sentinelSelectedFeature.bbox as LatLngBoundsExpression}
                                opacity={sentinelOverlayOpacity ?? 1.0}
                                pane={SENTINEL_PANE}
                            />
                        </React.Fragment>
                    ) : null}

                    {!highResLoaded && (
                        <ImageOverlay
                            url="/assets/loading.gif"
                            zIndex={20}
                            interactive={false}
                            bounds={
                                GeoUtil.polygonForBounds(
                                    squarePolygonForBounds(sentinelSelectedAOI)
                                ) as LatLngBoundsExpression
                            }
                        />
                    )}
                </React.Fragment>
            ) : null}
        </Pane>
    );
};

export default SentinelFeatures;
