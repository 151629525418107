import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import Analytics from '../../../../lib/user-analytics';
import SideDrawerSelect from '../Shared/side-drawer-select';

const CONTAINER_HEIGHT = '42px';

interface MapSearchFilterProps {
    isOpen: boolean;
    onSelectFilter: (filter: string | undefined) => void;
}

interface ListingFilter {
    value: string;
    label: string;
}

const noFilter: ListingFilter = { value: 'none', label: 'No filter' };
const agricultureFilter: ListingFilter = { value: 'agriculture', label: 'Agriculture' };
const climateFilter: ListingFilter = { value: 'climate', label: 'Climate' };
const earthArtFilter: ListingFilter = { value: 'earth-art', label: 'Earth Art' };
const economicFilter: ListingFilter = { value: 'economic', label: 'Economic' };
const environmentFilter: ListingFilter = { value: 'environment', label: 'Environment' };
const geologyFilter: ListingFilter = { value: 'geology', label: 'Geology' };
const historicalFilter: ListingFilter = { value: 'historical', label: 'Historical' };
const imageryFilter: ListingFilter = { value: 'imagery', label: 'Imagery' };
const landcoverFilter: ListingFilter = { value: 'landcover', label: 'Landcover' };
const nauticalFilter: ListingFilter = { value: 'nautical', label: 'Nautical' };
const politicalFilter: ListingFilter = { value: 'political', label: 'Political' };
const radarFilter: ListingFilter = { value: 'radar', label: 'Radar' };
const terrainFilter: ListingFilter = { value: 'terrain', label: 'Terrain' };
const topographicalFilter: ListingFilter = { value: 'topographical', label: 'Topographical' };
const transportFilter: ListingFilter = { value: 'transport', label: 'Transport' };
const urbanFilter: ListingFilter = { value: 'urban', label: 'Urban' };

const listingFilters: ListingFilter[] = [
    noFilter,
    agricultureFilter,
    climateFilter,
    earthArtFilter,
    economicFilter,
    environmentFilter,
    geologyFilter,
    historicalFilter,
    imageryFilter,
    landcoverFilter,
    nauticalFilter,
    politicalFilter,
    radarFilter,
    terrainFilter,
    topographicalFilter,
    transportFilter,
    urbanFilter,
];

const MapSearchFilter = (props: MapSearchFilterProps) => {
    const [filter, setFilter] = useState<ListingFilter>(noFilter);

    const handleChangeFilter = (filterKey: string) => {
        const newFilter = listingFilters.find((f) => f.value === filterKey);
        if (newFilter) {
            Analytics.Event('MapSearchFilter', 'ChangeFilter', newFilter.label);
            setFilter(newFilter);
            if (newFilter.value === 'none') {
                props.onSelectFilter(undefined);
            } else {
                props.onSelectFilter(newFilter.value);
            }
        }
    };

    return (
        <Container isOpen={props.isOpen}>
            {props.isOpen ? (
                <React.Fragment>
                    <Label>Filter maps:</Label>
                    <SelectContainer>
                        <SideDrawerSelect
                            options={listingFilters}
                            value={filter}
                            data-testid="map-filter-select"
                            onChange={(e) => handleChangeFilter(e.value)}
                        />
                    </SelectContainer>
                </React.Fragment>
            ) : null}
        </Container>
    );
};

export default MapSearchFilter;

const OpenAnimation = keyframes`
    0% {
        height: 0;
    }
    100% {
        height: ${CONTAINER_HEIGHT};
    }
`;

const CloseAnimation = keyframes`
    0% {
        height: ${CONTAINER_HEIGHT};
    }
    100% {
        height: 0;
    }
`;

const Container = styled.div<{ isOpen: boolean }>`
    background: rgba(255, 255, 255, 0.05);
    width: calc(100% - 5px);
    height: ${(props) => (props.isOpen ? `${CONTAINER_HEIGHT}` : '0')};
    margin: 0px 5px 5px 0px;
    animation: ${(props) => (props.isOpen ? OpenAnimation : CloseAnimation)} 0.3s ease;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
    display: flex;
`;

const Label = styled.label`
    color: white;
    font-size: 16px;
    margin: 8px 4px 0 4px;
`;

const SelectContainer = styled.div`
    pointer-events: all;
    user-select: none;
    width: calc(100% - 94px);
    display: flex;
    flex-direction: row;
    height: 42px;
`;
