import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { selectSelectedAnnotation } from '../../../store/Annotations/selectors';

interface LocationSearchIconProps {
    isActive: boolean;
    onToggle: (active: boolean) => void;
}

const LocationSearchIcon = ({ isActive, onToggle }: LocationSearchIconProps) => {
    const selectedAnnotation = useSelector(selectSelectedAnnotation);

    const handleToggle = () => {
        onToggle(isActive);
    };

    return (
        <SearchIconButton
            active={isActive}
            toolbarVisible={selectedAnnotation ? true : false}
            onClick={() => handleToggle()}
            title="Search for Location"
        >
            <SearchIcon src="/assets/map-controls/search-location.svg" alt="Search Location" />
        </SearchIconButton>
    );
};

export default LocationSearchIcon;

const SearchIconButton = styled.button<{ active: boolean; toolbarVisible: boolean }>`
    opacity: ${({ active }) => (active ? 1 : 0)};
    z-index: ${({ active, theme }) => (active ? theme.zIndex.drawer : 0)};

    position: absolute;
    right: 15px;
    top: ${(props) => (props.toolbarVisible ? `146px` : `95px`)};

    display: flex;
    justify-content: center;
    align-items: center;

    width: 42px;
    height: 42px;
    padding: 0;
    margin: 0;

    background: rgb(0, 0, 0, 0.8);
    border: 1px solid rgb(81, 81, 81);
    border-radius: 6px;
    box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.3), 0px 23px 36px 3px rgba(0, 0, 0, 0.24),
        0px 9px 44px 8px rgba(0, 0, 0, 0.22);

    outline: none;
`;

const SearchIcon = styled.img`
    width: 32px;
    height: 32px;
    opacity: 0.8;
`;
