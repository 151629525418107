import styled from 'styled-components';
import { TransparentButton } from '../../../../Shared/styled-button';

interface StoryBuilderAddPageProps {
    onAddPage: () => void;
    onCopyPage: () => void;
    onExportPage: () => void;
}

const StoryBuilderAddPage = (props: StoryBuilderAddPageProps) => {
    return (
        <Container>
            <Button onClick={() => props.onAddPage()}>
                <i className="fa fa-plus" /> Add page
            </Button>
            <Button onClick={() => props.onCopyPage()}>
                <i className="fa fa-file" /> Copy page
            </Button>

            <Button onClick={() => props.onExportPage()}>
                <i className="fa fa-download" /> Export
            </Button>
        </Container>
    );
};

export default StoryBuilderAddPage;

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    border-radius: ${(props) => props.theme.borderRadius} !important;

    padding: 8px;
    gap: 8px;
`;

const Button = styled(TransparentButton)`
    text-transform: none;
    display: flex;
    flex-direction: row;
    min-width: 0;
    margin: 0;
    padding: 8px 2px;
    width: auto;
    border: none !important;
    i {
        margin: 4px 6px 4px 0px;
    }
`;
