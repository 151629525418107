import { useEffect, useState } from 'react';
import CountUp from 'react-countup';
import styled from 'styled-components';

const TICKER_START_DELAY = 500; //ms
export const LISTING_COUNT = 838116;

const MapCountTicker = () => {
    const [mapCount, setMapCount] = useState(0);
    useEffect(() => {
        setTimeout(() => {
            setMapCount(LISTING_COUNT);
        }, TICKER_START_DELAY);
    }, []);

    return (
        <CountUp start={0} end={mapCount} duration={3.5} separator="," decimals={0} useEasing={true} useGrouping={true}>
            {({ countUpRef }) => <Ticker ref={countUpRef} />}
        </CountUp>
    );
};

export default MapCountTicker;

const Ticker = styled.span`
    color: white;
`;
