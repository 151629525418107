import { type LayerProps, createElementObject, createTileLayerComponent } from '@react-leaflet/core';
import L from 'leaflet';
import '@maplibre/maplibre-gl-leaflet';

export interface MapLibreTileLayerProps extends L.LeafletMaplibreGLOptions, LayerProps {
    url: string;
    attribution: string;
}

export const MapLibreTileLayer = createTileLayerComponent<L.MaplibreGL, MapLibreTileLayerProps>(
    function createTileLayer({ url, ...options }, context) {
        const layer = L.maplibreGL({ style: url, ...options });
        return createElementObject(layer, context);
    }
);
