import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { SentinelFeature } from '../../../../../store/Map/Sentinel/model';
import SatelliteUtil from '../../../../../lib/satellite-util';
import ApiCfSentinel from '../../../../../api/api-cf-sentinel';
import { trueColor } from '../sentinel-default-evalscripts';
import {
    actionSentinelFetchFeaturesSuccess,
    actionSentinelSelectedAdvancedFeatureLoading,
    actionSentinelSelectFeature,
} from '../../../../../store/Map/Sentinel/actions';
import {
    selectSentinelFeatures,
    selectSentinelSelectedAdvancedFeatureLoading,
    selectSentinelSelectedFeature,
} from '../../../../../store/Map/Sentinel/selectors';
import SentinelAdvancedDownloadButton from './sentinel-advanced-download-button';
import Analytics from '../../../../../lib/user-analytics';
import { updateShortUrl } from '../Shared/use-short-url';

interface SentinelResolutionAndDownloadSelectorProps {
    feature: SentinelFeature;
}

const SentinelResolutionAndDownloadSelector = ({ feature }: SentinelResolutionAndDownloadSelectorProps) => {
    const [selectedResolution, setSelectedResolution] = useState<number>();

    const dispatch = useDispatch();

    const selectedSentinelFeature = useSelector(selectSentinelSelectedFeature);
    const sentinelResults = useSelector(selectSentinelFeatures);
    const selectedSentinelFeatureLoading = useSelector(selectSentinelSelectedAdvancedFeatureLoading);

    const availableResolutions = SatelliteUtil.sentinelQualityEvaluation(feature.bbox);

    const handleLoadSelectedResolution = useCallback(
        async (selectedResolution: number) => {
            if (!selectedResolution || !selectedSentinelFeature) return;
            setSelectedResolution(selectedResolution);
            dispatch(actionSentinelSelectedAdvancedFeatureLoading(true));

            const previewUrl = await ApiCfSentinel.updateSentinelPreviewImage(
                selectedSentinelFeature,
                selectedSentinelFeature.evalScript ?? trueColor
            );

            const featureWithPreview = {
                ...selectedSentinelFeature,
                previewUrl: previewUrl ?? '',
                resolution: selectedResolution,
            };

            dispatch(actionSentinelSelectFeature(featureWithPreview));
            handleUpdateSentinelFeaturesScripts(selectedResolution);

            const selectedQuality = SatelliteUtil.getSelectedQuality(feature, selectedResolution);

            const { imageUrl } = await ApiCfSentinel.updateSentinelImage(
                featureWithPreview,
                featureWithPreview.evalScript ?? trueColor,
                selectedQuality?.widthPixels ?? 0,
                selectedQuality?.heightPixels ?? 0
            );

            dispatch(actionSentinelSelectedAdvancedFeatureLoading(false));

            const featureWithHighRes = {
                ...featureWithPreview,
                highResolutionPreviewUrl: imageUrl ?? '',
            };

            dispatch(actionSentinelSelectFeature(featureWithHighRes));

            updateShortUrl(featureWithHighRes);

            Analytics.Event('Satellite - Sentinel', 'Selected advanced resolution', selectedResolution.toString());
            Analytics.Event(
                'Satellite - Sentinel',
                'Selected to change resolution for bbox with evalscript',
                `${selectedResolution.toString()} ${
                    featureWithHighRes.evalScript?.name
                } - ${featureWithHighRes.bbox.toBBoxString()}`
            );
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [dispatch, feature, selectedSentinelFeature]
    );

    const handleUpdateSentinelFeaturesScripts = (selectedResolution: number) => {
        if (!sentinelResults) {
            return;
        }

        const updatedFeatures = sentinelResults.map((feature) => ({
            ...feature,
            resolution: selectedResolution,
        }));

        dispatch(actionSentinelFetchFeaturesSuccess(updatedFeatures));
        Analytics.Event(
            'Satellite - Sentinel',
            'Selected to change evalscript from advanced view',
            `${selectedResolution.toString()} ${feature.evalScript?.name}`
        );
    };

    useEffect(() => {
        if (selectedSentinelFeature) {
            setSelectedResolution(selectedSentinelFeature?.resolution);
        }
    }, [selectedSentinelFeature]);

    return (
        <React.Fragment>
            <SentinelResolutionAndDownloadSelectorContainer>
                <SentinelResolutionText>Available Resolutions</SentinelResolutionText>
                {availableResolutions?.map(({ resolution, isAvailable }) => (
                    <ResolutionButton
                        key={resolution}
                        available={isAvailable}
                        disabled={!isAvailable || selectedSentinelFeatureLoading}
                        isSelected={selectedResolution === resolution}
                        onClick={() => isAvailable && handleLoadSelectedResolution(resolution)}
                        title={
                            !isAvailable
                                ? `Reduce size of selected area to enable ${resolution}m resolution`
                                : `Available resolution ${resolution}m`
                        }
                    >
                        {!isAvailable && <NotAvailableIcon src="/assets/side-drawer/pixel-not-available.png" />}

                        {isAvailable && selectedSentinelFeatureLoading && selectedResolution === resolution && (
                            <LoadingFeatureIcon className="fas fa-spinner fa-spin" />
                        )}

                        {!(isAvailable && selectedSentinelFeatureLoading && selectedResolution === resolution) &&
                            `${resolution}m`}
                    </ResolutionButton>
                ))}
                <SentinelAdvancedDownloadButton feature={feature} selectedResolution={selectedResolution} />
            </SentinelResolutionAndDownloadSelectorContainer>
        </React.Fragment>
    );
};

export default SentinelResolutionAndDownloadSelector;

const SentinelResolutionAndDownloadSelectorContainer = styled.div`
    display: flex;
    flex-flow: column wrap;
    -webkit-box-pack: center;
    justify-content: center;
    position: absolute;
    top: 72px;
    z-index: 99999;
    margin-left: 3px;
    background-color: rgba(0, 0, 0, 0.85);
    padding: 5px;
    border-radius: 6px;
    width: 100px;
`;

const SentinelResolutionText = styled.span`
    margin: 5px;
    text-align: center;
    color: white;
    font-size: 0.9rem;
    font-weight: bold;
`;

const ResolutionButton = styled.button<{ available: boolean; isSelected: boolean }>`
    padding: 10px 20px;
    margin: 5px;
    border: none;
    border-radius: 26px;
    font-size: 0.9rem;
    width: 80px;
    height: 40px;

    cursor: ${({ available }) => (available ? 'pointer' : 'not-allowed')};
    background-color: ${({ available, isSelected }) =>
        isSelected ? '#eed926' : available ? 'rgba(250, 250, 150)' : 'rgba(255, 255, 255, 0.2)'};
    color: ${({ available }) => (available ? 'black' : 'white')};
    opacity: ${({ available }) => (available ? 1 : 0.5)};

    text-decoration: ${({ isSelected }) => (isSelected ? 'underline' : 'none')};
    font-weight: ${({ isSelected }) => (isSelected ? 'bold' : 'normal')};
    transform: ${({ isSelected }) => (isSelected ? 'scale(1.05)' : 'scale(1)')};
    box-shadow: ${({ isSelected }) => (isSelected ? '0 0 12px 2px rgba(255, 230, 0, 0.1)' : 'none')};
    transition: all 0.3s ease;

    :focus {
        outline: none;
    }

    :hover {
        opacity: ${({ available }) => (available ? 0.8 : 0.5)};
        transform: ${({ available }) => (available ? 'scale(1.05)' : 'scale(1)')};
    }
`;

const NotAvailableIcon = styled.img`
    width: 40px;
    height: 40px;
    position: absolute;
    margin-left: -8px;
    margin-top: -11px;
`;

const LoadingFeatureIcon = styled.i`
    width: 40px;
    height: 40px;
    position: absolute;
    margin-top: -20px;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
`;
