import { LatLng, LatLngBounds } from 'leaflet';
import { Config } from '../App/model';
import { actionTypes as at } from './constants';
import ApiFeaturedContent from '../../api/api-featured-content';
import {
    attachChipToListingDTO,
    ListingDTOWithChip,
} from '../../components/Drawer/SideDrawer/ListingsDrawer/listing-card-chip';
import ApiListings, { GetListingsParams } from '../../api/api-listings';
import GeoUtil from '../../lib/geo-util';
import L from 'leaflet';

export const actionUpdateConfigSuccess = (config: Config) => {
    return {
        type: at.UPDATE_CONFIG_SUCCESS,
        payload: config,
    };
};

export const actionFlyTo = (position: LatLng | LatLngBounds, zoom?: number, immediately?: boolean) => {
    return {
        type: at.FLY_TO,
        payload: {
            position: position,
            zoom: zoom,
            immediately: immediately ? immediately : false,
        },
    };
};

export const actionFlyToZoom = (zoom: number | undefined) => {
    return {
        type: at.FLY_TO_ZOOM,
        payload: zoom,
    };
};

export const actionFlyToOnMap = (location: LatLng | LatLngBounds) => {
    return async (dispatch, getState) => {
        dispatch({
            type: at.FLY_TO_POSITION,
            payload: location,
        });
        if (location) {
            await sleep(1000);
            dispatch(actionFlyToInvalidate());
        }
    };
};

export const actionFlyToInvalidate = () => {
    return {
        type: at.FLY_TO_POSITION_INVALIDATE,
    };
};

export const actionShowLoginDialog = (show: boolean) => {
    return {
        type: at.SHOW_LOGIN_DIALOG,
        payload: show,
    };
};

export const actionResetLoginDialogCloseRedirect = () => {
    return {
        type: at.RESET_LOGIN_DIALOG_CLOSE_REDIRECT,
        payload: {},
    };
};

function sleep(milliseconds) {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
}

export const actionShowNavbar = (showNavBar: boolean) => {
    return {
        type: at.SHOW_NAVBAR,
        payload: showNavBar,
    };
};

export const actionSetMapBounds = (bounds) => {
    return {
        type: at.SET_MAP_BOUNDS,
        payload: bounds,
    };
};

export const actionSetMapZoom = (zoom: number | undefined) => {
    return {
        type: at.SET_MAP_ZOOM,
        payload: zoom,
    };
};

export const actionSetMapMoveEnd = (moveEnd: boolean) => {
    return {
        type: at.SET_MAP_MOVE_END,
        payload: moveEnd,
    };
};

export const actionShowUploadDialog = (show: boolean) => {
    return {
        type: at.SHOW_UPLOAD_DIALOG,
        payload: show,
    };
};

export const actionFetchGlobalListings = () => {
    return async (dispatch, getState) => {
        const hottestMaps: ListingDTOWithChip[] = (await ApiFeaturedContent.getHottestMaps()).slice(0, 5);
        const hottestMapsWithChip = hottestMaps.map((r) => {
            r.chip = 'featured';
            return r;
        });

        const newestMaps: ListingDTOWithChip[] = (await ApiListings.getListings({ limit: 6 })).listings.slice(0, 5);
        const newestMapsWithChip = newestMaps.map((r) => {
            r.chip = 'new';
            return r;
        });

        const worldBounds = L.latLngBounds(L.latLng(-90, -180), L.latLng(90, 180));
        const aoiWKT = GeoUtil.latLngBoundsToWKT(worldBounds);
        const getListingsParams: GetListingsParams = {
            aoi: aoiWKT,
            limit: 25,
            offset: 0,
        };

        const globalListings = await ApiListings.getListings(getListingsParams);
        const globalListingsWithChip = globalListings.listings.map((r) => {
            return attachChipToListingDTO(r);
        });

        dispatch({
            type: at.FETCH_GLOBAL_LISTING,
            payload: [...hottestMapsWithChip, ...newestMapsWithChip, ...globalListingsWithChip],
        });
    };
};
