import styled from 'styled-components';
import Select from 'react-select';

interface ReactSelectProps {
    value: string;
    label: string;
}

interface SideDrawerSelectProps {
    options: ReactSelectProps[];
    value: ReactSelectProps;
    onChange: (value: ReactSelectProps) => void;
    icon?: string;
    isMobile?: boolean;
}

const SideDrawerSelect = ({ options, value, onChange, icon, isMobile = false }: SideDrawerSelectProps) => {
    return (
        <SelectWrapper>
            {icon && (
                <SelectIcon>
                    <img src={icon} alt="icon" />
                </SelectIcon>
            )}
            <StyledReactSelect
                options={options}
                value={value}
                onChange={(selectedOption: ReactSelectProps | null) => {
                    if (!selectedOption) return;
                    onChange(selectedOption);
                }}
                isSearchable={false}
                styles={customSelectStyles(icon, isMobile)}
                icon={icon}
            />
        </SelectWrapper>
    );
};

export default SideDrawerSelect;

const StyledReactSelect = styled(Select)<{ icon?: string }>`
    width: 100%;
`;

const SelectWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
`;

const SelectIcon = styled.div`
    position: absolute;
    left: 10px;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const REACT_SELECT_FONT_SIZE = '0.9rem';
const customSelectStyles = (icon?: string, isMobile = false) => ({
    control: (provided) => ({
        ...provided,
        fontSize: REACT_SELECT_FONT_SIZE,
        backgroundColor: 'transparent',
        border: '1px solid rgba(255, 255, 255, 0.2)',
        borderRadius: '6px',
        color: 'white',
        borderColor: '#eed926',
        boxShadow: 'none',
        paddingLeft: icon ? '25px' : '12px',
        ...(!isMobile
            ? {
                  '&:hover': {
                      borderColor: '#eed926',
                      boxShadow: '0 0 0 1px #eed926',
                  },
                  '&:focus': {
                      borderColor: '#eed926',
                      boxShadow: '0 0 0 1px #eed926',
                  },
                  '&:active': {
                      borderColor: '#eed926',
                      boxShadow: '0 0 0 1px #eed926',
                  },
              }
            : {}),
    }),
    input: (provided) => ({
        ...provided,
        fontSize: REACT_SELECT_FONT_SIZE,
    }),
    placeholder: (provided) => ({
        ...provided,
        fontSize: REACT_SELECT_FONT_SIZE,
    }),
    singleValue: (provided) => ({
        ...provided,
        fontSize: REACT_SELECT_FONT_SIZE,
        color: 'white',
    }),
    menu: (provided) => ({
        ...provided,
        fontSize: REACT_SELECT_FONT_SIZE,
        backgroundColor: '#161616',
        borderRadius: '6px',
        overflow: 'hidden',
        border: '1px solid rgba(255, 255, 255, 0.2)',
    }),
    menuList: (provided) => ({
        ...provided,
        overflowY: 'auto',
        scrollbarWidth: 'thin',
        scrollbarColor: '#eed926 #343a40',
        '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
            backgroundColor: '#343a40',
        },
        '&::-webkit-scrollbar': {
            width: '8px',
            backgroundColor: '#343a40',
        },
        '&::-webkit-scrollbar-thumb': {
            boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
            backgroundColor: '#eed926',
            borderRadius: '6px',
        },
    }),
    option: (provided, state) => ({
        ...provided,
        fontSize: REACT_SELECT_FONT_SIZE,
        backgroundColor: state.isSelected ? '#EED926' : 'transparent',
        color: state.isSelected ? 'black' : 'white',
        ...(!isMobile
            ? {
                  '&:hover': {
                      backgroundColor: '#eed926',
                      color: 'black',
                  },
              }
            : {}),
    }),
});
