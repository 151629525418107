import { LatLng, LatLngBounds } from 'leaflet';
import { StoaryPageMapLayer } from '../../../../api/stoaryModel';
import { handleDeprecatedSentinelEval } from '../../../Drawer/Satellites/Sentinel/sentinel-deprecated-request-util';
import SentinelUtil from '../../../../lib/sentinel-util';
import { SentinelFeature } from '../../../../store/Map/Sentinel/model';

const createLatLngBounds = (bounds: { _northEast; _southWest }): LatLngBounds => {
    const { _northEast, _southWest } = bounds;

    const northEast = new LatLng(_northEast.lat, _northEast.lng);
    const southWest = new LatLng(_southWest.lat, _southWest.lng);

    return new LatLngBounds(southWest, northEast);
};

export const parseSatellite = async (mapLayer): Promise<SentinelFeature> => {
    const satelliteFeature = mapLayer?.satelliteFeature;
    const bbox = createLatLngBounds(satelliteFeature.bbox);

    if (satelliteFeature.evalScript) {
        return { ...satelliteFeature, bbox };
    }

    const evalScript = handleDeprecatedSentinelEval(satelliteFeature.layer, satelliteFeature.satellite);

    return {
        ...satelliteFeature,
        date: satelliteFeature.date.split('T')[0],
        highResolutionPreviewUrl: undefined,
        resolution: SentinelUtil.pixelResolutionForBounds(bbox),
        collection: evalScript.sentinelSatelliteType,
        evalScript: evalScript,
        cloudCover: 0,
        constellation: evalScript.sentinelSatelliteType,
        bbox: bbox,
    };
};

/* used to parse old implementation of the mapLayers */
export const parseMapLayers = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    mapLayer: any
): StoaryPageMapLayer => {
    if (mapLayer.mapId) {
        return {
            title: 'Map Layer',
            listingId: mapLayer.mapId,
            author: 'Soar User',
            opacity: mapLayer.transparency,
        };
    }
    if (mapLayer.satelliteFeature) {
        const title =
            mapLayer.satelliteFeature?.evalScript.name ?? mapLayer.satelliteFeature?.layer?.replace(/_/im, ' ');
        const updatedFeature = {
            ...mapLayer.satelliteFeature,
            highResolutionPreviewUrl: undefined,
        };

        return {
            title: `${title} ${new Date(updatedFeature.date).toLocaleDateString()}`,
            author: updatedFeature.satellite,
            opacity: mapLayer?.opacity ?? mapLayer?.transparency ?? 1,
            satelliteFeature: updatedFeature,
        };
    }
    return { ...mapLayer, opacity: mapLayer.opacity ?? 1 };
};
