import Api from './api';
import { AnalyticsAction, AnalyticsNote, SatelliteProvider } from './model';
import ApiUser from './api-user';

export default class ApiAnalytics extends Api {
    public static postAnalytics(
        provider: SatelliteProvider,
        product: string,
        action: AnalyticsAction,
        geometryWKT: string,
        id: string,
        previewUrl: string
    ): Promise<void> {
        const data = {
            provider: provider.toString(),
            product,
            action,
            geometryWKT,
            id,
            previewUrl,
        };
        return (
            this.axios
                .post('/v1/analytics', data)
                .then((res) => {
                    return res.data;
                })
                .then((_) => {
                    if (action === AnalyticsAction.VIEW) {
                        ApiUser.updateMyPoints();
                    }
                })
                // we catch and ignore error here
                .catch((err) => console.log(err))
        );
    }

    public static postAnalyticsListing(
        action: AnalyticsAction,
        note: AnalyticsNote,
        listingId: number,
        isTask?: boolean
    ): Promise<void> {
        const data = {
            action: action, // view or download
            note: note, //  description of event
            id: listingId,
            isTask,
        };
        return (
            this.axios
                .post('/v1/analytics/listing', data)
                .then((res) => {
                    return res.data;
                })
                .then((_) => {
                    ApiUser.updateMyPoints();
                })
                // we catch and ignore error here
                .catch((err) => console.log(err))
        );
    }

    public static postAnalyticsEvent(category: string, action: string, label?: string, note?: string): Promise<void> {
        const data = {
            category: category,
            action: action,
            label: label,
            note: note,
        };
        return this.axios.post('/v1/analytics/generic', data);
    }

    // Backward compatibility so the old data is still usable and no changes needed for the admin or backend
    public static mapSatelliteTypeToProduct(satellite: string): string {
        if (satellite === 'sentinel-2-l1c') {
            return 'Sentinel-2 L1C';
        }

        if (satellite === 'sentinel-2-l2a') {
            return 'Sentinel-2 L2A';
        }

        if (satellite === 'sentinel-1-grd') {
            return 'Sentinel-1 GRD';
        }

        if (satellite === 'landsat-ot-l1') {
            return 'Landsat-8 L1C';
        }

        return satellite;
    }
}
