import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { actionSentinelSelectFeatureOpacity } from '../../../../../store/Map/Sentinel/actions';
import { selectSentinelSelectedFeatureOpacity } from '../../../../../store/Map/Sentinel/selectors';

const SentinelMapOpacitySlider = () => {
    const sentinelOpacity = useSelector(selectSentinelSelectedFeatureOpacity);

    const dispatch = useDispatch();
    const setTileLayerOpacity = useCallback(
        (opacity: number): void => {
            dispatch(actionSentinelSelectFeatureOpacity(opacity));
        },
        [dispatch]
    );
    const [opacity, setOpacity] = useState(sentinelOpacity || 1.0);

    useEffect(() => {
        setOpacity(sentinelOpacity || 1.0);
    }, [sentinelOpacity]);

    return (
        <SliderContainer className="opacity-slider">
            <SliderText>Transparency:</SliderText>
            <SliderTextValue>{parseInt(`${opacity * 100}`)}%</SliderTextValue>
            <SliderInputContainer>
                <Slider
                    type="range"
                    value={opacity * 100}
                    step={0.01}
                    onChange={(e) => {
                        if (document.activeElement === document.getElementById('listing-comment-input')) {
                            document.getElementById('listing-comment-input')?.blur();
                        }
                        const newOpacity = parseInt(e.target.value) / 100;
                        setOpacity(newOpacity);
                        setTileLayerOpacity(newOpacity || 0.0001);
                    }}
                />
            </SliderInputContainer>
        </SliderContainer>
    );
};

export default SentinelMapOpacitySlider;

const SliderContainer = styled.div`
    width: 100%;
    display: flex;
    margin-top: 5px;
`;

const SliderText = styled.p`
    vertical-align: middle;
    color: white;
    font-size: ${(props) => props.theme.fontSize.normal};
    margin: 0;
    padding: 0;
`;

const SliderInputContainer = styled.div`
    width: 100%;
    margin-left: 4px;
`;

const SliderTextValue = styled.div`
    width: 40px;
    text-align: right;
    color: rgba(255, 255, 255, 0.5);
    font-size: ${(props) => props.theme.fontSize.normal};
    margin-right: 4px;
    flex: 1 0 36px;
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Slider = styled.input<any>`
    width: 100%;
    background: linear-gradient(
        to left,
        rgba(255, 255, 255, 0.3) ${(props) => 100 - props.value}%,
        #eed926 ${(props) => 100 - props.value}%
    );
    height: 2px;
    padding: 0;
    box-shadow: none !important;
    border: none !important;
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    border-radius: 5px;
    pointer-events: all;
    vertical-align: middle;

    &::-moz-range-thumb {
        appearance: none;
        -webkit-appearance: none;
        background: #eed926;
        height: 12px;
        width: 12px;
        border-radius: 50%;
        cursor: pointer;
    }

    &::-webkit-slider-thumb {
        appearance: none;
        -webkit-appearance: none;
        background: #eed926;
        height: 12px;
        width: 12px;
        border-radius: 50%;
        cursor: pointer;
    }

    &:hover::-moz-range-thumb {
        appearance: none;
        -webkit-appearance: none;
        background: #eed926;
        border-radius: 50%;
        cursor: pointer;
    }

    &:hover::-webkit-slider-thumb {
        appearance: none;
        -webkit-appearance: none;
        background: #eed926;
        border-radius: 50%;
        cursor: pointer;
    }

    &:focus {
        background: linear-gradient(
            to left,
            rgba(255, 255, 255, 0.3) ${(props) => 100 - props.value}%,
            #eed926 ${(props) => 100 - props.value}%
        );
    }
`;
