import { LatLng, LatLngBounds } from 'leaflet';
import { useEffect, useState } from 'react';
import { isMobileVersion } from '../../../../lib/soar-helper';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import ApiAnalytics from '../../../../api/api-analytics';
import { AnalyticsAction, SatelliteProvider } from '../../../../api/model';
import Analytics from '../../../../lib/user-analytics';
import { actionFlyToOnMap } from '../../../../store/App/actions';
import {
    actionSentinelChangeOpacity,
    actionSentinelEndBoxSelect,
    actionSentinelOpenDownload,
    actionSentinelOpenShare,
    actionSentinelSelectFeature,
    actionSentinelSelectFeatureOpacity,
} from '../../../../store/Map/Sentinel/actions';
import { SentinelFeature } from '../../../../store/Map/Sentinel/model';
import {
    selectSentinelError,
    selectSentinelOpacity,
    selectSentinelSelectedAdvancedFeatureLoading,
    selectSentinelSelectedFeature,
    selectSentinelSelectedFeatureOpacity,
} from '../../../../store/Map/Sentinel/selectors';
import SentinelAdvancedFeatures from './SentinelAdvanced/sentinel-advanced-features';
import { StyledButton } from '../../../Shared/styled-button';
import ApiCfSentinel, { EvalScript } from '../../../../api/api-cf-sentinel';
import GeoUtil from '../../../../lib/geo-util';
import { updateShortUrl } from './Shared/use-short-url';
import UriHelper from '../../../../lib/uri-helper';
import SentinelUtil from '../../../../lib/sentinel-util';
import SentinelDrawerThumbnail from './sentinel-drawer-thumbnail';

const moment = require('moment');

interface SentinelItemProps {
    feature: SentinelFeature;
    evalScript?: EvalScript;
}

interface SentinelItemStyleProps {
    isSelected?: boolean;
    isMobile?: boolean;
    isDisabled?: boolean;
    width?: string;
}

const Detail = (props: { name: string; value: string; isSelected: boolean }) => {
    return (
        <DetailRow>
            <DetailName isSelected={props.isSelected} isMobile={isMobileVersion}>
                {props.name}:
            </DetailName>
            <DetailValue>{props.value}</DetailValue>
        </DetailRow>
    );
};

const SentinelItem = (props: SentinelItemProps) => {
    // TODO UPDATE - we have 2 opacity states floating around?
    const sentinelSelectedFeatureOpacity = useSelector(selectSentinelSelectedFeatureOpacity);
    const sentinelOpacity = useSelector(selectSentinelOpacity);
    const sentinelError = useSelector(selectSentinelError);
    const selectedSentinelFeature = useSelector(selectSentinelSelectedFeature);
    const isLoading = useSelector(selectSentinelSelectedAdvancedFeatureLoading);

    const dispatch = useDispatch();
    const openDownload = () => dispatch(actionSentinelOpenDownload());
    const flyTo = (position: LatLng | LatLngBounds) => dispatch(actionFlyToOnMap(position));
    const openShare = () => dispatch(actionSentinelOpenShare());

    const [previewUrl, setPreviewUrl] = useState<string | undefined>(undefined);
    const [isSelected, setIsSelected] = useState(false);
    const [apiImageKey, setApiImageKey] = useState('');

    useEffect(() => {
        // DEV NOTE: The isSelected logic works correctly with the new shareId, but not with the old url share.
        // While it is possible it adds unnecessary complexity for something so trivial with a low occurance rate...
        const selected = props.feature.id === selectedSentinelFeature?.id && !isMobileVersion;

        setIsSelected(selected);
    }, [props.feature.id, selectedSentinelFeature?.id]);

    const handleSelectFeature = (feature: SentinelFeature) => {
        if (isSelected) {
            return;
        }

        updateShortUrl(feature);

        const updatedFeature: SentinelFeature = {
            ...feature,
            previewUrl: previewUrl ?? '',
            highResolutionPreviewUrl: undefined,
        };

        ApiCfSentinel.clearAllCancelTokens('Changed selection');
        dispatch(actionSentinelSelectFeature(updatedFeature));
        dispatch(actionSentinelSelectFeatureOpacity(sentinelOpacity[feature.id]));

        dispatch(actionSentinelEndBoxSelect());

        if (!isMobileVersion) {
            flyTo(feature.bbox.pad(0.125));
        }

        Analytics.Event(
            'Satellite - Sentinel',
            'Selected sentinel feature',
            `${feature.evalScript?.name} - ${feature.bbox.toBBoxString()}`
        );
        requestAnimationFrame(() => {
            const shareId = UriHelper.tryGetParam('shareId');
            ApiAnalytics.postAnalytics(
                SatelliteProvider.SINERGISE,
                ApiAnalytics.mapSatelliteTypeToProduct(props.feature.evalScript.sentinelSatelliteType),
                AnalyticsAction.VIEW,
                GeoUtil.latLngBoundsToWKT(props.feature.bbox),
                props.feature.evalScript?.name ?? 'Custom Script',
                shareId ?? ''
            );
        });
    };

    // this should set the selected satellite's opacity when exiting from draw tools
    useEffect(() => {
        if (isSelected && sentinelSelectedFeatureOpacity && !sentinelOpacity[props.feature.id]) {
            dispatch(actionSentinelChangeOpacity({ id: props.feature.id, opacity: sentinelSelectedFeatureOpacity }));
        }
    }, [dispatch, isSelected, sentinelSelectedFeatureOpacity, props.feature.id, sentinelOpacity]);

    useEffect(() => {
        const handlePreviewUrl = async () => {
            if (props.feature) {
                // Cancel any previous requests for the preview loading
                ApiCfSentinel.cancelToken(apiImageKey);
                setApiImageKey('');
                const previewUrl = await ApiCfSentinel.generateImageThumbnail(
                    props.feature,
                    props.evalScript ?? props.feature.evalScript,
                    (key) => setApiImageKey(key)
                );
                setPreviewUrl(previewUrl);
            }
        };
        handlePreviewUrl();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.evalScript, props.feature]);

    return (
        <SatelliteCardItem
            data-testid="sentinel-feature"
            isSelected={isSelected}
            onClick={() => handleSelectFeature(props.feature)}
        >
            <SentinelDetailsContainer isSelected={isSelected}>
                <PreviewImageContainer>
                    <PreviewImage isSelected={isSelected} alt="Sentinel Preview" src={previewUrl} />
                </PreviewImageContainer>
                <DetailsContainer isSelected={isSelected}>
                    <Detail
                        isSelected={isSelected}
                        name="Date"
                        value={moment(props.feature.date).format('MMM Do YYYY')}
                    />
                    {!SentinelUtil.isLandsatSatellite(props.feature.satellite) ? (
                        <Detail isSelected={isSelected} name="Cloud Cover" value={`${props.feature.cloudCover}%`} />
                    ) : null}
                    <Detail
                        isSelected={isSelected}
                        name={isMobileVersion ? 'Resolution' : 'Pixel Resolution'}
                        value={`${props.feature.resolution}m`}
                    />
                </DetailsContainer>
            </SentinelDetailsContainer>
            <ButtonContainer
                isSelected={isSelected}
                isDisabled={sentinelError ? true : false}
                width={!SentinelUtil.isLandsatSatellite(props.feature.satellite) ? '100%' : '205px'}
            >
                <SentinelOptionButton
                    title="Download"
                    onClick={() => {
                        openDownload();
                        Analytics.Event(
                            'Satellite - Sentinel',
                            'Clicked download',
                            `${props.feature.evalScript.name} - ${props.feature.bbox.toBBoxString()}`
                        );
                    }}
                    isDisabled={sentinelError ? true : false}
                    isSelected={isSelected}
                    disabled={isLoading}
                >
                    Download
                    <SentinelOptionIcon src="/assets/floating-drawer-icons/download-icon.png" />
                </SentinelOptionButton>
                <SentinelOptionButton
                    title="Share"
                    onClick={() => {
                        openShare();
                        Analytics.Event(
                            'Satellite - Sentinel',
                            'Clicked share',
                            `${props.feature.evalScript.name} - ${props.feature.bbox.toBBoxString()}`
                        );
                    }}
                    isDisabled={sentinelError ? true : false}
                    isSelected={isSelected}
                    disabled={isLoading}
                >
                    Share
                    <SentinelOptionIcon src="/assets/floating-drawer-icons/share-icon.png" />
                </SentinelOptionButton>
                {!SentinelUtil.isLandsatSatellite(props.feature.satellite) && !isMobileVersion ? (
                    <SentinelAdvancedFeatures />
                ) : null}
            </ButtonContainer>
        </SatelliteCardItem>
    );
};

export default SentinelItem;

const SatelliteCardItem = styled.div<SentinelItemStyleProps>`
    cursor: pointer;
    height: auto;
    width: 98%;
    min-width: 98%;
    border-radius: 6px;
    margin: 0px 0px 5px;
    border: ${(props) =>
        props.isMobile
            ? '1px solid rgba(255, 255, 255, 0.1)'
            : props.isSelected
            ? '1px solid #eed926'
            : '1px solid rgba(255, 255, 255, 0.1)'};
    background: rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: column;

    ${(props) =>
        !props.isMobile &&
        `
        &:hover {
            border: 1px solid #eed926;
        }
    `}

    @media only screen and (max-width: 600px) {
        margin-left: 4px;
    }
`;

const SentinelDetailsContainer = styled.div<SentinelItemStyleProps>`
    display: flex;
    flex-direction: row;
    border-bottom: ${(props) => (props.isSelected ? '1px solid rgba(255, 255, 255, 0.1)' : 'none')};
`;

const PreviewImageContainer = styled.div`
    height: 68px;
    width: 68px;
`;

const PreviewImage = styled(SentinelDrawerThumbnail)<SentinelItemStyleProps>`
    height: 100%;
    width: 100%;
    border-top-left-radius: 6px;
    border-bottom-left-radius: ${(props) => (props.isSelected ? '0px' : '6px')};
    margin: 0;
    padding: 0;
    object-fit: cover;
`;

const DetailsContainer = styled.div<SentinelItemStyleProps>`
    width: 80%;
    padding: 8px 2px 0px 10px;
`;

const DetailRow = styled.div`
    user-select: none;
    display: flex;
`;

const DetailName = styled.span<SentinelItemStyleProps>`
    color: white;
    font-size: 0.9rem;
    width: ${(props) => (props.isMobile ? '80px' : '105px')};
`;

const DetailValue = styled.span`
    color: white;
    font-size: 0.9rem;
    margin-top: -1px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 110px;

    &.slider-detail-value {
        margin-top: -5px;
    }
`;

const ButtonContainer = styled.div<SentinelItemStyleProps>`
    display: ${(props) => (props.isSelected ? 'flex' : 'none')};
    flex-direction: row;
    justify-content: space-evenly;
    opacity: ${(props) => (props.isSelected ? 1 : 0)};
    transition: opacity 0.3s ease-in;
    animation: ${({ isSelected }) => (isSelected ? 'fadeIn' : 'fadeOut')} 0.3s ease-in;
    margin: 4px 0px;
    width: ${(props) => props.width};

    @keyframes fadeIn {
        0% {
            opacity: 0;
        }
        75% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
    @keyframes fadeOut {
        0% {
            opacity: 1;
        }
        75% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }
`;

export const SentinelOptionButton = styled(StyledButton)<SentinelItemStyleProps>`
    border-radius: 6px;
    padding: 4px 10px 2px 10px;
    font-size: 0.9rem;
    user-select: ${(props) => (props.isDisabled ? 'none' : 'auto')};
    pointer-events: ${(props) => (props.isDisabled ? 'none' : 'auto')};

    :focus {
        outline: none;
    }

    @media (max-width: 650px) {
        display: none;
    }
`;

export const SentinelOptionIcon = styled.img`
    height: 16px;
    width: 16px;
    margin-left: 5px;
    margin-top: -3px;
`;
