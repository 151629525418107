import { useEffect, useState } from 'react';
import styled from 'styled-components';
import ListingHelper from '../../../../../lib/listing-helper';
import PreviewTile from '../../../../Shared/preview-tile';
import MapViewHistoryHelper from '../../../../../lib/map-view-history-helper';
import { StoaryPageMapLayer } from '../../../../../api/stoaryModel';
import UserHelper from '../../../../../lib/user-helper';

interface StoryBuilderPageItemMapLayerProps {
    mapLayer: StoaryPageMapLayer;
    setShowSearchModal: () => void;
    onUpdateMapLayerFromPage: (mapLayer: StoaryPageMapLayer) => void;
    onDeleteMapLayerFromPage: (mapLayer: StoaryPageMapLayer) => void;
}

const StoryBuilderPageItemMapLayer = (props: StoryBuilderPageItemMapLayerProps) => {
    const [selectedPreviewUrl, setSelectedPreviewUrl] = useState<string | undefined>(undefined);

    const handleMapLayerOpacityChange = (newOpacity: number) => {
        const mapLayer = { ...props.mapLayer, opacity: newOpacity };
        props.onUpdateMapLayerFromPage(mapLayer);
    };

    useEffect(() => {
        if (props.mapLayer) {
            const previewUrl = props.mapLayer.satelliteFeature
                ? props.mapLayer.satelliteFeature.previewUrl
                : ListingHelper.getPreviewUrlForListing(props.mapLayer.listingId as number, 'small');
            setSelectedPreviewUrl(previewUrl);
        }
    }, [props.mapLayer]);

    useEffect(() => {
        if (props.mapLayer && props.mapLayer.listingId) {
            MapViewHistoryHelper.setLastUsedStoaryMap(
                props.mapLayer.listingId,
                props.mapLayer.title,
                props.mapLayer.author
            );
        }
    }, [props.mapLayer]);

    const authorName = props.mapLayer.satelliteFeature
        ? UserHelper.formatToUppercaseAndLowercaseText(props.mapLayer.author)
        : props.mapLayer.author || 'Soar User';

    return (
        <Row>
            <PreviewContainer title="Swap this with another map">
                <PreviewTile
                    onClick={() => props.setShowSearchModal()}
                    previewUrl={selectedPreviewUrl}
                    title={props.mapLayer.title}
                    subText={`by ${authorName}`}
                    opacity={props.mapLayer.opacity}
                    showPercentageText={true}
                    onUpdateOpacity={handleMapLayerOpacityChange}
                    isSatelliteFeature={props.mapLayer.satelliteFeature ? true : false}
                />
            </PreviewContainer>
            <DeleteIcon
                src="/assets/annotations/icon-trash-red.svg"
                title="Remove map from this page"
                onClick={() => {
                    props.onDeleteMapLayerFromPage(props.mapLayer);
                }}
            />
        </Row>
    );
};

export default StoryBuilderPageItemMapLayer;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 68px;
    margin: 0 0 18px 0;
`;

const PreviewContainer = styled.div`
    display: block;
    cursor: pointer;
    height: 68px;
    width: calc(100% - 32px);
    border-radius: ${(props) => props.theme.borderRadius};
    overflow: hidden;
    position: relative;
    border: ${(props) => `1px solid ${props.theme.color.gray}`};
`;

const DeleteIcon = styled.img`
    width: 24px;
`;
