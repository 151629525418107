import React, { useEffect, useState } from 'react';
import { Collapse } from 'reactstrap';
import styled from 'styled-components';
import { StyledButton } from '../../../../Shared/styled-button';
import { EvalScript } from '../../../../../api/api-cf-sentinel';

interface SentinelAdvancedScriptEditorProps {
    evalScript: EvalScript;
    setEvalScript: (evalScript: EvalScript) => void;
}

const COPIED_ANIMATION_RESET_TIMEOUT = 1500;

const SentinelAdvancedScriptEditor = ({ evalScript, setEvalScript }: SentinelAdvancedScriptEditorProps) => {
    const [copied, setCopied] = useState(false);
    const [showEditor, setShowEditor] = useState(false);
    const [customScript, setCustomScript] = useState(evalScript.script);

    const handleSubmitCustomScript = () => {
        const customEvalScript = {
            ...evalScript,
            name: 'Custom Script',
            script: customScript,
            description: 'Custom Script',
        };
        setCustomScript(customScript);
        setEvalScript(customEvalScript);
    };

    const handleCopy = () => {
        if (copied) return;
        navigator.clipboard.writeText(customScript);
        setCopied(true);
        setTimeout(() => setCopied(false), COPIED_ANIMATION_RESET_TIMEOUT);
    };

    useEffect(() => {
        if (evalScript.name !== 'Custom Script') {
            setShowEditor(false);
        }
        setCustomScript(evalScript.script);
    }, [evalScript]);

    return (
        <React.Fragment>
            <AdvancedScriptToggle onClick={() => setShowEditor(!showEditor)}>
                <AdvancedScriptToggleText>Open Edit Script Window</AdvancedScriptToggleText>
                <AdvancedScriptToggleIcon src="/assets/side-drawer/advanced-eval-icon-white.png" />
            </AdvancedScriptToggle>
            <AdvancedScriptsCollapse isOpen={showEditor}>
                <AdvancedScriptsEditor>
                    <AdvancedScriptsEditorContainer>
                        <AdvancedScriptCopy onClick={handleCopy} className={copied ? 'bouncing' : ''}>
                            {copied ? 'Copied!' : 'Copy'}
                        </AdvancedScriptCopy>
                        <AdvancedScriptsEditorTextarea
                            value={customScript}
                            onChange={(e) => setCustomScript(e.target.value)}
                            spellCheck={false}
                        />
                    </AdvancedScriptsEditorContainer>
                </AdvancedScriptsEditor>
                <AdvancedScriptButton
                    disabled={evalScript.script === customScript}
                    onClick={handleSubmitCustomScript}
                    title={evalScript.script === customScript ? 'Update the script to apply changes' : ''}
                >
                    Apply & generate preview
                </AdvancedScriptButton>
            </AdvancedScriptsCollapse>
        </React.Fragment>
    );
};

export default SentinelAdvancedScriptEditor;

const AdvancedScriptToggle = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
`;

const AdvancedScriptToggleText = styled.span`
    color: white;
    font-size: 0.9rem;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    justify-content: center;

    :hover {
        cursor: pointer;
        color: #eed926;
    }
`;

const AdvancedScriptToggleIcon = styled.img`
    width: 28px;
    height: 28px;

    :hover {
        cursor: pointer;
    }
`;

const AdvancedScriptsCollapse = styled(Collapse)`
    margin-top: 10px;
`;

const AdvancedScriptsEditor = styled.div`
    display: flex;
    flex-direction: column;
    height: calc(-540px + 100vh);
`;

const AdvancedScriptsEditorContainer = styled.div`
    position: relative;
    width: 100%;
    flex: 1;
`;

const AdvancedScriptsEditorTextarea = styled.textarea`
    width: 100%;
    height: 100% !important;
    padding: 10px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 6px;
    font-family: monospace;
    font-size: 14px;
    background-color: rgba(255, 255, 255, 0.05);
    color: white;
    resize: none;

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #343a40;
    }

    &::-webkit-scrollbar {
        width: 8px;
        background-color: #343a40;
    }

    &::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #eed926;
        border-radius: 4px;
    }

    :focus-visible {
        outline: none;
    }
`;

const AdvancedScriptCopy = styled(StyledButton)`
    position: absolute;
    top: 5px;
    right: 10px;
    padding: 4px 8px;
    font-size: 0.9rem;
    background-color: #eed926;
    color: black;
    border: none;
    border-radius: 6px;
    transition: transform 0.2s ease-in-out;

    &.bouncing {
        animation: bounce 0.4s ease;
    }

    :hover {
        cursor: pointer;
        opacity: 0.8;
    }

    @keyframes bounce {
        0% {
            transform: scale(1);
        }
        50% {
            transform: scale(1.1);
        }
        100% {
            transform: scale(1);
        }
    }
`;

const AdvancedScriptButton = styled(StyledButton)`
    background-color: #eed926;
    color: black;
    border: none;
    border-radius: 6px;
    padding: 10px 20px;
    display: flex;
    margin: 10px auto 0px auto;
    font-size: 0.9rem;

    :hover {
        cursor: pointer;
        background-color: #d3b827;
    }
`;
