import { ListingCategories } from '../../../api/model';
import { actionTypes as at } from './constants';
import { ListingGroup } from './model';

import { isMobileVersion } from '../../../lib/soar-helper';
import { actionUpdateActiveSearchPosition } from '../../Search/actions';
import { actionActiveMapCleared } from '../SuperMap/actions';

export const actionSetSelectedContinent = (listingGroup: ListingGroup | undefined) => {
    return {
        type: at.SET_SELECTED_CONTINENT,
        payload: listingGroup,
    };
};

export const actionSetSelectedCategory = (category: ListingCategories | undefined) => {
    return {
        type: at.SET_SELECTED_CATEGORY,
        payload: category,
    };
};

export const actionSetSelectedSearchTerm = (searchTerm: string | undefined) => {
    return {
        type: at.SET_SELECTED_SEARCH_TERM,
        payload: searchTerm,
    };
};

export const actionSetSearchFocused = (focused: boolean) => {
    return {
        type: at.SET_SEARCH_FOCUSED,
        payload: focused,
    };
};

export const actionClearSelectedSearch = () => {
    return (dispatch) => {
        dispatch(actionSetSelectedCategory(undefined));
        dispatch(actionSetSelectedContinent(undefined));
        dispatch(actionSetSelectedSearchTerm(undefined));
        dispatch(actionActiveMapCleared());

        // We want desktop to clear the marker with this action but not on mobile
        if (!isMobileVersion) {
            dispatch(actionUpdateActiveSearchPosition(undefined));
        }
    };
};

export const actionClearSelectedListing = (clearListing: boolean) => {
    return {
        type: at.SET_CLEAR_SELECTED_LISTING,
        payload: clearListing,
    };
};

export const actionSetSelectedIsSearchActive = (isActive: boolean) => {
    return {
        type: at.SET_SELECTED_IS_SEARCH_ACTIVE,
        payload: isActive,
    };
};
