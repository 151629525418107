import styled from 'styled-components';
import SoarModal, { StyledModalBody, StyledModalFooter } from '../../../../Shared/soar-modal';
import { StyledButton } from '../../../../Shared/styled-button';

interface StoryBuilderExportPagePopupProps {
    isOpen: boolean;
    onClose: () => void;
    onExport: () => void;
}

const StoryBuilderExportPagePopup = (props: StoryBuilderExportPagePopupProps) => {
    return (
        <SoarModal title="Export features to GeoJSON format" onClose={props.onClose} isOpen={props.isOpen}>
            <StyledModalBody>
                <p>This will export all features on the current page to GeoJSON format.</p>
                <p>Please note this is a basic geometry export and does not support:</p>
                <List>
                    <ListItem>Line styles including color and thickness</ListItem>
                    <ListItem>Feature measurements colors and labels</ListItem>
                    <ListItem>Additional symbols such as arrows, circles, markers, photos or text</ListItem>
                </List>
            </StyledModalBody>

            <StyledModalFooter>
                <StyledButton onClick={props.onExport}>Export</StyledButton>
            </StyledModalFooter>
        </SoarModal>
    );
};

export default StoryBuilderExportPagePopup;

const List = styled.ul`
    color: white;
`;

const ListItem = styled.li`
    color: white;
`;
