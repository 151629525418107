import { LatLngBounds } from "leaflet";
import GeoUtil from "./geo-util";
import { SentinelFeature } from "../store/Map/Sentinel/model";
import proj4 from 'proj4';

export default class SentinelUtil {
    /*
 *  Sentinel WFS only allows 2500x2500 pixel images.  We need to calculate what can be
 *  asked for based on the geometry
 */
    public static pixelResolutionForBounds(bounds: LatLngBounds): number {
        const MAX_SENTINEL_IMAGE_SIZE = 2500;

        const widthMeters = GeoUtil.widthKilometers(bounds) * 1000;
        const heightMeters = GeoUtil.heightKilometers(bounds) * 1000;

        const imageWidthFor10m = Math.round(widthMeters / 10);
        const imageHeightFor10m = Math.round(heightMeters / 10);

        if (imageWidthFor10m < MAX_SENTINEL_IMAGE_SIZE && imageHeightFor10m < MAX_SENTINEL_IMAGE_SIZE) {
            return 10;
        }

        const imageWidthFor20m = Math.round(widthMeters / 20);
        const imageHeightFor20m = Math.round(heightMeters / 20);
        if (imageWidthFor20m < MAX_SENTINEL_IMAGE_SIZE && imageHeightFor20m < MAX_SENTINEL_IMAGE_SIZE) {
            return 20;
        }

        const imageWidthFor50m = Math.round(widthMeters / 50);
        const imageHeightFor50m = Math.round(heightMeters / 50);
        if (imageWidthFor50m < MAX_SENTINEL_IMAGE_SIZE && imageHeightFor50m < MAX_SENTINEL_IMAGE_SIZE) {
            return 50;
        }

        return 100;
    }

    public static convertLatLngToBbox = (latLngBounds: LatLngBounds): number[] => {
        return [
            latLngBounds.getSouthWest().lng,
            latLngBounds.getSouthWest().lat,
            latLngBounds.getNorthEast().lng,
            latLngBounds.getNorthEast().lat,
        ];
    };

    public static convertLatLngToNumberArray = (latLngBounds: LatLngBounds): number[] => {
        const EPSG4326 = 'EPSG:4326'; // WGS84
        const EPSG3857 = 'EPSG:3857'; // Web Mercator

        // Get the southwest and northeast corners
        const southWest = latLngBounds.getSouthWest();
        const northEast = latLngBounds.getNorthEast();

        // Extract latitudes and longitudes
        const southWestLng = southWest.lng;
        const southWestLat = southWest.lat;
        const northEastLng = northEast.lng;
        const northEastLat = northEast.lat;

        // Ensure that the coordinates are valid numbers
        if (isNaN(southWestLng) || isNaN(southWestLat) || isNaN(northEastLng) || isNaN(northEastLat)) {
            throw new Error('Invalid coordinates detected.');
        }

        // Convert from EPSG:4326 to EPSG:3857
        const convertedSouthWest = proj4(EPSG4326, EPSG3857, [southWestLng, southWestLat]);
        const convertedNorthEast = proj4(EPSG4326, EPSG3857, [northEastLng, northEastLat]);

        return [convertedSouthWest[0], convertedSouthWest[1], convertedNorthEast[0], convertedNorthEast[1]];
    };

    public static formatConstellation = (constellation: string): string => {
        return constellation
            .replace('-', ' ')
            .split(' ')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };

    // TODO  UPDATE some formulae or way of handling this must exist as old one had it to allow 200 x 200 regardless of size
    public static getSmallestPreviewByPixelResolution(res: number): number {
        switch (res) {
            case 10:
                return 125;
            case 20:
                return 250;
            case 50:
                return 750;
            default:
                return 1400;
        }
    }

    /**
     * Calculates the average cloud cover for a group of catalog items.
     * TODO UPDATE - confirm Assumes each result for a dates cloud cover i is based on the same scale (area)
     * */
    public static calculateAverageCloudCover = (items: SentinelFeature[]): number => {
        if (items.length === 0) return 0;

        const totalCloudCover = items.reduce((sum, item) => sum + (item.cloudCover ?? 0), 0);
        const averageCloudCover = totalCloudCover / items.length;

        return parseFloat(averageCloudCover.toFixed(2));
    };

    public static isLandsatSatellite = (satellites: string[] | string): boolean => {
        const satelliteItems = Array.isArray(satellites) ? satellites : [satellites];
        return satelliteItems.some((satelliteItem) => satelliteItem.toLowerCase().includes('landsat'));
    };

}
