import { ReactNode } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import Masonry from 'react-masonry-css';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { selectSelectedSearchTerm } from '../../../../store/Map/MapSelection/selectors';
import { PulseLoader } from 'react-spinners';
import { ListingDTO } from '../../../../api/model';
import DiscoverCardGridView from './discover-card-grid-view';
import {
    SegmentContainer,
    SegmentTitle,
    SegmentTitleIcon,
} from '../../../MapView/Annotations/StoryMaps/StoryBuilder/SearchModal/search-container';
import { useWindowSize } from '@react-hook/window-size';

interface SearchResultsMasonaryProps {
    maybeLoadMore: () => void;
    items: ListingDTO[];
    isNoItems: boolean;
    children?: ReactNode;
    resultsContainer?: string;
    selectedOrder?: string;
}

const MAX_CARD_WIDTH = 250;
const SIDEBAR_WIDTH = 78;

const DiscoverResultsMasonary = (props: SearchResultsMasonaryProps) => {
    const selectedSearchTerm = useSelector(selectSelectedSearchTerm);
    const [windowWidth] = useWindowSize();

    const masonryBreakPoints = (windowWidth: number): number => {
        const availableWidth = windowWidth - SIDEBAR_WIDTH;
        const numCards = Math.floor(availableWidth / MAX_CARD_WIDTH);
        return numCards;
    };

    const selectableListings: ListingDTO[] = props.items.filter((value, index, array) => {
        return array.indexOf(value) === index;
    });

    // TODO this is temporary until we know what is going on with the featured, the selected as default is not the ideal solution
    const showSegmentTitle = !selectedSearchTerm?.length && !props.selectedOrder;

    return (
        <DiscoverInfiniteScroll
            dataLength={selectableListings.length}
            next={props.maybeLoadMore}
            height={
                !props.resultsContainer ? `calc(100dvh - ${selectedSearchTerm?.length ? '140px' : '85px'})` : undefined
            }
            hasMore={!props.isNoItems}
            loader={<PulseLoader />}
            endMessage={
                <AllLoadedNotification>{`We have found ${selectableListings.length} results`}</AllLoadedNotification>
            }
            scrollableTarget={props.resultsContainer}
        >
            {props.children}
            <SegmentContainer className={selectedSearchTerm ? 'unstyled' : ''}>
                {showSegmentTitle ? (
                    <SegmentTitle>
                        <SegmentTitleIcon src="/assets/search-content/emoji-explore.svg" />
                        Explore the <span>latest</span> maps
                    </SegmentTitle>
                ) : null}

                {selectableListings ? (
                    <DiscoverMasonry
                        breakpointCols={masonryBreakPoints(windowWidth)}
                        className="masonry-grid"
                        columnClassName="masonry-grid_column"
                    >
                        {selectableListings.map((listing, index) => (
                            <DiscoverCardGridView key={index} listing={listing} />
                        ))}
                    </DiscoverMasonry>
                ) : null}
            </SegmentContainer>
        </DiscoverInfiniteScroll>
    );
};

export default DiscoverResultsMasonary;

const AllLoadedNotification = styled.div`
    text-align: center;
    color: ${(props) => props.theme.color.yellow};
    margin: 10px 0px;
    font-size: 18px;
`;

const DiscoverInfiniteScroll = styled(InfiniteScroll)`
    &.infinite-scroll-component {
        scroll-behavior: smooth;
    }

    &::-webkit-scrollbar-track {
        border-left: 6px solid transparent;
        background-color: transparent;
    }
    &::-webkit-scrollbar {
        width: 8px;
        background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
        background-color: ${(props) => props.theme.color.yellow};
        border-radius: 4px;
    }
`;

const DiscoverMasonry = styled(Masonry)`
    &.masonry-grid {
        display: -webkit-box; /* Not needed if autoprefixing */
        display: -ms-flexbox; /* Not needed if autoprefixing */
        display: flex;
        width: auto;
    }

    &.masonry-grid_column {
        background-clip: padding-box;
        will-change: transform; /* Enable hardware acceleration for smoother animations */
    }
`;
