import { LatLngBounds } from 'leaflet';

import { DeprecatedSentinelRequestParams, SentinelFeature } from '../../../../store/Map/Sentinel/model';
import {
    legacyDefaultFalseColorEvalScript,
    legacyDefaultGeologyEvalScript,
    legacyDefaultNDVIEvalScript,
    legacyDefaultRadarEvalScript,
    legacyDefaultTrueColorEvalScript,
} from './sentinel-default-evalscripts';
import GeoUtil from '../../../../lib/geo-util';
import { legacyDefaultLandsatTrueColorEvalScript } from './landsat-default-evalscripts';
import SentinelUtil from '../../../../lib/sentinel-util';
import { EvalScript } from '../../../../api/api-cf-sentinel';

export const getSatelliteFeatureEvalScriptFromURLString = (urlParams: DeprecatedSentinelRequestParams): EvalScript => {
    return handleDeprecatedSentinelEval(urlParams.layers, urlParams.title);
};

// Takes the url paramaters and returns a satelliteFeature
export const handleDeprecatedSentinelStringUrl = async (
    urlParams: DeprecatedSentinelRequestParams
): Promise<SentinelFeature> => {
    const createLatLngBounds = (bbox: string): LatLngBounds => {
        const [west, south, east, north] = bbox.split(',').map(Number);
        let latLngBounds = new LatLngBounds(
            GeoUtil.EPSG3857ToLatLng(west, south),
            GeoUtil.EPSG3857ToLatLng(east, north)
        );
        if (Math.abs(latLngBounds.getSouthWest().lat) < 0.01 && Math.abs(latLngBounds.getSouthWest().lng) < 0.01) {
            latLngBounds = new LatLngBounds([south, west], [north, east]);
        }
        return latLngBounds;
    };

    const bbox = createLatLngBounds(urlParams.bbox);
    const evalScript = getSatelliteFeatureEvalScriptFromURLString(urlParams);
    const resolution = SentinelUtil.pixelResolutionForBounds(bbox);
    return {
        id: urlParams.title,
        date: urlParams.time,
        previewUrl: '',
        highResolutionPreviewUrl: undefined,
        resolution: resolution,
        satellite: urlParams.title,
        collection: evalScript.sentinelSatelliteType,
        evalScript: evalScript,
        cloudCover: 0,
        constellation: evalScript.sentinelSatelliteType,
        bbox: bbox,
    };
};

export const handleDeprecatedSentinelEval = (layerName: string, satelliteName: string): EvalScript => {
    const lowerCaseSatelliteName = satelliteName.toLowerCase();
    const lowerCaseLayerName = layerName.toLowerCase();

    if (lowerCaseSatelliteName.includes('sentinel')) {
        switch (lowerCaseLayerName) {
            case 'true_color':
                return legacyDefaultTrueColorEvalScript;
            case 'false_color':
                return legacyDefaultFalseColorEvalScript;
            case 'geology':
                return legacyDefaultGeologyEvalScript;
            case 'ndvi':
                return legacyDefaultNDVIEvalScript;
            case 'sentinel_1_grd':
            case 'sentinel_1_radar':
                return legacyDefaultRadarEvalScript;
            default:
                return legacyDefaultTrueColorEvalScript;
        }
    } else if (lowerCaseLayerName.includes('landsat')) {
        return legacyDefaultLandsatTrueColorEvalScript;
    }

    return legacyDefaultTrueColorEvalScript;
};
