import styled from 'styled-components';
import { AnalyticsAction, AnalyticsNote, ListingDTO } from '../../../../api/model';
import UriHelper from '../../../../lib/uri-helper';
import Analytics from '../../../../lib/user-analytics';
import { MarkdownViewer } from '../../../Shared/markdown-viewer';
import { StyledButton } from '../../../Shared/styled-button';
import ActiveMapExternalSourceNotification from '../../Maps/ActiveMap/active-map-external-source-notification';
import TemporalCategoryControl from '../../Shared/TemporalCategory/temporal-category-control';
import SideDrawerLocationMap from '../Shared/side-drawer-location-map';
import ApiAnalytics from '../../../../api/api-analytics';

interface MapSearchResultPreviewProps {
    listing: ListingDTO;
    analyticNote: AnalyticsNote;
    isUserNameDisabled?: boolean;
}

const MapSearchDrawPreviewDetails = ({ listing, analyticNote, isUserNameDisabled }: MapSearchResultPreviewProps) => {
    return (
        <MapSearchResultPreviewContainer>
            <MapSearchResultsTitle title={listing.title}>{listing.title}</MapSearchResultsTitle>
            {isUserNameDisabled ? (
                <UserNameDisabledPadding />
            ) : (
                <MapSearchResultsAuthor href={`/profile/${listing.userId}`} title={listing.userName}>
                    by {listing.userName || 'Soar User'}
                </MapSearchResultsAuthor>
            )}
            {listing.bboxWKT ? <SideDrawerLocationMap position={listing.bboxWKT} /> : null}
            <TemporalCategoryControl listing={listing} margin="5px 3px" showDefaultCategories showTemporalCategories />
            <MapSearchResultsButton
                onClick={() => {
                    UriHelper.navigateToPath(`/maps/${listing.id}`);
                    ApiAnalytics.postAnalyticsListing(AnalyticsAction.VIEW, analyticNote, listing.id);
                    Analytics.Event('Side Drawer', 'Clicked View Interactive Map from nested draw');
                }}
            >
                Open Map
            </MapSearchResultsButton>
            <ActiveMapExternalSourceNotification listing={listing} />
            <MapDescriptionContainer>
                <MarkdownViewer text={listing.description} />
            </MapDescriptionContainer>
            {listing?.tags && (
                <TagContainer>
                    {listing.tags.map((tag, index) => {
                        return <Tag key={`${tag}-${index}`}>{tag}</Tag>;
                    })}
                </TagContainer>
            )}
        </MapSearchResultPreviewContainer>
    );
};

export default MapSearchDrawPreviewDetails;

const MapSearchResultPreviewContainer = styled.div`
    height: calc(100vh - 110px);
    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #343a40;
    }
    &::-webkit-scrollbar {
        width: 6px;
        background-color: #343a40;
    }
    &::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #eed926;
        border-radius: 4px;
    }
`;

const MapSearchResultsTitle = styled.h2`
    margin: 10px 20px 10px 0px;
    font-size: 1.5rem;
    font-weight: 700;
    padding: 0;
    color: #fff;
    overflow-wrap: break-word;
`;

const MapSearchResultsAuthor = styled.a`
    font-size: 1rem;
    color: #fff;
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
    display: block;
    text-align: center;

    &:hover {
        color: #eed926;
    }
`;

const UserNameDisabledPadding = styled.div`
    height: 20px;
`;

const MapSearchResultsButton = styled(StyledButton)`
    margin: 10px auto 0px auto;
`;

const MapDescriptionContainer = styled.div`
    margin: 10px 5px;
`;

const TagContainer = styled.div`
    flex: 0 1 auto;
    text-align: left;
    display: flex;
    flex-wrap: wrap;
    max-height: auto;
    overflow: visible;
`;

const Tag = styled.div`
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 6px;
    padding: 3px;
    line-height: 14px;
    margin: 0px 2px 2px 0px;
    color: white;
    font-size: 12px;
    word-break: break-all;
`;
