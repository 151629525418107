import { LatLngBounds } from 'leaflet';
import debounce from 'lodash/debounce';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import ApiListings, { GetListingsParams } from '../../../../api/api-listings';
import GeoUtil from '../../../../lib/geo-util';
import SoarHelper from '../../../../lib/soar-helper';
import {
    selectGlobalListings,
    selectMapBounds,
    selectMapMoveEnd,
    selectMapZoom,
} from '../../../../store/App/selectors';
import { LISTING_COUNT } from '../../../LandingPage/map-count-ticker';
import { attachChipToListingDTO, ListingDTOWithChip } from './listing-card-chip';
import MapSearchDrawAOIResults from './map-search-draw-aoi-results';
import MapSearchDrawListingCount from './map-search-draw-listing-count';
import MapSearchFilter from './map-search-filter';

const RESULT_LIMIT = 25; // Api default
const LOAD_MORE_LIMIT = 20;
const MAP_MOVE_DEBOUNCE = 250;
const WORLD_BOUNDS_ZOOM_CUTOFF = 3;

const MapSearchDraw = () => {
    const [listings, setListings] = useState<ListingDTOWithChip[] | undefined>();
    const [listingCount, setListingCount] = useState<number | undefined>();
    const [showFilter, setShowFilter] = useState<boolean>(false);
    const [searchFilter, setSearchFilter] = useState<string | undefined>(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const mapZoom = useSelector(selectMapZoom);
    const mapBounds = useSelector(selectMapBounds);
    const mapMoveEnd = useSelector(selectMapMoveEnd);

    const globalListings = useSelector(selectGlobalListings);

    useEffect(() => {
        fetchListings(0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchFilter, globalListings]);

    const cancelFetchListings = () => ApiListings.cancelGetListings('AOI Changed');

    useEffect(() => {
        if (!mapZoom) return;

        if (mapZoom < WORLD_BOUNDS_ZOOM_CUTOFF && !searchFilter && SoarHelper.isSoar()) {
            setListings(globalListings);
            setListingCount(LISTING_COUNT);
            return;
        }
    }, [mapZoom, searchFilter, globalListings]);

    const fetchListings = useCallback(
        async (offset: number, loadMoreLimit?: number) => {
            if (!mapZoom || !mapBounds) return;

            if (mapZoom < WORLD_BOUNDS_ZOOM_CUTOFF && offset === 0 && !searchFilter && SoarHelper.isSoar()) {
                return;
            }

            if (offset === 0) {
                setListings(undefined);
                setListingCount(undefined);
            }

            try {
                if (!process.env.STORYBOOK) {
                    cancelFetchListings();
                }

                setIsLoading(true);

                const aoiWKT = GeoUtil.latLngBoundsToWKT(mapBounds as LatLngBounds);
                const params: GetListingsParams = {
                    aoi: aoiWKT,
                    limit: loadMoreLimit ? loadMoreLimit : RESULT_LIMIT,
                    offset: offset,
                    category: searchFilter,
                };
                const data = await ApiListings.getListings(params);
                setIsLoading(false);

                if (data?.listings) {
                    if (!loadMoreLimit) {
                        const listings: ListingDTOWithChip[] = data.listings.map((r) => {
                            return attachChipToListingDTO(r);
                        });
                        if (listings.length > 0) {
                            setListings(listings);
                        }
                    } else {
                        const listings: ListingDTOWithChip[] = data.listings.map((r) => {
                            return attachChipToListingDTO(r);
                        });
                        if (listings.length > 0) {
                            setListings((prevListings) => [...(prevListings ?? []), ...listings]);
                        }
                    }

                    if (data?.listings.length > 0) {
                        if (mapZoom < WORLD_BOUNDS_ZOOM_CUTOFF && !searchFilter && SoarHelper.isSoar()) {
                            setListingCount(LISTING_COUNT);
                        } else {
                            setListingCount(data.total);
                        }
                    }
                }
            } catch (error) {
                if (!error.toString().includes('Cancel')) {
                    console.log('Error fetching listings:', error);
                }
            } finally {
                setTimeout(() => setIsLoading(false), 300);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [mapZoom, mapBounds, searchFilter]
    );

    useEffect(() => {
        fetchListings(0);
        // Adding the fetchListings dependency causes a nasty bug
        // Since fetchListings is a callback with the mapBounds and mapZoom dependencies,
        // it will cause this useEffect to trigger again, then get cancelled (or will override) the
        // debounced mapMove
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchFilter]);

    const debouncedHandleMapMove = useMemo(() => debounce(() => fetchListings(0), MAP_MOVE_DEBOUNCE), [fetchListings]);

    useEffect(() => {
        if (mapMoveEnd) {
            debouncedHandleMapMove();
        }

        return () => {
            debouncedHandleMapMove.cancel();
        };
    }, [mapMoveEnd, debouncedHandleMapMove]);

    const handleLoadMore = debounce(() => {
        fetchListings(listings?.length ?? 0, LOAD_MORE_LIMIT);
    }, MAP_MOVE_DEBOUNCE);

    return (
        <MapSearchDrawContainer>
            <MapSearchDrawListingCount
                listingCount={listingCount}
                filterOpen={showFilter}
                onClickFilter={() => {
                    setShowFilter(!showFilter);
                }}
                filter={searchFilter}
            />
            <MapSearchFilter isOpen={showFilter} onSelectFilter={(filter) => setSearchFilter(filter)} />
            <MapSearchResultsDivider />
            <MapSearchDrawAOIResults
                listings={listings}
                listingsCount={listingCount || 0}
                isLoading={isLoading}
                onLoadMore={handleLoadMore}
                isFilterOpen={showFilter}
            />
        </MapSearchDrawContainer>
    );
};

export default MapSearchDraw;

const MapSearchDrawContainer = styled.div`
    margin: 0px 0px 0px 7px;
`;

const MapSearchResultsDivider = styled.div`
    height: 1px;
    background-color: transparent;
    margin-top: 10px;
`;
