import L from 'leaflet';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { selectMapBounds } from '../../../../../../store/App/selectors';
import SearchContentCarousel, { CarouselItem } from './search-content-carousel';
import {
    SegmentContainer,
    SegmentTitle,
    SegmentTitleIcon,
    SegmentHeader,
    SegmentFilters,
    SegmentContent,
    Select,
    SelectOption,
    LayerSelect,
    DateSelect,
    SatelliteSelect,
} from './search-modal';

import { SentinelFeature } from '../../../../../../store/Map/Sentinel/model';
import { PulseLoader } from '../../../../../Shared/pulse-loader';
import SatelliteUtil from '../../../../../../lib/satellite-util';
import Analytics from '../../../../../../lib/user-analytics';
import {
    sentinelDefaultEvalScripts,
    trueColor,
} from '../../../../../Drawer/Satellites/Sentinel/sentinel-default-evalscripts';
import ApiCfSentinel, { EvalScript } from '../../../../../../api/api-cf-sentinel';
import {
    landsatDefaultEvalScripts,
    landsatTrueColor,
} from '../../../../../Drawer/Satellites/Sentinel/landsat-default-evalscripts';
import { DateRange, last30Days } from '../../../../../Drawer/Satellites/Sentinel/Shared/date-filter-util';

interface SearchContentSentinelInViewportProps {
    onSelectItem: (mapLayer: CarouselItem) => void;
}

type ProviderFilter = 'SENTINEL' | 'LANDSAT';

const SearchContentSentinelInViewport = (props: SearchContentSentinelInViewportProps) => {
    const aoi = useSelector(selectMapBounds);
    const [fetchingSentinel, setFetchingSentinel] = useState(false);
    const [sentinelFeatures, setSentinelFeatures] = useState<CarouselItem[]>([]);
    const [sentinelError, setSentinelError] = useState<string | undefined>();

    const [selectedEvalScript, setSelectedEvalScript] = useState<EvalScript>(trueColor);
    const [filterProvider, setFilterProvider] = useState<ProviderFilter>('SENTINEL');
    const [filterDate, setFilterDate] = useState<DateRange>(last30Days);
    const [defaultSentinelEvalScripts, setDefaultSentinelEvalScripts] =
        useState<EvalScript[]>(sentinelDefaultEvalScripts);

    const currentDate = moment();
    const dateFilterOptions: DateRange[] = [
        {
            startDate: currentDate.clone().subtract(1, 'months').toDate(),
            endDate: currentDate.toDate(),
            displayText: 'Last 30 days',
        },
    ];

    const optionDate = currentDate.clone();
    while (optionDate.year() >= 2016 && optionDate.month() >= 0) {
        optionDate.subtract(1, 'months');

        dateFilterOptions.push({
            startDate: optionDate.startOf('month').toDate(),
            endDate: optionDate.endOf('month').toDate(),
            displayText: optionDate.format('MMMM YYYY'),
        });
    }

    const handleScriptName = (scriptName: string) => {
        if (scriptName.includes('NDWI')) {
            return 'NDWI';
        } else if (scriptName.includes('NDMI')) {
            return 'NDMI';
        }
        return scriptName;
    };

    const handleSelectLayer = (scriptName: string) => {
        Analytics.Event('Satellite - Sentinel', 'Changed EvalScript', scriptName);
        const selectedScript = defaultSentinelEvalScripts.find((script) => script.name === scriptName);
        if (!selectedScript) return;
        setSelectedEvalScript(selectedScript);
    };

    useEffect(() => {
        if (filterProvider === 'LANDSAT') {
            setDefaultSentinelEvalScripts(landsatDefaultEvalScripts);
            setSelectedEvalScript(landsatTrueColor);
        } else {
            setDefaultSentinelEvalScripts(sentinelDefaultEvalScripts);
            setSelectedEvalScript(trueColor);
        }
    }, [filterProvider]);

    useEffect(() => {
        const handleFetchingSentinelResults = async () => {
            setFetchingSentinel(true);
            const features = await ApiCfSentinel.searchSentinelFeatures({
                bounds: aoi as L.LatLngBounds,
                dateFrom: filterDate.startDate,
                dateTo: filterDate.endDate,
                satellites: [selectedEvalScript.sentinelSatelliteType],
                limit: 20,
                evalScript: selectedEvalScript,
            });

            if (!features || features.length === 0) {
                setSentinelError('No data available. Please select a different area.');
                setFetchingSentinel(false);
                return;
            }

            // To make it compatible with the carousel we need ot populate the preview image first
            const items: CarouselItem[] = await Promise.all(
                features.map(async (feature: SentinelFeature) => {
                    const previewUrl = await ApiCfSentinel.generateImageThumbnail(feature, selectedEvalScript);

                    return {
                        previewUrl,
                        title: `${handleScriptName(selectedEvalScript.name)} ${new Date(
                            feature.date
                        ).toLocaleDateString()}`,
                        author: feature.satellite,
                        satelliteFeature: {
                            ...feature,
                            previewUrl: previewUrl,
                        },
                        opacity: 1,
                    };
                })
            );

            setFetchingSentinel(false);
            setSentinelFeatures(items);
        };

        if (aoi && aoi instanceof L.LatLngBounds) {
            setSentinelFeatures([]);
            setSentinelError(undefined);
            setFetchingSentinel(false);
            if (
                filterProvider === 'SENTINEL' &&
                !SatelliteUtil.sentinelQualityEvaluation(aoi).find((sentinelDimension) => sentinelDimension.isAvailable)
            ) {
                setSentinelError(
                    'No available Sentinel Feed on current zoom level. Please zoom in to see satellite feeds.'
                );
            } else if (filterProvider === 'LANDSAT' && !SatelliteUtil.landsatQualityEvaluation(aoi)[0].isAvailable) {
                setSentinelError(
                    'No available Landsat Feed on current zoom level. Please zoom in to see satellite feeds.'
                );
            } else {
                handleFetchingSentinelResults();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [aoi, filterDate.startDate, filterDate.endDate, selectedEvalScript, defaultSentinelEvalScripts]);

    return (
        <SegmentContainer>
            <SegmentHeader>
                <SegmentTitle>
                    <SegmentTitleIcon src="/assets/dashboard/satellite_yellow.svg" />
                    Latest free satellite imagery in <span>this area</span>
                </SegmentTitle>
                <SegmentFilters>
                    <SatelliteSelect>
                        <Select
                            title="Filter by Provider"
                            value={filterProvider}
                            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                setFilterProvider(e.target.value as ProviderFilter);
                                Analytics.Event('Draw Tools - Project', `Filter By Provider ${e.target.value}`);
                            }}
                            disabled={fetchingSentinel}
                        >
                            <SelectOption value="SENTINEL">Sentinel</SelectOption>
                            <SelectOption value="LANDSAT">Landsat</SelectOption>
                        </Select>
                    </SatelliteSelect>

                    <LayerSelect>
                        <Select
                            title={selectedEvalScript.name}
                            value={selectedEvalScript.name}
                            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                Analytics.Event('Draw Tools - Project', `Filter By Layer ${e.target.value}`);
                                handleSelectLayer(e.target.value);
                            }}
                            disabled={fetchingSentinel}
                            style={{ width: '150px' }}
                        >
                            {defaultSentinelEvalScripts.map((evalOption, index) => (
                                <SelectOption key={index} value={evalOption.name}>
                                    {evalOption.name}
                                </SelectOption>
                            ))}
                        </Select>
                    </LayerSelect>
                    <DateSelect>
                        <Select
                            title="Filter by Date"
                            value={filterDate.displayText}
                            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                setFilterDate(
                                    dateFilterOptions.find((dateFilter) => dateFilter.displayText === e.target.value) ??
                                        last30Days
                                );
                                Analytics.Event('Draw Tools - Project', `Filter By Date ${e.target.value}`);
                            }}
                            disabled={fetchingSentinel}
                        >
                            {dateFilterOptions.map((option) => (
                                <SelectOption value={option.displayText} key={option.displayText}>
                                    {option.displayText}
                                </SelectOption>
                            ))}
                        </Select>
                    </DateSelect>
                </SegmentFilters>
            </SegmentHeader>

            <SegmentContent>
                {fetchingSentinel ? <PulseLoader /> : null}
                {sentinelError && !fetchingSentinel && !sentinelFeatures.length ? <Error>{sentinelError}</Error> : null}
                {!fetchingSentinel && sentinelFeatures && sentinelFeatures.length > 0 ? (
                    <CarouselContainer>
                        <SearchContentCarousel
                            items={sentinelFeatures}
                            height="180px"
                            handleSelectItem={(item: CarouselItem) => props.onSelectItem(item)}
                        />
                    </CarouselContainer>
                ) : null}
            </SegmentContent>
        </SegmentContainer>
    );
};

export default SearchContentSentinelInViewport;

const CarouselContainer = styled.div``;

const Error = styled.p`
    color: ${(props) => props.theme.color.opaqueWhite};
    font-style: italic;
    text-align: center;
    height: 100%;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
`;
