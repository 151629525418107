import L from 'leaflet';
import { defaultZIndex } from '../layers-util';
import { MeasurementLabelColor } from '../Measurement/measurement-toolbar-label-color';
import { MeasurementUnits } from '../Measurement/measurement-toolbar-units';
import { MeasurementBackgroundColor } from '../Measurement/measurement-toolbar-background-color';
import { MeasurementLengthType } from '../Measurement/measurement-toolbar-polygon-length';

export default class Polyline {
    annotationType: string;
    zIndex: number;
    id: string;
    positions: L.LatLng[];
    options: L.PolylineOptions;
    showLength: MeasurementLengthType;
    labelColor: MeasurementLabelColor;
    labelBgColor: MeasurementBackgroundColor;
    units: MeasurementUnits;

    constructor(
        id: string,
        positions: L.LatLng[],
        options: L.PolylineOptions,
        showLength: MeasurementLengthType,
        labelColor: MeasurementLabelColor,
        labelBgColor: MeasurementBackgroundColor,
        units: MeasurementUnits
    ) {
        this.annotationType = 'Polyline';
        this.id = id;
        this.positions = positions;
        this.options = options;
        this.showLength = showLength;
        this.labelColor = labelColor;
        this.labelBgColor = labelBgColor;
        this.units = units;
    }

    static serialize(polyline: Polyline): GeoJSON.Feature {
        return {
            type: 'Feature',
            geometry: {
                type: 'LineString',
                coordinates: polyline.positions.map((position) => [position.lng, position.lat]),
            },
            properties: {
                annotationType: 'Polyline',
                id: polyline.id,
                showLength: polyline.showLength,
                labelColor: polyline.labelColor,
                labelBgColor: polyline.labelBgColor,
                units: polyline.units,

                zIndex: polyline.zIndex || defaultZIndex,
                ...polyline.options,
            },
        };
    }

    static partialSerialize(polyline: Polyline): GeoJSON.Feature {
        return {
            type: 'Feature',
            geometry: {
                type: 'LineString',
                coordinates: polyline.positions.map((position) => [position.lng, position.lat]),
            },
            properties: {},
        };
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    static deserialize(json: any): Polyline {
        const positions = json.geometry.coordinates.map((coordinate: number[]) => {
            return new L.LatLng(coordinate[1], coordinate[0]);
        });

        const { id, zIndex, showLength, labelColor, labelBgColor, units, ...polylinePathOptions } = json.properties;

        return {
            annotationType: 'Polyline',
            positions: positions,
            id: id,
            zIndex: zIndex || defaultZIndex,

            options: {
                ...defaultPolylineOptions,
                ...polylinePathOptions,
            },
            showLength: showLength,
            labelColor: labelColor,
            labelBgColor: labelBgColor,
            units: units,
        };
    }
}

export const defaultPolylineOptions: L.PathOptions = {
    stroke: true,
    color: '#eed926',
    weight: 3,
    opacity: 1,
    lineCap: 'round',
    lineJoin: 'round',
    dashArray: undefined,
    dashOffset: undefined,
    fill: false,
    fillColor: 'transparent',
    fillOpacity: 0,
    fillRule: 'evenodd',
    interactive: true,
    bubblingMouseEvents: false,
};

export const ghostPathOptions: L.PathOptions = {
    ...defaultPolylineOptions,
    interactive: true,
    color: 'transparent',
    weight: 30,
    opacity: 1.0,
    fill: false,
    fillColor: 'transparent',
};
