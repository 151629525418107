import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import UriHelper from '../../../../lib/uri-helper';
import Analytics from '../../../../lib/user-analytics';
import {
    actionSentinelBeginBoxSelect,
    actionSentinelChangeOpacity,
    actionSentinelClearFeatures,
    actionSentinelEndBoxSelect,
    actionSentinelFetchFeatures,
    actionSentinelFetchFeaturesError,
    actionSentinelFetchFeaturesSuccess,
    actionSentinelResetSelectedAOI,
    actionSentinelResetSelectedFeature,
} from '../../../../store/Map/Sentinel/actions';
import {
    selectSentinelError,
    selectSentinelFeatures,
    selectSentinelFeaturesLoading,
    selectSentinelIsBoxSelectActive,
    selectSentinelSelectedAOI,
    selectSentinelSelectedFeature,
} from '../../../../store/Map/Sentinel/selectors';
import SatelliteLoading from '../satellite-loading';
import SentinelItem from '../Sentinel/sentinel-item';
import { SideDrawerMode } from '../../../../store/SideDrawer/model';
import DrawerHint from '../../drawer-hint';
import {
    Content,
    LogoContainer,
    Logo,
    ShowMoreButton,
    ShowMoreContainer,
    TeaserIcon,
    TeaserText,
    EmptyTeaserTitle,
    SatelliteSubmitButton,
} from '../satellite-drawer-styles';
import { PulseLoader } from '../../../Shared/pulse-loader';

import { SideDrawerBackButton } from '../../SideDrawer/Shared/side-drawer-buttons';
import { selectLoggedIn } from '../../../../store/Account/selectors';
import DrawerLogin from '../../Shared/drawer-login';
import { landsatDefaultEvalScripts } from '../Sentinel/landsat-default-evalscripts';
import SentinelOpacitySlider from '../Sentinel/sentinel-opacity-slider';
import SentinelDrawerFilter from '../Sentinel/sentinel-drawer-filter';
import ApiCfSentinel, { EvalScript } from '../../../../api/api-cf-sentinel';
import { DateRange, last30Days } from '../Sentinel/Shared/date-filter-util';

const moment = require('moment');

// TODO UPDATE this is now the same as the sentinel drawer possibly cna be refactored into one?
const LandsatDrawer = () => {
    const bbox = useSelector(selectSentinelSelectedAOI);
    const sentinelFeatures = useSelector(selectSentinelFeatures);
    const selectedSentinelFeature = useSelector(selectSentinelSelectedFeature);
    const sentinelFeaturesLoading = useSelector(selectSentinelFeaturesLoading);
    const sentinelError = useSelector(selectSentinelError);
    const isBoxSelectActive = useSelector(selectSentinelIsBoxSelectActive);

    const dispatch = useDispatch();
    const beginBoxSelect = () => dispatch(actionSentinelBeginBoxSelect());
    const endBoxSelect = () => dispatch(actionSentinelEndBoxSelect());
    const resetSelectedAOI = () => dispatch(actionSentinelResetSelectedAOI());
    const resetSelectedSentinelFeature = () => dispatch(actionSentinelResetSelectedFeature());
    const clearSentinelFeatures = () => dispatch(actionSentinelClearFeatures());

    const isLoggedIn = useSelector(selectLoggedIn);

    const [dateRange, setDateRange] = useState<DateRange>(last30Days);
    const [evalScript, setEvalScript] = useState<EvalScript>(landsatDefaultEvalScripts[0]);
    const [dropdownEvalScript, setDropdownEvalScript] = useState<EvalScript>(landsatDefaultEvalScripts[0]);
    const [showMoreLoading, setShowMoreLoading] = useState(false);

    const invalidateSentinelResults = () => {
        clearSentinelFeatures();
        resetSelectedAOI();
        resetSelectedSentinelFeature();
        endBoxSelect();
        setDateRange(last30Days);
    };

    useEffect(() => {
        if (selectedSentinelFeature && !sentinelFeaturesLoading && !sentinelFeatures?.length) {
            const startDate = moment(selectedSentinelFeature.date).subtract(1, 'months');
            const endDate = moment(selectedSentinelFeature.date).add(1, 'days');
            setDateRange({
                endDate: endDate.toDate(),
                startDate: startDate.toDate(),
                displayText: startDate.format('MMMM YYYY'),
            });
        } else {
            setDateRange(last30Days);
        }

        return () => {
            invalidateSentinelResults();
            dispatch(actionSentinelFetchFeaturesSuccess(undefined));
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        //reset opacity
        dispatch(actionSentinelChangeOpacity(undefined));
        if (bbox && !sentinelFeaturesLoading && !showMoreLoading) {
            requestAnimationFrame(() => {
                setDropdownEvalScript(evalScript);
            });
            dispatch(
                actionSentinelFetchFeatures(
                    bbox,
                    dateRange.startDate,
                    dateRange.endDate,
                    [selectedSentinelFeature?.evalScript.sentinelSatelliteType ?? evalScript.sentinelSatelliteType],
                    sentinelFeatures?.length,
                    selectedSentinelFeature?.evalScript || evalScript,
                    undefined,
                    !isBoxSelectActive
                )
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bbox, evalScript, dateRange]);

    useEffect(() => {
        if (selectedSentinelFeature?.evalScript) {
            setDropdownEvalScript(selectedSentinelFeature.evalScript);
        }
    }, [selectedSentinelFeature?.evalScript]);

    useEffect(() => {
        if (sentinelFeatures) {
            setShowMoreLoading(false);
        }
    }, [sentinelFeatures]);

    const handleShowMore = async () => {
        Analytics.Event('Satellite - Sentinel', 'Clicked show more', evalScript.name);

        if (showMoreLoading) {
            return;
        }

        if (!dateRange) {
            return;
        }

        const startDate = moment(dateRange.startDate).subtract(1, 'day').toDate();
        const newStartDate = moment(startDate).subtract(1, 'months').toDate();
        const newDateRange = { ...dateRange, startDate: newStartDate };
        setDateRange(newDateRange);

        setEvalScript(dropdownEvalScript);
        setShowMoreLoading(true);

        if (bbox) {
            try {
                const results = await ApiCfSentinel.searchSentinelFeatures({
                    bounds: bbox,
                    dateFrom: newStartDate,
                    dateTo: dateRange.startDate,
                    satellites: [evalScript.sentinelSatelliteType],
                    limit: 30,
                    evalScript: selectedSentinelFeature?.evalScript,
                });

                if (!results?.length) {
                    throw new Error('No data available. Please select a different area.');
                }

                const features = sentinelFeatures ? [...sentinelFeatures, ...results] : results;

                dispatch(actionSentinelFetchFeaturesSuccess(features));
            } catch (error) {
                dispatch(actionSentinelFetchFeaturesError(error));
            }
        }
    };

    const handleSelectDateRange = (dateRange: DateRange) => {
        setDateRange(dateRange);
        setShowMoreLoading(true);
        if (bbox) {
            dispatch(
                actionSentinelFetchFeatures(
                    bbox,
                    dateRange.startDate,
                    dateRange.endDate,
                    [selectedSentinelFeature?.evalScript.sentinelSatelliteType ?? evalScript.sentinelSatelliteType],
                    sentinelFeatures?.length,
                    selectedSentinelFeature?.evalScript ?? evalScript,
                    undefined,
                    !isBoxSelectActive
                )
            );
        }
    };

    const reset = () => {
        UriHelper.removeAllSentinelParametersFromURI();
        UriHelper.removeParameterFromUri('shareId');
        ApiCfSentinel.clearAllCancelTokens('user backed out');
        if (sentinelFeatures) {
            invalidateSentinelResults();
        } else {
            clearSentinelFeatures();
            resetSelectedAOI();
            UriHelper.navigateToDrawer(SideDrawerMode.SATELLITE);
            endBoxSelect();
            Analytics.Event('Satellite - Landsat', 'Clicked back arrow');
        }
    };

    if (!isLoggedIn) {
        return (
            <DrawerLogin
                logo="/assets/floating-drawer-satellite-icons/satellite-nasa-logo.png"
                onLogin={(isUserLoggedIn) => {
                    if (!isUserLoggedIn) {
                        UriHelper.navigateToDrawer(SideDrawerMode.SATELLITE);
                    }
                }}
                onClickBackButton={reset}
            />
        );
    }

    return (
        <React.Fragment>
            <SideDrawerBackButton onClick={reset} />

            <LogoContainer>
                <Logo src="/assets/floating-drawer-satellite-icons/satellite-nasa-logo.png" />
            </LogoContainer>

            {!sentinelFeatures && !sentinelFeaturesLoading && sentinelError ? (
                <Content>
                    <EmptyTeaserTitle />
                    <TeaserIcon src="/assets/floating-drawer-icons/map-teaser-icon.svg" />
                    <SatelliteSubmitButton
                        id="draw-aoi-button"
                        onClick={() => {
                            invalidateSentinelResults();
                            Analytics.Event('Satellite - Landsat', 'Clicked redraw area of interest');
                        }}
                    >
                        RESET AREA OF INTEREST
                    </SatelliteSubmitButton>
                </Content>
            ) : null}

            {!sentinelFeatures?.length && !sentinelFeaturesLoading && !sentinelError ? (
                <Content>
                    <EmptyTeaserTitle />
                    <TeaserIcon src="/assets/floating-drawer-icons/map-teaser-icon.svg" />
                    <TeaserText>Click the button and drag an Area of Interest (AOI).</TeaserText>
                    <SatelliteSubmitButton
                        disabled={isBoxSelectActive}
                        id="draw-aoi-button"
                        onClick={() => {
                            ApiCfSentinel.clearAllCancelTokens('started new AOI');
                            clearSentinelFeatures();
                            resetSelectedSentinelFeature();

                            beginBoxSelect();
                            Analytics.Event('Satellite - Landsat', 'Clicked draw area of interest');
                        }}
                    >
                        DRAW AOI
                    </SatelliteSubmitButton>
                    {!isBoxSelectActive && <DrawerHint isActive={true}>Now includes Landsat 9!</DrawerHint>}
                </Content>
            ) : null}

            {sentinelFeaturesLoading && !showMoreLoading && !sentinelError ? (
                <Content>
                    <SatelliteLoading />
                </Content>
            ) : null}

            {sentinelFeatures?.length && !sentinelError && (!sentinelFeaturesLoading || showMoreLoading) ? (
                <React.Fragment>
                    <SentinelDrawerFilter
                        onSelectEvalScript={setEvalScript}
                        onSelectDateRange={(dateRange) => handleSelectDateRange(dateRange)}
                        evalScript={dropdownEvalScript}
                        dateRange={dateRange}
                        defaultEvalScriptOptions={landsatDefaultEvalScripts}
                    />
                    {selectedSentinelFeature ? (
                        <SentinelOpacitySliderContainer>
                            <SentinelOpacitySlider />
                        </SentinelOpacitySliderContainer>
                    ) : null}
                    <LandsatContent>
                        <LandsatItems featureActive={!!selectedSentinelFeature}>
                            {sentinelFeatures.map((t, index) => {
                                return (
                                    <SentinelItem
                                        key={`landsat-feature-${index}`}
                                        feature={t}
                                        evalScript={selectedSentinelFeature?.evalScript ?? evalScript}
                                    />
                                );
                            })}
                            <ShowMoreContainer>
                                {showMoreLoading ? (
                                    <ShowMoreButton>
                                        <PulseLoader />
                                    </ShowMoreButton>
                                ) : (
                                    <ShowMoreButton
                                        onClick={() => {
                                            handleShowMore();
                                            Analytics.Event(
                                                'Satellite - Landsat',
                                                'Clicked show more',
                                                evalScript.name
                                            );
                                        }}
                                    >
                                        SHOW MORE
                                    </ShowMoreButton>
                                )}
                            </ShowMoreContainer>
                        </LandsatItems>
                    </LandsatContent>
                </React.Fragment>
            ) : null}
            {sentinelError ? (
                <DrawerHint error>{sentinelError instanceof Error ? sentinelError.message : sentinelError}</DrawerHint>
            ) : null}
        </React.Fragment>
    );
};

export default LandsatDrawer;

const LandsatContent = styled(Content)`
    display: flex;
    flex-direction: column;
`;

const calculateFlexBasis = (featureActive: boolean) => {
    if (featureActive) return 'calc(100vh - 305px)';
    return 'calc(100vh - 260px)';
};

const LandsatItems = styled.div<{ featureActive: boolean }>`
    overflow-y: auto;
    flex-basis: ${(props) => calculateFlexBasis(props.featureActive)};
    margin: 5px 0px 0px 5px;

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #343a40;
    }

    &::-webkit-scrollbar {
        width: 8px;
        background-color: #343a40;
    }

    &::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #eed926;
        border-radius: 4px;
    }
`;

const SentinelOpacitySliderContainer = styled.div`
    display: flex;
    justify-content: center;
    margin: 10px;
`;
