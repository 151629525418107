import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
    selectSentinelError,
    selectSentinelFeatures,
    selectSentinelFeaturesLoading,
    selectSentinelMapZoom,
    selectSentinelSelectedAOI,
    selectSentinelSelectedFeature,
    selectSentinelSelectedFeatureLoading,
} from '../../../store/Map/Sentinel/selectors';
import { actionBeginMobileSentinelSearch } from '../../../store/Map/Mobile/actions';
import SentinelItem from '../../Drawer/Satellites/Sentinel/sentinel-item';
import MobileSatelliteLoading from './mobile-satellite-loading';
import {
    actionSentinelClearFeatures,
    actionSentinelCloseDownload,
    actionSentinelCloseShare,
    actionSentinelFetchFeatures,
    actionSentinelResetSelectedFeature,
    actionSentinelResetSelectedAOI,
    actionSentinelChangeOpacity,
    actionSentinelFetchFeaturesError,
    actionSentinelFetchFeaturesSuccess,
} from '../../../store/Map/Sentinel/actions';
import { useDispatch, useSelector } from 'react-redux';
import UriHelper from '../../../lib/uri-helper';
import { BottomSheetState } from '../BottomSheet/bottom-sheet';
import MobileSentinelSearchResult from './mobile-sentinel-search-result';
import { selectMapBounds } from '../../../store/App/selectors';
import { LatLngBounds } from 'leaflet';
import { selectLoggedIn } from '../../../store/Account/selectors';
import { TabBarState } from '../TabBar/tab-bar';
import MobileSatelliteRibbonHint from './mobile-satellite-ribbon-hint';
import ApiCfSentinel, { EvalScript } from '../../../api/api-cf-sentinel';
import { sentinelDefaultEvalScripts, trueColor } from '../../Drawer/Satellites/Sentinel/sentinel-default-evalscripts';
import Analytics from '../../../lib/user-analytics';
import SentinelUtil from '../../../lib/sentinel-util';
import MobileSatelliteFilter from './mobile-satellite-filter';
import { DateRange, last30Days } from '../../Drawer/Satellites/Sentinel/Shared/date-filter-util';
const moment = require('moment');

const MAX_SENTINEL_ZOOM = 9;

enum SearchState {
    SelectAOI = 'SelectAOI',
    AOITooLarge = 'AOITooLarge',
    BeginSearch = 'BeginSearch',
    Loading = 'Loading',
    ErrorResult = 'ErrorResult',
    ViewResults = 'ViewResults',
    ViewImagery = 'ViewImagery',
}

interface MobileSatelliteRibbonProps {
    satelliteBottomSheetState: BottomSheetState;
    setSatelliteBottomSheetState: (state: BottomSheetState) => void;
    navigateToTab?: (tabBarState: TabBarState) => void;
}

const MobileSatelliteRibbon = (props: MobileSatelliteRibbonProps) => {
    const isLoggedIn = useSelector(selectLoggedIn);

    const mapZoomLevel = useSelector(selectSentinelMapZoom);
    const mapBounds = useSelector(selectMapBounds);
    const satelliteAOI = useSelector(selectSentinelSelectedAOI);
    const sentinelFeatures = useSelector(selectSentinelFeatures);
    const sentinelFeaturesLoading = useSelector(selectSentinelFeaturesLoading);
    const selectedSentinelFeature = useSelector(selectSentinelSelectedFeature);
    const selectedSentinelFeatureLoading = useSelector(selectSentinelSelectedFeatureLoading);
    const sentinelError = useSelector(selectSentinelError);

    const dispatch = useDispatch();

    const [searchState, setSearchState] = useState<SearchState | undefined>(undefined);
    const [searchPreviousState, setSearchPreviousState] = useState<SearchState | undefined>(undefined);
    const [dateRange, setDateRange] = useState<DateRange>(last30Days);
    const [evalScript, setEvalScript] = useState<EvalScript>(trueColor);

    useEffect(() => {
        if (selectedSentinelFeature && selectedSentinelFeature?.evalScript?.name !== evalScript.name) {
            setEvalScript(selectedSentinelFeature.evalScript);
        }
    }, [evalScript.name, selectedSentinelFeature]);

    useEffect(() => {
        if (!isLoggedIn) {
            return;
        }

        if (satelliteAOI) {
            dispatch(actionSentinelChangeOpacity(undefined));
            dispatch(
                actionSentinelFetchFeatures(
                    satelliteAOI,
                    dateRange.startDate,
                    dateRange.endDate,
                    [evalScript.sentinelSatelliteType],
                    sentinelFeatures?.length,
                    evalScript
                )
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [satelliteAOI, evalScript, isLoggedIn]);

    useEffect(() => {
        const invalidateSentinelResults = () => {
            props.setSatelliteBottomSheetState(BottomSheetState.close);
            dispatch(actionSentinelResetSelectedFeature());
            dispatch(actionSentinelClearFeatures());
            dispatch(actionSentinelResetSelectedAOI());
        };

        return () => {
            invalidateSentinelResults();
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (sentinelFeaturesLoading || selectedSentinelFeatureLoading) {
            switchSearchState(SearchState.Loading);
        } else if (selectedSentinelFeature) {
            switchSearchState(SearchState.ViewImagery);
        } else if (sentinelFeatures && !selectedSentinelFeature) {
            switchSearchState(SearchState.ViewResults);
        } else if (sentinelError) {
            switchSearchState(SearchState.ErrorResult);
        } else {
            switchSearchState(SearchState.SelectAOI);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        sentinelFeaturesLoading,
        selectedSentinelFeature,
        sentinelFeatures,
        sentinelError,
        selectedSentinelFeatureLoading,
    ]);

    useEffect(() => {
        if (!searchState) {
            return;
        }

        const pos = UriHelper.tryGetParam('pos');
        switch (searchState) {
            case SearchState.ViewImagery:
                window.history.pushState(null, '', `/satellites/sentinel${pos ? `?pos=${pos}` : ''}`);
                break;
            case SearchState.ViewResults:
                if (searchPreviousState === SearchState.ViewImagery) {
                    dispatch(actionSentinelResetSelectedFeature());
                }
            // fallthrough
            default:
                window.history.pushState(null, '', `/satellites${pos ? `?pos=${pos}` : ''}`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchState]);

    const switchSearchState = (newState: SearchState) => {
        setSearchPreviousState(searchState);
        setSearchState(newState);
    };

    const handleClickQuickSearch = () => {
        if (!isLoggedIn) {
            props.navigateToTab && props.navigateToTab('profile');
            return;
        }

        if (mapZoomLevel <= MAX_SENTINEL_ZOOM) {
            switchSearchState(SearchState.AOITooLarge);
        } else {
            props.setSatelliteBottomSheetState(BottomSheetState.mid);
            switchSearchState(SearchState.BeginSearch);
            dispatch(actionBeginMobileSentinelSearch());
        }
    };

    const handleShowMore = async () => {
        Analytics.Event('Satellite - Sentinel', 'Clicked show more', evalScript.name);

        const newStartDate = moment(dateRange.startDate).subtract(1, 'months').toDate();
        const newDateRange = { ...dateRange, startDate: newStartDate };
        setDateRange(newDateRange);

        if (satelliteAOI) {
            try {
                const results = await ApiCfSentinel.searchSentinelFeatures({
                    bounds: satelliteAOI,
                    dateFrom: newStartDate,
                    dateTo: dateRange.startDate,
                    satellites: [evalScript.sentinelSatelliteType],
                    limit: 30,
                    evalScript,
                });
                const features = sentinelFeatures ? [...sentinelFeatures, ...results] : results;

                dispatch(actionSentinelFetchFeaturesSuccess(features));
            } catch (error) {
                dispatch(actionSentinelFetchFeaturesError(error));
            }
        }
    };

    const handleSelectDateRange = (dateRange: DateRange) => {
        setDateRange(dateRange);
        if (satelliteAOI) {
            dispatch(
                actionSentinelFetchFeatures(
                    satelliteAOI,
                    dateRange.startDate,
                    dateRange.endDate,
                    [evalScript.sentinelSatelliteType],
                    sentinelFeatures?.length,
                    evalScript
                )
            );
        }
    };

    const isValidSearchArea = mapZoomLevel > MAX_SENTINEL_ZOOM;
    const sentinelImageResolution =
        isValidSearchArea && mapBounds ? SentinelUtil.pixelResolutionForBounds(mapBounds as LatLngBounds) : '';

    switch (searchState) {
        case SearchState.SelectAOI:
            return (
                <Container>
                    <Button onClick={handleClickQuickSearch} disabled={!isValidSearchArea}>
                        {isValidSearchArea
                            ? isLoggedIn
                                ? `Search for ${sentinelImageResolution}m/pixel Sentinel imagery`
                                : `Login to search`
                            : 'Zoom in to search'}
                    </Button>
                </Container>
            );

        case SearchState.AOITooLarge:
            // Should not happen but incase let the user know why.
            return (
                <Container>
                    <Button onClick={handleClickQuickSearch} disabled={!isValidSearchArea}>
                        {isValidSearchArea
                            ? isLoggedIn
                                ? `Search for ${sentinelImageResolution}m/pixel Sentinel imagery`
                                : `Login to search`
                            : 'Zoom in to search'}
                    </Button>
                    <Error>This area is too large. Please zoom in closer to your Area of Interest</Error>
                </Container>
            );

        case SearchState.BeginSearch:
        case SearchState.Loading:
            return (
                <Container>
                    <MobileSatelliteLoading />
                </Container>
            );

        case SearchState.ErrorResult:
            return (
                <Container>
                    <Button onClick={handleClickQuickSearch} disabled={true}>
                        {isValidSearchArea
                            ? isLoggedIn
                                ? `Search for ${sentinelImageResolution}m/pixel Sentinel imagery`
                                : `Login to search`
                            : 'Zoom in to search'}
                    </Button>
                    <Error>{sentinelError ? sentinelError.message : 'An error occurred, Please try again'}</Error>
                    <Button
                        onClick={() => {
                            props.setSatelliteBottomSheetState(BottomSheetState.close);
                            dispatch(actionSentinelResetSelectedFeature());
                            dispatch(actionSentinelClearFeatures());
                            dispatch(actionSentinelResetSelectedAOI());
                        }}
                    >
                        Reset search
                    </Button>
                </Container>
            );

        case SearchState.ViewResults:
            return (
                <Container>
                    <RibbonHeader>
                        <BackArrow
                            onClick={() => {
                                props.setSatelliteBottomSheetState(BottomSheetState.close);
                                dispatch(actionSentinelResetSelectedFeature());
                                dispatch(actionSentinelClearFeatures());
                                dispatch(actionSentinelResetSelectedAOI());
                                switchSearchState(SearchState.SelectAOI);
                            }}
                        />
                        {sentinelFeatures && sentinelFeatures?.length !== 0 ? (
                            <SentinelFeatureHeader>{`Found ${sentinelFeatures.length} ${sentinelFeatures[0].evalScript?.name} images`}</SentinelFeatureHeader>
                        ) : null}
                    </RibbonHeader>

                    {sentinelFeatures && sentinelFeatures[0] && sentinelFeatures[0].resolution === 50 ? (
                        <MobileSatelliteRibbonHint text="Zoom in for Higher Resolution images" />
                    ) : null}

                    {sentinelFeatures && sentinelFeatures[0] && sentinelFeatures[0].resolution === 20 ? (
                        <MobileSatelliteRibbonHint text="Zoom in for Higher Resolution images" />
                    ) : null}

                    <MobileSatelliteFilter
                        onSelectEvalScript={(evalScript) => setEvalScript(evalScript)}
                        onSelectDateRange={(dateRange) => handleSelectDateRange(dateRange)}
                        evalScript={evalScript}
                        dateRange={dateRange}
                        defaultEvalScriptOptions={sentinelDefaultEvalScripts}
                        isMobile
                    />
                    {sentinelFeatures &&
                        sentinelFeatures.map((t, index) => {
                            return <SentinelItem key={index} feature={t} evalScript={evalScript} />;
                        })}
                    <Button
                        style={{ marginTop: '10px' }}
                        onClick={() => {
                            handleShowMore();
                        }}
                        disabled={false}
                    >
                        Load More Satellite Imagery
                    </Button>
                    <SentinelFeaturesListTabBarPadding />
                </Container>
            );

        case SearchState.ViewImagery:
            return (
                <Container>
                    <RibbonHeader>
                        <RibbonDetails>
                            <BackArrow
                                onClick={() => {
                                    UriHelper.removeAllSentinelParametersFromURI();
                                    UriHelper.removeParameterFromUri('shareId');
                                    dispatch(actionSentinelResetSelectedFeature());
                                    dispatch(actionSentinelCloseDownload());
                                    dispatch(actionSentinelCloseShare());
                                    if (!isLoggedIn) {
                                        dispatch(actionSentinelClearFeatures());
                                    }
                                    switchSearchState(isLoggedIn ? SearchState.ViewResults : SearchState.SelectAOI);
                                }}
                            />
                            {selectedSentinelFeature ? (
                                <SentinelFeatureHeader>{`${selectedSentinelFeature.resolution}m/pixel ${selectedSentinelFeature?.evalScript?.name}`}</SentinelFeatureHeader>
                            ) : null}
                        </RibbonDetails>
                    </RibbonHeader>
                    {selectedSentinelFeature ? <MobileSentinelSearchResult feature={selectedSentinelFeature} /> : null}
                </Container>
            );
        default:
            return <React.Fragment />;
    }
};

export default MobileSatelliteRibbon;

const Container = styled.div`
    pointer-events: auto;
`;

const RibbonHeader = styled.div`
    display: flex;
    flex-direction: row;
`;

const RibbonDetails = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1;
`;

const SentinelFeatureHeader = styled.div`
    color: white;
    font-size: 20px;
    font-weight: 600;
    margin-right: 5px;
    padding: 0px 5px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
    text-overflow: ellipsis;
`;

const BackArrow = styled.div`
    background-image: url('data:image/svg+xml,%3Csvg%20width%3D%2222%22%20height%3D%2222%22%20viewBox%3D%220%200%2022%2022%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20d%3D%22M1%2011H21%22%20stroke%3D%22white%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%0A%3Cpath%20d%3D%22M9.57153%201L0.999955%2011L9.57153%2021%22%20stroke%3D%22white%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%0A%3C%2Fsvg%3E%0A');
    background-position: center;
    background-repeat: no-repeat;
    width: 30px;
    min-width: 30px;
    height: 30px;
    align-self: center;
    margin: 0px 5px 0px 9px;
`;

const Button = styled.div<{ disabled?: boolean }>`
    background: ${(props) => (props.disabled ? 'rgba(255, 255, 255, 0.05)' : props.theme.color.yellow)};
    border-radius: 6px;
    transition: opacity 0.3s ease, transform 0.3s ease;
    font-size: 16px;
    color: ${(props) => (props.disabled ? 'white' : 'black')};
    margin: 0 0 0 15px;
    padding: 5px;
    text-align: center;
    pointer-events: auto;
    width: calc(100% - 30px);
`;

const Error = styled.p`
    color: red;
    font-weight: 600;
    text-align: center;
    margin: 12px 12px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 6px;
    padding: 10px;
    background: rgba(255, 255, 255, 0.05);
`;

const SentinelFeaturesListTabBarPadding = styled.div`
    height: 64px; // Height of padding equal to the TabBar + list item margin
`;
