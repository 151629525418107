import React from 'react';
import SentinelAOISelect from '../../MapView/Sentinel/sentinel-aoi-select';
import SentinelFeatures from '../../MapView/Sentinel/sentinel-features';

const MobileSentinelLayers = () => {
    return (
        <React.Fragment>
            <SentinelAOISelect />
            <SentinelFeatures />
        </React.Fragment>
    );
};

export default MobileSentinelLayers;
