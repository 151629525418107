import { actionTypes as at } from './constants';
import { LatLngBounds } from 'leaflet';
import SatelliteUtil from '../../../lib/satellite-util';
import ApiCfSentinel, { EvalScript } from '../../../api/api-cf-sentinel';
import { SentinelFeature } from './model';

export const actionSentinelBeginBoxSelect = () => {
    return {
        type: at.SENTINEL_BEGIN_BOX_SELECT,
    };
};

export const actionSentinelEndBoxSelect = () => {
    return {
        type: at.SENTINEL_END_BOX_SELECT,
    };
};

export const actionSentinelSelectAOI = (bbox: LatLngBounds) => {
    return {
        type: at.SENTINEL_SELECTED_AOI,
        payload: bbox,
    };
};

export const actionSentinelMapZoom = (zoom: number) => {
    return {
        type: at.SENTINEL_MAP_ZOOM,
        payload: zoom,
    };
};

export const actionSentinelResetSelectedAOI = () => {
    return {
        type: at.SENTINEL_RESET_SELECTED_AOI,
    };
};

export const actionSentinelSelectFeature = (feature: SentinelFeature | undefined) => {
    return {
        type: at.SENTINEL_SELECTED_FEATURE,
        payload: feature,
    };
};

export const actionSentinelSelectFeatureLoading = (loading: boolean) => {
    return {
        type: at.SENTINEL_SELECTED_FEATURE_LOADING,
        payload: loading,
    };
};

export const actionSentinelSelectFeatureOpacity = (opacity: number | undefined) => {
    return {
        type: at.SENTINEL_SELECTED_FEATURE_OPACITY,
        payload: opacity ?? 1,
    };
};

export const actionSentinelResetSelectedFeature = () => {
    return {
        type: at.SENTINEL_RESET_SELECTED_FEATURE,
    };
};

export const actionSentinelResetSelected = () => {
    return (dispatch) => {
        dispatch(actionSentinelResetSelectedFeature());
        dispatch(actionSentinelResetSelectedAOI());
    };
};

export const actionSentinelOpenDownload = () => {
    return {
        type: at.SENTINEL_OPEN_DOWNLOAD,
    };
};

export const actionSentinelOpenShare = () => {
    return {
        type: at.SENTINEL_OPEN_SHARE,
    };
};

export const actionSentinelChangeOpacity = (payload: { id: number | string; opacity: number } | undefined) => {
    return {
        type: at.SENTINEL_SET_OPACITY,
        payload,
    };
};

export const actionSentinelCloseDownload = () => {
    return {
        type: at.SENTINEL_CLOSE_DOWNLOAD,
    };
};

export const actionSentinelCloseShare = () => {
    return {
        type: at.SENTINEL_CLOSE_SHARE,
    };
};

export const actionSentinelFetchFeatures = (
    bounds: LatLngBounds,
    startDate: Date,
    endDate: Date,
    satellites: string[], // We are only using one at the moment but can be multiple
    limit: number | undefined,
    evalScript: EvalScript,
    userSelectedResolution?: number,
    disabledLoading?: boolean
) => {
    return async (dispatch, getState) => {
        if (!disabledLoading) {
            dispatch({
                type: at.SENTINEL_FETCH_FEATURES,
            });
        }
        try {
            if (startDate > endDate) {
                // Protects against a weird timezone bug which can cause the start date to be after the end date
                [startDate, endDate] = [endDate, startDate];
            }

            // Some light validation to make sure we have a bounds (existing edge case...)
            const southWest = bounds.getSouthWest();
            const northEast = bounds.getNorthEast();

            if (southWest.lng === northEast.lng || southWest.lat === northEast.lat) {
                dispatch(actionSentinelFetchFeaturesSuccess(undefined));
                throw new Error('You created a single point. Please select a larger area.');
            }

            const results = await ApiCfSentinel.searchSentinelFeatures({
                bounds,
                dateFrom: startDate,
                dateTo: endDate,
                satellites: satellites,
                limit: limit ? limit + 30 : 30,
                evalScript,
                userSelectedResolution,
            });

            if (!results?.length) {
                throw new Error('No data available. Please select a different area.');
            }

            dispatch(actionSentinelFetchFeaturesSuccess(results));
        } catch (err) {
            console.log('Error fetching sentinel features:', err);
            SatelliteUtil.sendSentinelSlackMessage(
                `ERROR: ${evalScript?.sentinelSatelliteType.toUpperCase()} ${evalScript.name}`,
                `FAILED FIS: ${err}`,
                `BBOX: ${bounds.toBBoxString()}`
            );
            dispatch(actionSentinelFetchFeaturesError(err));
        }
    };
};

export const actionSentinelFetchFeaturesSuccess = (features: SentinelFeature[] | undefined) => {
    return {
        type: at.SENTINEL_FETCH_FEATURES_SUCCESS,
        payload: features,
    };
};

export const actionSentinelFetchFeaturesError = (error: Error | string | undefined) => {
    return {
        type: at.SENTINEL_FETCH_FEATURES_ERROR,
        payload: typeof error === 'string' ? new Error(error) : error,
    };
};

export const actionSentinelClearFeatures = () => {
    return {
        type: at.SENTINEL_CLEAR_FEATURES,
    };
};

export const actionSentinelFeatureLoading = (isLoading: boolean) => {
    return {
        type: at.SENTINEL_FEATURE_LOADING,
        payload: isLoading,
    };
};

export const actionSentinelSelectedAdvancedFeatureLoading = (isLoading: boolean) => {
    return {
        type: at.SENTINEL_SELECTED_ADVANCED_FEATURE_LOADING,
        payload: isLoading,
    };
};
