import React, { useEffect, useState } from 'react';
import { sentinelDefaultEvalScripts } from '../sentinel-default-evalscripts';
import Analytics from '../../../../../lib/user-analytics';
import styled from 'styled-components';
import SentinelAdvancedScriptEditor from './sentinel-advanced-script-editor';
import { PulseLoader } from '../../../../Shared/pulse-loader';
import SideDrawerSelect from '../../../SideDrawer/Shared/side-drawer-select';
import { EvalScript } from '../../../../../api/api-cf-sentinel';

interface SentinelAdvancedEditorProps {
    setShowAdvancedFeatures: () => void;
    evalScript: EvalScript;
    onSelectedEvalScript: (evalScript: EvalScript) => void;
    isLoadingPreview?: boolean;
}

const SentinelAdvancedEditor = ({
    evalScript,
    onSelectedEvalScript,
    isLoadingPreview,
}: SentinelAdvancedEditorProps) => {
    const [selectedEvalScript, setSelectedEvalScript] = useState<EvalScript>(evalScript);

    const handleChangeEvalScript = (scriptName: string) => {
        Analytics.Event('Satellite - Sentinel', 'Changed Advanced EvalScript', scriptName);
        const selectedScript = sentinelDefaultEvalScripts.find((script) => script.name === scriptName);
        if (!selectedScript) return;
        onSelectedEvalScript(selectedScript);
        setSelectedEvalScript(selectedScript);
    };

    const handleSetCustomEvalScript = (customEvalScript: EvalScript) => {
        Analytics.Event('Satellite - Sentinel', 'Changed Custom EvalScript', customEvalScript.name);
        Analytics.Event('Satellite - Sentinel', 'Used Custom Script', customEvalScript.toString());
        onSelectedEvalScript(customEvalScript);
        setSelectedEvalScript(customEvalScript);
    };

    useEffect(() => {
        if (evalScript) {
            setSelectedEvalScript(evalScript);
        }
    }, [evalScript]);

    const evalScriptOptions = [
        ...sentinelDefaultEvalScripts.map((evalOption) => ({
            value: evalOption.name,
            label: evalOption.name,
            evalScript: evalOption,
        })),
    ];

    const selectedOption = evalScriptOptions.find((option) => option.evalScript.name === selectedEvalScript?.name) || {
        value: 'Custom Script',
        label: 'Custom Script',
    };

    return (
        <SentinelAdvancedScriptOptionsContainer>
            <EvalScriptPresetSelect>
                <EvalScriptTitle>Preset Scripts</EvalScriptTitle>
                <SideDrawerSelect
                    options={evalScriptOptions}
                    value={selectedOption}
                    onChange={(selectedOption) => selectedOption && handleChangeEvalScript(selectedOption.value)}
                    icon="/assets/sentinel-filter/layers.svg"
                />
            </EvalScriptPresetSelect>
            <EvalScriptDescriptionContainer>
                <EvalScriptDescription>{selectedEvalScript?.description}</EvalScriptDescription>
                {selectedEvalScript?.author ? (
                    <React.Fragment>
                        <br />
                        <EvalScriptDescription>{selectedEvalScript?.author}</EvalScriptDescription>
                    </React.Fragment>
                ) : null}
            </EvalScriptDescriptionContainer>
            {selectedEvalScript ? (
                <SentinelAdvancedScriptEditor
                    evalScript={selectedEvalScript}
                    setEvalScript={handleSetCustomEvalScript}
                />
            ) : null}
            {isLoadingPreview && (
                <EvalScriptLoading>
                    <PulseLoader />
                </EvalScriptLoading>
            )}
        </SentinelAdvancedScriptOptionsContainer>
    );
};

export default SentinelAdvancedEditor;

const SentinelAdvancedScriptOptionsContainer = styled.div`
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    height: 100%;

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #343a40;
    }

    &::-webkit-scrollbar {
        width: 8px;
        background-color: #343a40;
    }

    &::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #eed926;
        border-radius: 4px;
    }
`;

const EvalScriptPresetSelect = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 8px;
`;

const EvalScriptTitle = styled.div`
    align-self: center;
    color: white;
    font-size: 0.9rem;
    font-weight: bold;
    width: 135px;
`;

const EvalScriptDescriptionContainer = styled.div`
    margin: 10px 0px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    background-color: rgba(255, 255, 255, 0.05);
    border-radius: 6px;
    padding: 12px;
`;

const EvalScriptDescription = styled.p`
    color: white;
    font-size: 0.9rem;
    margin: 0;
`;

const EvalScriptLoading = styled.div`
    width: 100%;
    height: 100%;
`;
