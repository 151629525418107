import { SentinelFeature } from '../../../../../store/Map/Sentinel/model';
import styled from 'styled-components';
import SatelliteUtil from '../../../../../lib/satellite-util';
import { StyledButton } from '../../../../Shared/styled-button';
import { useCallback, useState } from 'react';
import ApiCfSentinel from '../../../../../api/api-cf-sentinel';
import { toast } from 'react-toastify';
import Analytics from '../../../../../lib/user-analytics';
import ApiAnalytics from '../../../../../api/api-analytics';
import { SatelliteProvider, AnalyticsAction } from '../../../../../api/model';
import GeoUtil from '../../../../../lib/geo-util';
import UriHelper from '../../../../../lib/uri-helper';

interface SentinelAdvancedDownloadButtonProps {
    feature: SentinelFeature;
    selectedResolution?: number;
}

const SentinelAdvancedDownloadButton = ({ feature, selectedResolution }: SentinelAdvancedDownloadButtonProps) => {
    const [isDownloading, setIsDownloading] = useState(false);

    const bestAvailableResolution = SatelliteUtil.getBestAvailableResolution(feature);
    const resolution = selectedResolution ?? bestAvailableResolution;

    const handleDownloadSatelliteImage = useCallback(async () => {
        const selectedQuality = SatelliteUtil.getSelectedQuality(feature, resolution);
        if (!selectedQuality) return;

        setIsDownloading(true);
        const downloadUrl = await ApiCfSentinel.getSentinelTiffDownloadImage(
            feature,
            selectedQuality.widthPixels ?? 0,
            selectedQuality.heightPixels
        );

        setIsDownloading(false);
        if (!downloadUrl) {
            toast.error('Failed to download image');
            return;
        }

        const date = new Date(feature.date).toISOString().split('T')[0];
        const fileName = `Soar-${feature.evalScript?.sentinelSatelliteType}_${feature.evalScript?.name}_GSD${selectedQuality.resolution}_${date}.tiff`;
        Analytics.Event(
            'Satellite - Sentinel',
            'Selected to download image',
            `${feature.evalScript.name} - ${fileName}`
        );

        const shareId = UriHelper.tryGetParam('shareId');
        ApiAnalytics.postAnalytics(
            SatelliteProvider.SINERGISE,
            ApiAnalytics.mapSatelliteTypeToProduct(feature.evalScript?.sentinelSatelliteType),
            AnalyticsAction.DOWNLOAD,
            GeoUtil.latLngBoundsToWKT(feature.bbox),
            feature.evalScript?.name ?? 'Custom Script',
            shareId ?? ''
        );

        SatelliteUtil.downloadFile(downloadUrl, fileName);
    }, [feature, resolution]);

    return (
        <SentinelAdvancedDownloadButtonContainer>
            <SentinelAdvancedDownloadDivider />
            <SentinelAdvancedDownloadText>
                Download at {selectedResolution ?? bestAvailableResolution}m/pixel
            </SentinelAdvancedDownloadText>
            <SentinelAdvancedDownload disabled={isDownloading} onClick={handleDownloadSatelliteImage}>
                {isDownloading ? <SentinelDownloadingIcon className="fas fa-spinner fa-spin" /> : 'Download'}
            </SentinelAdvancedDownload>
        </SentinelAdvancedDownloadButtonContainer>
    );
};

export default SentinelAdvancedDownloadButton;

const SentinelAdvancedDownloadDivider = styled.div`
    width: 100%;
    height: 1px;
    background-color: rgba(255, 255, 255, 0.2);
    margin: 10px 0px 0px 0px;
`;

const SentinelAdvancedDownloadButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const SentinelAdvancedDownloadText = styled.span`
    margin: 5px;
    text-align: center;
    color: white;
    font-size: 0.9rem;
    font-weight: bold;
`;

const SentinelAdvancedDownload = styled(StyledButton)`
    margin-top: 5px;
    padding: 8px 0px 4px 0px;
    font-size: 0.9rem;
    transition: all 0.3s ease;
    height: 40px;
`;

const SentinelDownloadingIcon = styled.i`
    font-size: 20px;
`;
