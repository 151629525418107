import { actionTypes as at } from './constants';
import { Action } from '../root-reducer';
import { LatLng, LatLngBounds } from 'leaflet';
import { Config } from './model';
import { ListingDTOWithChip } from '../../components/Drawer/SideDrawer/ListingsDrawer/listing-card-chip';

const defaultConfig: Config = {
    IS_LOADING_CONFIG: true,
    IS_AVAILABLE_FOR_PURCHASE: false,
    SUBDOMAIN: '',
    LOGO: '/assets/logos/soar_logo.png',
    MOBILE_LOGO: '/assets/logos/soar-icon-search.png',
    MOBILE_LOGO_ACTIVE: '/assets/logos/soar-icon-search.png',
    BEHAVIOURS: {
        FORCED_LOGIN: false,
        ROUTE_AFTER_LOGIN: '',
    },
    FEATURES: {
        SHOPPING_CART: false, // TODO can probably be removed
    },
};

interface StoreApp {
    config: Config;
    maintenance: boolean;
    mapPosition?: LatLng | LatLngBounds;
    mapPositionZoom: number | undefined;
    shouldMapPositionUpdateImmediately: boolean;
    showLoginDialog: boolean;
    showNavbar: boolean;
    onLoginDialogCloseRedirect: string | undefined;
    mapBounds: LatLng | LatLngBounds | undefined;
    mapZoom: number | undefined;
    mapMoveEnd: boolean;
    showUploadDialog: boolean;
    globalListings: ListingDTOWithChip[];
}

const initialAppState: StoreApp = {
    config: defaultConfig,
    maintenance: false,
    mapPosition: undefined,
    mapPositionZoom: undefined,
    shouldMapPositionUpdateImmediately: false,
    showLoginDialog: false,
    showNavbar: true,
    onLoginDialogCloseRedirect: undefined,
    mapBounds: undefined,
    mapZoom: undefined,
    mapMoveEnd: false,
    showUploadDialog: false,
    globalListings: [],
};

export default (state: StoreApp = initialAppState, action: Action): StoreApp => {
    switch (action.type) {
        case at.UPDATE_CONFIG:
            return { ...state, maintenance: false };
        case at.UPDATE_CONFIG_SUCCESS:
            return { ...state, maintenance: false, config: action.payload };
        case at.UPDATE_CONFIG_FAILURE:
            return { ...state, maintenance: true };

        case at.FLY_TO:
            return {
                ...state,
                mapPosition: action.payload.position,
                mapPositionZoom: action.payload.zoom,
                shouldMapPositionUpdateImmediately: action.payload.immediately,
            };

        case at.FLY_TO_POSITION:
            return {
                ...state,
                mapPosition: action.payload,
            };

        case at.FLY_TO_POSITION_INVALIDATE:
            return {
                ...state,
                mapPosition: undefined,
                mapPositionZoom: undefined,
                shouldMapPositionUpdateImmediately: false,
            };

        case at.FLY_TO_ZOOM:
            return {
                ...state,
                mapPositionZoom: action.payload,
            };

        case at.SET_LOGIN_DIALOG_CLOSE_REDIRECT:
            return {
                ...state,
                onLoginDialogCloseRedirect: action.payload,
            };

        case at.RESET_LOGIN_DIALOG_CLOSE_REDIRECT:
            return {
                ...state,
                onLoginDialogCloseRedirect: initialAppState.onLoginDialogCloseRedirect,
            };

        case at.SHOW_LOGIN_DIALOG:
            return {
                ...state,
                showLoginDialog: action.payload,
            };

        case at.SHOW_NAVBAR:
            return {
                ...state,
                showNavbar: action.payload,
            };

        case at.SET_MAP_BOUNDS:
            return {
                ...state,
                mapBounds: action.payload,
            };

        case at.SET_MAP_ZOOM:
            return {
                ...state,
                mapZoom: action.payload,
            };

        case at.SET_MAP_MOVE_END:
            return {
                ...state,
                mapMoveEnd: action.payload,
            };

        case at.SHOW_UPLOAD_DIALOG:
            return {
                ...state,
                showUploadDialog: action.payload,
            };

        case at.FETCH_GLOBAL_LISTING:
            return {
                ...state,
                globalListings: action.payload,
            };
        default:
            return state;
    }
};
