import styled from 'styled-components';
import { Row, Col, Input } from 'reactstrap';
import { SentinelImageDimension } from '../../../../api/model';
import { SentinelFeature } from '../../../../store/Map/Sentinel/model';
import UserHelper from '../../../../lib/user-helper';

interface DownloadDetailsProps {
    sentinelFeature: SentinelFeature;
    sentinelImageDimensions: SentinelImageDimension;
    area: number;
    handleSelectQuality: (quality: string) => void;
    availableImageDimensions: SentinelImageDimension[] | undefined;
}

export const DownloadDetails = ({
    sentinelFeature,
    sentinelImageDimensions,
    area,
    handleSelectQuality,
    availableImageDimensions,
}: DownloadDetailsProps) => {
    return (
        <Row>
            <Container>
                <Row>
                    <Col md={5}>
                        <Key>Sensor</Key>
                    </Col>
                    <Col md={7}>
                        <Value>{UserHelper.formatToUppercaseAndLowercaseText(sentinelFeature.satellite)}</Value>
                    </Col>
                </Row>
                <Row>
                    <Col md={5}>
                        <Key>Area</Key>
                    </Col>
                    <Col md={7}>
                        <Value>
                            {area.toLocaleString(undefined, { maximumFractionDigits: 2 })}
                            km&#178;
                        </Value>
                    </Col>
                </Row>
                <Row>
                    <Col md={5}>
                        <Key>Image format</Key>
                    </Col>
                    <Col md={7}>
                        <Value>32-bit GeoTIFF</Value>
                    </Col>
                </Row>
                <Row>
                    <Col md={5}>
                        <Key>Image Size</Key>
                    </Col>
                    <Col md={7}>
                        <Value>
                            {sentinelImageDimensions.widthPixels.toString()} x{' '}
                            {sentinelImageDimensions.heightPixels.toString()}
                        </Value>
                    </Col>
                </Row>
                <Row>
                    <Col md={5}>
                        <Key>Resolution</Key>
                    </Col>
                    <Col md={7}>
                        <Value>{sentinelImageDimensions.resolution.toString()}m/pixel</Value>
                    </Col>
                </Row>
                {availableImageDimensions ? (
                    <Row>
                        <Col md={5}>
                            <Key>Choose Quality</Key>
                        </Col>
                        <Col md={7}>
                            <DownloadSelectValue
                                value={sentinelImageDimensions.quality}
                                type="select"
                                onChange={(e) => {
                                    handleSelectQuality(e.target.value);
                                }}
                            >
                                {availableImageDimensions.map((imageDimension, index) => (
                                    <option key={index} value={imageDimension.quality}>
                                        {imageDimension.quality}
                                    </option>
                                ))}
                            </DownloadSelectValue>
                        </Col>
                    </Row>
                ) : null}
            </Container>
        </Row>
    );
};

const Container = styled.div`
    width: 100%;
    color: rgb(176, 176, 176);
    margin-top: 30px;

    label {
        color: rgb(176, 176, 176);
        width: 100%;
    }

    option {
        background-color: rgba(0, 0, 0, 0.8);
        color: rgb(176, 176, 176);
        font-size: 15px;
    }
    select {
        background-color: rgba(0, 0, 0, 0);
        color: rgb(176, 176, 176);
        border: 1px solid rgb(255 255 255 / 0.3);

        :focus {
            background-color: rgba(0, 0, 0, 0);
            color: rgb(176, 176, 176);
            border-color: rgba(238 227 8 / 0.6);
            box-shadow: 0 0 0 0.2rem rgba(238 227 8 / 0.1);
        }
    }
    input {
        background-color: rgba(0, 0, 0, 0);
        color: rgb(176, 176, 176);
        border: 1px solid rgb(255 255 255 / 0.3);

        :focus {
            background-color: rgba(0, 0, 0, 0);
            color: rgb(176, 176, 176);
            border-color: rgba(238 227 8 / 0.6);
            box-shadow: 0 0 0 0.2rem rgba(238 227 8 / 0.1);
        }
    }
`;

const Key = styled.div`
    margin-top: 10px;
    color: rgb(176, 176, 176);
`;

const Value = styled.div`
    margin-top: 10px;
    color: rgb(176, 176, 176);
`;

const DownloadSelectValue = styled(Input)`
    margin-top: 8px;
    padding-left: 2px;
    padding-top: 3px;
    height: 29px;
    margin-top: 8px;
    width: 140px;
`;
