import { Carousel } from '@trendyol-js/react-carousel';
import React from 'react';
import styled from 'styled-components';
import SearchContentCard from './search-content-card';
import { StoaryPageMapLayer } from '../../../../../../api/stoaryModel';

export interface CarouselItem extends StoaryPageMapLayer {
    previewUrl: string;
}

interface SearchContentCarouselProps {
    items: CarouselItem[];
    height: string;
    handleSelectItem: (item: CarouselItem) => void;
}

const SearchContentCarousel = (props: SearchContentCarouselProps) => {
    const RightArrow = (props: { height: string }): React.ReactElement => {
        return (
            <RightArrowContainer height={props.height}>
                <RightArrowIcon className="fa fa-chevron-right" />
            </RightArrowContainer>
        );
    };

    const LeftArrow = (props: { height: string }): React.ReactElement => {
        return (
            <LeftArrowContainer height={props.height}>
                <LeftArrowIcon className="fa fa-chevron-left" />
            </LeftArrowContainer>
        );
    };

    return (
        <CarouselContainer height={props.height}>
            <Carousel
                show={5.5}
                slide={2}
                swiping={true}
                infinite={false}
                rightArrow={<RightArrow height={props.height} />}
                leftArrow={<LeftArrow height={props.height} />}
            >
                {props.items?.map((t, k) => {
                    return (
                        <SearchContentCard
                            key={t.previewUrl || k}
                            title={t.title}
                            height={props.height}
                            previewUrl={t.previewUrl}
                            onClick={() => props.handleSelectItem(t)}
                        />
                    );
                })}
            </Carousel>
        </CarouselContainer>
    );
};

export default SearchContentCarousel;

const CarouselContainer = styled.div<{ height: string }>`
    height: ${(props) => props.height};
`;

const ArrowContainer = styled.div<{ height: string }>`
    width: 64px;
    height: calc(100% - 8px);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
    background: rgba(0, 0, 0, 0.8);
    margin: 4px 0;
    display: flex;
    align-items: center;
`;

const LeftArrowContainer = styled(ArrowContainer)`
    z-index: 99999;
    mask-image: -webkit-gradient(linear, left center, right center, from(rgba(0, 0, 0, 1)), to(rgba(0, 0, 0, 0)));
`;

const RightArrowContainer = styled(ArrowContainer)`
    left: calc(100% - 64px);
    mask-image: -webkit-gradient(linear, left center, right center, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 1)));
`;

const ArrowIcon = styled.i`
    font-size: 2.5rem;
    color: white;
    width: 100%;
    margin-top: -4px;
`;

const LeftArrowIcon = styled(ArrowIcon)`
    text-align: left;
`;

const RightArrowIcon = styled(ArrowIcon)`
    text-align: right;
`;
